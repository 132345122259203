/**
 * Common file type extensions.
 */
export const EXCEL_FILES = ['xls', 'xlsx']
export const EXCEL_TEMPLATE_FILES = ['xlt', 'xltm', 'xltx']
export const POWERPOINT_FILES = ['ppt', 'pptx']
export const POWERPOINT_TEMPLATE_FILES = ['pot', 'potm', 'potx']
export const WORD_FILES = ['doc', 'docx']
export const WORD_TEMPLATE_FILES = ['dot', 'dotm', 'dotx']
export const TEMPLATE_FILES = EXCEL_TEMPLATE_FILES
  .concat(WORD_TEMPLATE_FILES)
  .concat(POWERPOINT_TEMPLATE_FILES)
const IMAGE_FILES = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'tiff', 'svg']
const EMAIL_FILES = ['msg', 'eml']

/**
 * Convert string to Uint8Array.
 */
export function stringToUint (string) {
  const array = new Array(string.length)
  const chars = string.split('')
  chars.forEach((c, i) => {
    array[i] = c.charCodeAt(0)
  })
  return Uint8Array.from(array)
}

/**
 * Cleans a file name from invalid characters.
 * Also tidies up multiples of spaces with a single space.
 */
export function cleanFileName (fileName) {
  let clean = fileName
    .replace(/[<>:'"/\\|?*#%]/g, '')
    .split(' ')
    .filter(Boolean)
    .join(' ')

  if (fileName.endsWith(' ')) {
    clean += ' '
  }

  return clean
}

/**
 * Adds https:// to an url if it's missing (for hrefs)
 */
export function httpify (url) {
  const lower = url.toLowerCase()
  if (lower.startsWith('http://') || lower.startsWith('https://')) {
    return url
  } else {
    return `https://${url}`
  }
}

/**
 * Compares two strings in lower case.
 */
function compareLowerCase (A, B) {
  const a = A.toLowerCase()
  const b = B.toLowerCase()
  if (a < b) return -1
  else if (a > b) return 1
  else return 0
}

/**
 * Sorts an array of folders and files.
 * Folders are sorted before files.
 * Folder and files are sorted between each other.
 */
export function sortFileItems (items) {
  return items.slice().sort((A, B) => {
    if (A.type === 'folder' && B.type === 'file') {
      return -1
    } else if (A.type === 'file' && B.type === 'folder') {
      return 1
    }
    return compareLowerCase(A.name, B.name)
  })
}

/**
 * Return true if a token has more than 90 seconds left
 */
export function isValidToken (token) {
  return typeof token === 'string' && getTokenExpiresInMs(token) > 90 * 1000
}

function getTokenExpiresInMs (token) {
  const { exp } = decodeToken(token)
  return exp * 1000 - Date.now()
}

/**
 * Decodes the payload from a json web token. Does not verify.
 */
export function decodeToken (token) {
  const parts = (token || '').split('.')

  if (parts.length !== 3) {
    return {}
  }

  let payload = {}

  try {
    payload = JSON.parse(atob(parts[1]))
  } catch (e) {
    console.error('Failed to parse encoded token payload')
  }

  return payload
}

/**
 * Return file extension from file name
 */
export function getFileExtension (name) {
  const split = name.split('.')
  return (split.length > 0 ? split[split.length - 1] : '').toLowerCase()
}

/**
 * Return true if file extension is an office zip file
 */
export function isOfficeZipFile (name) {
  return ['xlsx', 'docx', 'pptx'].includes(getFileExtension(name))
}

/**
 * Return office uri protocol based based on file extension.
 */
function getOfficeUriProtocol (ext) {
  if (WORD_FILES.includes(ext) ||
      WORD_TEMPLATE_FILES.includes(ext)) {
    return 'ms-word'
  } else if (POWERPOINT_FILES.includes(ext) ||
             POWERPOINT_TEMPLATE_FILES.includes(ext)) {
    return 'ms-powerpoint'
  } else if (EXCEL_FILES.includes(ext) ||
             EXCEL_TEMPLATE_FILES.includes(ext)) {
    return 'ms-excel'
  } else {
    return ''
  }
}

/**
 * Return true if extension is a template
 */
export function isTemplateFile (ext) {
  return TEMPLATE_FILES.includes(ext)
}

/**
 * Return an absolute url using host origin and a server relative url
 */
export function absoluteUrl (siteUrl, serverRelativeUrl) {
  const parsed = new URL(siteUrl)
  return encodeURI(`${parsed.origin}${serverRelativeUrl || ''}`)
}

/**
 * Converts keys and values from an object to a query string.
 */
export function toQueryString (params) {
  const query = Object.keys(params).map(key => {
    const value = params[key]
    if (typeof value === 'string') {
      return value ? `${key}=${encodeURI(value)}` : ''
    } else {
      return `${key}=${encodeURI(value)}`
    }
  }).filter(Boolean).join('&')
  return query ? `?${query}` : ''
}

/**
 * Open a document uri based on file type using the office uri scheme.
 * uri: full url to document or template
 * params: optional object which will passed as parameters to templates
 * **NOTE** Currently disabling query parameters for opening templates
 * on Mac!
 */
export function openDocument (uri, params) {
  const ext = getFileExtension(uri)
  const protocol = getOfficeUriProtocol(ext)
  if (protocol) {
    const isTemplate = isTemplateFile(ext)
    const command = isTemplate ? 'nft' : 'ofe'
    const query = isTemplate && params && isWindows() ? `${toQueryString(params)}` : ''
    const url = `${protocol}:${command}|u|${uri}${query}`
    console.info('opening document', url)
    window.open(url)
  } else if (ext === 'pdf') {
    console.info('opening pdf document', uri)
    window.open(uri)
  } else if (IMAGE_FILES.includes(ext)) {
    console.info('opening image', uri)
    window.open(uri)
  } else if (EMAIL_FILES.includes(ext)) {
    console.info('opening email', uri)
    window.open(uri)
  } else {
    console.info('could not find a suitable application for', uri)
  }
}

/**
 * Creates a parameter object for use with openDocument()
 */
export function constructDocumentParams (userData, context) {
  return {
    // From userData
    pds_user: userData.name || '',
    pds_email: userData.email || '',
    pds_phone: userData.phone || '',
    pds_city: userData.city || '',
    pds_address: userData.address || '',
    pds_userhomesite: userData.homepage || '',
    pds_contact: userData.collection_name || '',
    pds_title: userData.object_type_name || '',
    pds_zipcode: userData.postal_code || '',
    // From context
    pds_type: (context && context.type) || '',
    pds_id: (context && context.id) || '',
    pds_eid: (context && context.eid) || ''
  }
}

/**
 * Returns true if there is a matching office protocol for the
 * file type on the url.
 */
export function canOpenDocument (uri) {
  if (typeof uri === 'string') {
    const ext = getFileExtension(uri)
    return !!(getOfficeUriProtocol(ext) || ext === 'pdf' || IMAGE_FILES.includes(ext)) || EMAIL_FILES.includes(ext)
  } else {
    return false
  }
}

/**
 * Function for figuring out if an item should be selected in a
 * tree view
 */
export function isSelected (selected) {
  return (item) => {
    if (selected) {
      return item.type === selected.type && item.id === selected.id
    } else {
      return false
    }
  }
}

/**
 * Categories are really document libraries under the parent department
 */
export function getItemUrl (item) {
  return item.type === 'category' ? item.collection_url : item.url
}

/**
 * Category document library uses category.id. All other items use 'files'
 * as their document library root.
 */
export function getItemRoot (item) {
  if (item.type === 'category') {
    return `files_${item.id}`
  } else if (item.type === 'templates') {
    return 'pdsMallar'
  } else {
    return 'files'
  }
}

/**
 * Waits delay milliseconds.
 * Returns a promise.
 */
export function wait (delay) {
  return new Promise(resolve => setTimeout(resolve, delay))
}

/**
 * Return true if this is a Windows machine.
 */
export function isWindows () {
  return navigator.platform.startsWith('Win')
}

/**
 * Return true if this is a Mac machine.
 */
export function isMac () {
  return navigator.platform.startsWith('Mac')
}
