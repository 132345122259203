export const BEGIN_PROGRESS_DIALOG_ACTION = 'BEGIN_PROGRESS_DIALOG_ACTION'
export const UPDATE_PROGRESS_DIALOG_ACTION = 'UPDATE_PROGRESS_DIALOG_ACTION'
export const END_PROGRESS_DIALOG_ACTION = 'END_PROGRESS_DIALOG_ACTION'

export function beginProgressDialog (title, status, statusParam = '') {
  return {
    type: BEGIN_PROGRESS_DIALOG_ACTION,
    title,
    status,
    statusParam
  }
}

export function updateProgressDialog (status, statusParam = '') {
  return {
    type: UPDATE_PROGRESS_DIALOG_ACTION,
    status,
    statusParam
  }
}

export function endProgressDialog () {
  return {
    type: END_PROGRESS_DIALOG_ACTION
  }
}
