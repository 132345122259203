import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore
} from 'redux'
import thunkMiddleWare from 'redux-thunk'

import { busy } from './busy/reducer.js'
import { contact } from './contact/reducer.js'
import { contentType } from './content-type/reducer.js'
import { department } from './department/reducer.js'
import { dialog } from './dialog/reducer.js'
import { error } from './error/reducer.js'
import { files } from './files/reducer.js'
import { locale } from './locale/reducer.js'
import { login } from './login/reducer.js'
import { page } from './page/reducer.js'
import { project } from './project/reducer.js'
import { progress } from './progress/reducer.js'
import { save } from './save/reducer.js'
import { selected } from './selected/reducer.js'

const DEVTOOLS = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()

export default createStore(
  combineReducers({
    busy,
    contact,
    contentType,
    dialog,
    department,
    error,
    files,
    locale: locale(),
    login: login(),
    page: page(),
    project,
    progress,
    save,
    selected
  }),
  compose(
    applyMiddleware(thunkMiddleWare),
    DEVTOOLS || compose
  )
)
