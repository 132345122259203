// sizes
export const HEADER_HEIGHT = '50px'
export const FOOTER_HEIGHT = '50px'

// colors
export const HEADER_BACKGROUND = '#266783'
export const FOOTER_BACKGROUND = '#394b55'
export const CONTENT_BACKGROUND = '#f4f4f4'
export const RECENT_COLOR = '#196dc7'
export const FAVORITE_COLOR = '#208524'
