import React from 'react'
import {
  ContactsOutlined,
  Favorite,
  FavoriteBorder,
  HowToRegOutlined
} from '@material-ui/icons'
import { TemplateManagerIcon, DocumentLibraryIcon } from '../icons/index.js'
import { FAVORITE_COLOR, RECENT_COLOR } from '../styles.js'
import {
  ListItemIcon,
  Tooltip
} from '@material-ui/core'

export const renderCustomIcons = ({
  locale,
  onContactPersonClick,
  onPersonWithRoleClick,
  onTemplateManagerClick,
  onFavoriteClick,
  sharePointSaveEnabled
}) => {
  const renderTemplateManagerIcon = (item) => {
    if (typeof onTemplateManagerClick !== 'function' || (item.type !== 'contact' && item.type !== 'project')) return null

    const onClick = (event) => {
      event.stopPropagation()
      onTemplateManagerClick(item)
    }
    return (
      <Tooltip title={locale.templates_page_title} arrow>
        <ListItemIcon onClick={onClick} style={{ minWidth: 18 }}>
          <TemplateManagerIcon width={20} height={20} />
        </ListItemIcon>
      </Tooltip>
    )
  }
  const renderContactPersonIcon = (item) => {
    if (typeof onContactPersonClick !== 'function' || item.type !== 'contact') return null

    const onClick = (event) => {
      event.stopPropagation()
      onContactPersonClick(item)
    }

    return (
      <Tooltip title={locale.contact_person_title} arrow>
        <ListItemIcon onClick={onClick} style={{ minWidth: 18 }}>
          <ContactsOutlined style={{ color: RECENT_COLOR, height: 16 }} />
        </ListItemIcon>
      </Tooltip>
    )
  }

  const renderPersonWithRoleIcon = (item) => {
    if (typeof onPersonWithRoleClick !== 'function') return null

    const onClick = (event) => {
      event.stopPropagation()
      onPersonWithRoleClick(item.collection_id ?? item.id, item)
    }

    return (
      <Tooltip title={locale.people_dialog_title} arrow>
        <ListItemIcon onClick={onClick} style={{ minWidth: 18 }}>
          <HowToRegOutlined style={{ color: RECENT_COLOR, height: 16 }} />
        </ListItemIcon>
      </Tooltip>
    )
  }

  const renderDocumentLibraryIcon = (item) => {
    if (!sharePointSaveEnabled) return null

    const color = item.url ? '#137178' : 'rgba(0, 0, 0, 0, 0.54)'

    const onClick = (event) => {
      if (item.url) {
        event.stopPropagation()
        const documentLibraryUrl = () => {
          if (item.type === 'category') {
            return item.url
          } else {
            return `${item.url}/files`
          }
        }
        window.open(documentLibraryUrl())
      }
    }

    return (
      <Tooltip title={locale.document_library} arrow>
        <ListItemIcon onClick={onClick} style={{ marginTop: 4, minWidth: 18 }}>
          <DocumentLibraryIcon style={{ color, height: 16 }} />
        </ListItemIcon>
      </Tooltip>
    )
  }

  const renderFavoriteIcon = (item) => {
    if (typeof onFavoriteClick !== 'function') return null

    function renderIcon () {
      if (item.is_favorite) {
        return <Favorite style={favoriteIconStyle} />
      } else {
        return <FavoriteBorder style={favoriteIconStyle} />
      }
    }
    return (
      <Tooltip title={locale.favorite} arrow>
        <ListItemIcon onClick={(e) => { e.stopPropagation(); onFavoriteClick(item) }} style={{ marginTop: 2, minWidth: 18 }}>
          {renderIcon()}
        </ListItemIcon>
      </Tooltip>
    )
  }

  return (item) => {
    return (
      <>
        {renderContactPersonIcon(item)}
        {renderPersonWithRoleIcon(item)}
        {renderTemplateManagerIcon(item)}
        {renderDocumentLibraryIcon(item)}
        {renderFavoriteIcon(item)}
      </>
    )
  }
}

const favoriteIconStyle = {
  height: 16,
  color: FAVORITE_COLOR
}
