import { absoluteUrl } from '../../util.js'

const PDS_EMAIL_CONTENT_TYPE = 'pdsct_email'

/**
 * Get files and folders in a document library folder.
 *
 * siteUrl: url to site
 * path: path to file
 * id: unique folder id
 * spToken: sharepoint access token
 */
export async function getDocumentLibraryFiles ({ siteUrl, path, id }, spToken) {
  const url = id ? `${siteUrl}/_api/Web/GetFolderById('${id}')?$expand=Folders,Files` : `${siteUrl}/_api/Web/GetFolderByServerRelativeUrl('${path}')?$expand=Folders,Files`
  const res = await fetch(url, getRequestOptions(spToken))
  if (!res.ok) {
    throw new Error(`getDocumentLibraryFiles() failed (${res.status})`)
  }
  return (await res.json()).d
}

/**
 * Reads a file from SharePoint.
 * Returns the file in binary as a Uint8Array.
 *
 * uri: Full uri to file
 * spToken: sharepoint access token
 *
 * We assume that uri has the following format
 * https://pergasab.sharepoint.com/sites/foo/some/path/to/file.docx
 *
 * The request to the api is built up using the origin, the site collection
 * and the server relative url.
 */
export async function readSharePointFile (uri, spToken) {
  const parsed = new URL(uri)
  const {
    // https://pergasab.sharepoint.com
    origin,
    // /sites/foo/some/path/to/file.docx
    pathname
  } = parsed

  // /sites/foo
  const siteCollection = pathname.split('/').slice(0, 3).join('/')
  const serverRelativeUrl = pathname

  // https://pergasab.sharepoint.com/sites/foo/_api...
  const url = `${origin}${siteCollection}/_api/web/GetFileByServerRelativeUrl('${serverRelativeUrl}')/$value`
  const opts = getRequestOptions(spToken)
  opts.headers.Accept = 'application/octet-stream'
  const res = await fetch(url, opts)
  if (!res.ok) {
    throw new Error(`readFile() failed (${res.status})`)
  }
  return new Uint8Array(await res.arrayBuffer())
}

/**
 * Writes a file to SharePoint.
 * Returns metadata about the file and the file item in the document folder.
 *
 * siteUrl: url to site
 * path: server relative url to folder, e.g. `files`, `files/foo`, `files/foo/bar`
 * for contact, project and department root folders for a category the folders would
 * instead start with the category id e.g. `files_34`, `files_34/foo`, `files_34/foo/bar`
 * fileName: file name including file extension
 * fileData: string | Uint8array
 * spToken: sharepoint access token
 */
export async function writeSharePointFile ({
  siteUrl,
  path,
  fileName,
  fileData
}, spToken) {
  const opts = getRequestOptions(spToken)
  opts.headers['IF-MATCH'] = '*'
  opts.method = 'POST'
  opts.body = fileData

  const url = `${siteUrl}/_api/web/GetFolderByServerRelativeUrl('${path}')/Files/add(url='${fileName}',overwrite=true)?$expand=ListItemAllFields,ListItemAllFields/ParentList`
  const res = await fetch(url, opts)
  if (!res.ok) {
    throw new Error(`writeSharePointFile() failed (${res.status}))`)
  }

  const result = (await res.json()).d
  const serverRelativeUrl = result.ServerRelativeUrl
  const listItem = result.ListItemAllFields
  const listId = listItem.ParentList.Id
  const listItemUrl = `${siteUrl}/_api/Web/Lists(guid'${listId}')/Items(${listItem.Id})`
  return {
    absoluteUrl: absoluteUrl(siteUrl, serverRelativeUrl),
    serverRelativeUrl,
    listItemUrl
  }
}

/**
 * Update an email list item after it has been created.
 * siteUrl: site url
 * listItemUrl: url to list item
 * fields: email field properties
 * spToken: sharepoint access token
 */
export async function updateEmailListItemFields ({
  siteUrl,
  listItemUrl,
  fields
}, spToken) {
  const contentTypes = await getSiteContentTypes(siteUrl, spToken)
  const email = contentTypes.find(({ Name }) => Name === PDS_EMAIL_CONTENT_TYPE)
  if (!email) {
    throw new Error('No email content-type found')
  }

  const data = {
    __metadata: {
      type: 'SP.ListItem'
    },
    ContentTypeId: email.StringId,
    Title: fields.subject,
    pdscol_document_class: fields.documentClass,
    pdscol_document_date: fields.documentDate,
    pdscol_email_receiver_address: fields.to,
    pdscol_email_send_address: fields.sender,
    pdscol_email_sent_time: fields.dateTimeCreated
  }

  const opts = getRequestOptions(spToken)
  opts.headers['X-HTTP-Method'] = 'MERGE'
  opts.headers['If-Match'] = '*'
  opts.method = 'POST'
  opts.body = JSON.stringify(data)

  const res = await fetch(listItemUrl, opts)
  if (!res.ok) {
    throw new Error(`updateEmailListItemFields() failed (${res.status})`)
  }
}

/**
 * Returns available content types.
 * siteUrl: site url
 * spToken: sharepoint access token
 */
async function getSiteContentTypes (siteUrl, spToken) {
  const opts = getRequestOptions(spToken)
  const res = await fetch(`${siteUrl}/_api/Web/AvailableContentTypes`, opts)
  if (!res.ok) {
    throw new Error(`getSiteContentTypes() failed (${res.status})`)
  }
  const result = await res.json()
  return result?.d?.results || []
}

/**
 * Search files in a document library.
 *
 * siteUrl: url to site
 * serverRelativeUrl: server relative url to list
 * query: search string
 * onResults: callback on results
 * onEnd: callback when finished
 * spToken: sharepoint access token
 */
export async function searchDocumentLibrary ({
  siteUrl,
  serverRelativeUrl,
  query,
  onResults,
  onEnd
}, spToken) {
  const opts = getRequestOptions(spToken)

  const GET = async (url) => {
    const res = await fetch(url, opts)
    if (!res.ok) {
      throw new Error(`partial search failed (${res.status})`)
    }
    const data = await res.json()
    const { results } = data.d
    if (Array.isArray(results)) {
      onResults(results.map(f => f.File))
    }

    const nextUrl = data?.d?.__next
    if (typeof nextUrl === 'string') {
      await GET(nextUrl)
    } else {
      onEnd()
    }
  }

  const list = await getList(siteUrl, serverRelativeUrl, opts)
  const startUrl = `${siteUrl}/_api/Web/Lists/getById('${list.Id}')/items?$expand=File,FieldValuesAsText&$select=File,FieldValuesAsText&$filter=FSObjType eq '0' and substringof('${query}',FileLeafRef)`
  await GET(startUrl)
}

/**
 * Get a list by its relative url.
 *
 * siteUrl: url to site
 * serverRelativeUrl: server relative url to list
 * opts: auth options
 */
async function getList (siteUrl, listRelativeUrl, opts) {
  const select = '$select=Title,RootFolder/ServerRelativeUrl'
  const expand = '$expand=RootFolder'
  const filter = `$filter=RootFolder/ServerRelativeUrl eq '${listRelativeUrl}'`
  const url = `${siteUrl}/_api/web/lists?${select}&${expand}&${filter}`

  const res = await fetch(url, opts)
  if (!res.ok) {
    throw new Error(`selecting list failed (${res.status})`)
  }

  const data = await res.json()
  if (Array.isArray(data.d?.results) && data.d.results.length > 0) {
    const listUri = data.d.results[0].__metadata.id
    const res = await fetch(listUri, opts)
    if (!res.ok) {
      throw new Error(`fetching list data failed (${res.status})`)
    }
    return (await res.json()).d
  } else {
    throw new Error(`no relative url for site '${siteUrl}'`)
  }
}

/**
 * Returns request options needed to interact with the SharePoint REST api.
 */
function getRequestOptions (token) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json;odata=verbose',
      'Content-Type': 'application/json;odata=verbose'
    }
  }
}
