import React from 'react'
import { SvgIcon } from '@material-ui/core'
import {
  ArrowDownward,
  ArrowUpward,
  ContactsOutlined,
  FileCopy,
  Help,
  Language,
  SettingsApplicationsOutlined
} from '@material-ui/icons'
import {
  ArchivedFileIcon,
  CodeFileIcon,
  CompressedFileIcon,
  EmailFileIcon,
  ExcelFileIcon,
  ExcelTemplateFileIcon,
  ExecutableFileIcon,
  FolderIcon,
  GenericFileIcon,
  ImageFileIcon,
  MarkupFileIcon,
  MusicFileIcon,
  OneNoteFileIcon,
  PdfFileIcon,
  PowerPointFileIcon,
  PowerPointTemplateFileIcon,
  ProjectFileIcon,
  RichTextFileIcon,
  SystemFileIcon,
  TextFileIcon,
  VectorImageFileIcon,
  VideoFileIcon,
  WordFileIcon,
  WordTemplateFileIcon
} from './file-icons'
import {
  getFileExtension,
  WORD_FILES,
  WORD_TEMPLATE_FILES,
  EXCEL_FILES,
  EXCEL_TEMPLATE_FILES,
  POWERPOINT_FILES,
  POWERPOINT_TEMPLATE_FILES
} from '../util'

/**
 * Wrappers around Material UI icons with naming that fits our domain.
 */
export const TemplateIcon = FileCopy
export const UnknownIcon = Help
export const SettingsIcon = SettingsApplicationsOutlined
export const LocaleIcon = Language
export const SortAscending = ArrowUpward
export const SortDescending = ArrowDownward

export const PergasIconColored = (props) => {
  return (
    <SvgIcon
      viewBox='0 0 25.179 25.179'
      {...props}
    >
      <path id='Rectangle_13810' d='M0 0H6.867V6.867H0z' fill='#266783' />
      <path id='Rectangle_13811' d='M0 0H6.867V6.867H0z' fill='#3a4a54' transform='translate(9.156)' />
      <path id='Rectangle_13812' d='M0 0H6.867V6.867H0z' fill='#3a4a54' transform='translate(18.312)' />
      <path id='Rectangle_13813' d='M0 0H6.867V6.867H0z' fill='#266783' transform='translate(9.156 9.156)' />
      <path id='Rectangle_13814' d='M0 0H6.867V6.867H0z' fill='#3a4a54' transform='translate(18.312 9.156)' />
      <path id='Rectangle_13815' d='M0 0H6.867V6.867H0z' fill='#266783' transform='translate(0 18.312)' />
      <path id='Rectangle_13816' d='M0 0H6.867V6.867H0z' fill='#3a4a54' transform='translate(18.312 18.312)' />
    </SvgIcon>
  )
}

export const MicrosoftIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path fill='#f35325' d='M0 0h10v10H0z' />
      <path fill='#81bc06' d='M11 0h10v10H11z' />
      <path fill='#05a6f0' d='M0 11h10v10H0z' />
      <path fill='#ffba08' d='M11 11h10v10H11z' />
    </SvgIcon>
  )
}

export const DocumentLibraryIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' height='20' viewBox='0 0 24 24' width='20'>
        <path d='M0 0h24v24H0z' fill='none' />
        <path d='M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z' />
      </svg>
    </SvgIcon>
  )
}

export const ContactPersonIcon = ContactsOutlined

export const ContactIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' enableBackground='new 0 0 24 24' height='24' viewBox='0 0 24 24' width='24'>
        <rect fill='none' height='24' width='24' />
        <path d='M12,7V3H2v18h20V7H12z M10,19H4v-2h6V19z M10,15H4v-2h6V15z M10,11H4V9h6V11z M10,7H4V5h6V7z M20,19h-8V9h8V19z M18,11h-4v2 h4V11z M18,15h-4v2h4V15z' />
      </svg>
    </SvgIcon>
  )
}

export const ProjectIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' enableBackground='new 0 0 24 24' height='24' viewBox='0 0 24 24' width='24'>
        <g><rect fill='none' height='24' width='24' /></g>
        <g>
          <g>
            <path d='M20,3H4C2.9,3,2,3.9,2,5v14c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V5 C22,3.9,21.1,3,20,3z M20,19H4V5h16V19z' fillRule='evenodd' />
            <polygon fillRule='evenodd' points='19.41,10.42 17.99,9 14.82,12.17 13.41,10.75 12,12.16 14.82,15' />
            <rect fillRule='evenodd' height='2' width='5' x='5' y='7' />
            <rect fillRule='evenodd' height='2' width='5' x='5' y='11' />
            <rect fillRule='evenodd' height='2' width='5' x='5' y='15' />
          </g>
        </g>
      </svg>
    </SvgIcon>
  )
}

export const DepartmentIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'>
        <path d='M0 0h24v24H0z' fill='none' />
        <path d='M17 15h2v2h-2zM17 11h2v2h-2zM17 7h2v2h-2zM13.74 7l1.26.84V7z' />
        <path d='M10 3v1.51l2 1.33V5h9v14h-4v2h6V3z' />
        <path d='M8.17 5.7L15 10.25V21H1V10.48L8.17 5.7zM10 19h3v-7.84L8.17 8.09 3 11.38V19h3v-6h4v6z' />
      </svg>
    </SvgIcon>
  )
}

export const CategoryIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'>
        <path d='M0 0h24v24H0V0z' fill='none' />
        <path d='M12 2l-5.5 9h11L12 2zm0 3.84L13.93 9h-3.87L12 5.84zM17.5 13c-2.49 0-4.5 2.01-4.5 4.5s2.01 4.5 4.5 4.5 4.5-2.01 4.5-4.5-2.01-4.5-4.5-4.5zm0 7c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5zM3 21.5h8v-8H3v8zm2-6h4v4H5v-4z' />
      </svg>
    </SvgIcon>
  )
}

export const TemplateManagerIcon = (props) => {
  return (
    <svg {...props} viewBox='0 0 24 24' fill='none'>
      <path d='M10.848 1.99979L21.4207 4.68278C21.5651 4.67433 21.7071 4.7233 21.8156 4.81902C21.9241 4.91473 21.9904 5.04945 22 5.19381V16.5648C21.9904 16.7092 21.9241 16.8439 21.8156 16.9396C21.7071 17.0354 21.5651 17.0843 21.4207 17.0759L10.848 14.2651C10.7036 14.2735 10.5617 14.2246 10.4532 14.1288C10.3447 14.0331 10.2784 13.8984 10.2687 13.754V2.51083C10.2784 2.36646 10.3447 2.23175 10.4532 2.13603C10.5617 2.04031 10.7036 1.99134 10.848 1.99979Z' fill='#073A53' />
      <path d='M6.71359 4.3446L17.2862 7.02759C17.4307 7.01914 17.5726 7.06811 17.6811 7.16383C17.7896 7.25954 17.8559 7.39426 17.8655 7.53863V18.9097C17.8559 19.054 17.7896 19.1887 17.6811 19.2845C17.5726 19.3802 17.4307 19.4291 17.2862 19.4207L6.71359 16.6099C6.56915 16.6183 6.42722 16.5694 6.31872 16.4737C6.21021 16.3779 6.14392 16.2432 6.13428 16.0989V4.85564C6.14392 4.71127 6.21021 4.57656 6.31872 4.48084C6.42722 4.38512 6.56915 4.33615 6.71359 4.3446Z' fill='#4D84A0' />
      <path d='M2.57996 7.2186L13.1609 9.84917C13.304 9.83946 13.4451 9.88656 13.5537 9.98025C13.6623 10.0739 13.7295 10.2067 13.7409 10.3496V21.4991C13.7298 21.642 13.6627 21.7747 13.5544 21.8685C13.446 21.9623 13.305 22.0097 13.162 22.0002L2.57996 19.2439C2.43684 19.2537 2.29564 19.2066 2.187 19.1129C2.07837 19.0192 2.01108 18.8864 1.99973 18.7434V7.71998C2.0108 7.57699 2.07781 7.44415 2.18623 7.35028C2.29465 7.25641 2.43572 7.20909 2.57881 7.2186' fill='#9AADBA' />
      <path d='M8.46332 10.4469L5.17203 9.54123C5.07407 9.50821 4.96935 9.50052 4.86761 9.51887C4.76588 9.53723 4.67045 9.58104 4.59021 9.64621C4.50997 9.71139 4.44753 9.79581 4.40871 9.89162C4.36988 9.98743 4.35594 10.0915 4.36818 10.1942L4.48345 17.2181C4.49524 17.4646 4.58112 17.7018 4.72986 17.8986C4.87861 18.0955 5.08329 18.2429 5.31716 18.3215L10.2585 19.6812C10.3565 19.7146 10.4615 19.7226 10.5635 19.7045C10.6655 19.6865 10.7613 19.6429 10.8419 19.5779C10.9226 19.5128 10.9854 19.4284 11.0247 19.3326C11.064 19.2367 11.0783 19.1325 11.0665 19.0295L10.9769 13.7606L8.46332 10.4469ZM9.39074 16.8197L8.15634 16.4801L8.17873 17.7973L7.35604 17.5717L7.33364 16.2545L6.09924 15.9147L6.0843 15.0363L7.31871 15.3759L7.29632 14.0591L8.11926 14.2854L8.14165 15.6026L9.37605 15.9422L9.39074 16.8197Z' fill='white' />
    </svg>
  )
}

/**
 * Icons for different types of items.
 */
export function getItemIcon (item) {
  switch (item.type) {
    case 'category':
      return CategoryIcon
    case 'contact':
      return ContactIcon
    case 'department':
      return DepartmentIcon
    case 'rootfolder':
    case 'folder':
      return FolderIcon
    case 'file':
      return getFileIcon(item.name || '')
    case 'project':
      return ProjectIcon
    default:
      return UnknownIcon
  }
}

/**
 * Return a file icon based on file extension.
 */
function getFileIcon (name) {
  const ext = getFileExtension(name)

  // TODO keep refactoring this once we have support for other
  // protocols in the Office URI Scheme

  if (WORD_FILES.includes(ext)) {
    return WordFileIcon
  } else if (WORD_TEMPLATE_FILES.includes(ext)) {
    return WordTemplateFileIcon
  } else if (EXCEL_FILES.includes(ext)) {
    return ExcelFileIcon
  } else if (EXCEL_TEMPLATE_FILES.includes(ext)) {
    return ExcelTemplateFileIcon
  } else if (POWERPOINT_FILES.includes(ext)) {
    return PowerPointFileIcon
  } else if (POWERPOINT_TEMPLATE_FILES.includes(ext)) {
    return PowerPointTemplateFileIcon
  }

  switch (ext) {
    case 'dll':
    case 'lib':
      return SystemFileIcon
    case 'exe':
      return ExecutableFileIcon
    case 'avi':
    case 'mpeg':
    case 'mkv':
      return VideoFileIcon
    case 'mp3':
    case 'ogg':
      return MusicFileIcon
    case 'h':
    case 'c':
    case 'cpp':
    case 'js':
      return CodeFileIcon
    case 'rtf':
      return RichTextFileIcon
    case 'htm':
    case 'html':
    case 'xml':
      return MarkupFileIcon
    case 'tar':
      return ArchivedFileIcon
    case 'zip':
    case 'gz':
      return CompressedFileIcon
    case 'msg':
    case 'eml':
      return EmailFileIcon
    case 'gif':
    case 'ico':
    case 'png':
    case 'jpg':
    case 'jpeg':
      return ImageFileIcon
    case 'svg':
      return VectorImageFileIcon
    case 'mpp':
      return ProjectFileIcon
    case 'one':
      return OneNoteFileIcon
    case 'pdf':
      return PdfFileIcon
    case 'txt':
      return TextFileIcon
    default:
      return GenericFileIcon
  }
}
