import store from './store.js'
import * as category from './category/actions.js'
import * as contact from './contact/actions.js'
import * as contentType from './content-type/actions.js'
import * as department from './department/actions.js'
import * as dialog from './dialog/actions.js'
import * as error from './error/actions.js'
import * as files from './files/actions.js'
import * as locale from './locale/actions.js'
import * as login from './login/actions.js'
import * as page from './page/actions.js'
import * as project from './project/actions.js'
import * as progress from './progress/actions.js'
import * as save from './save/actions.js'
import * as selected from './selected/actions.js'

export default {
  store,
  actions: {
    category,
    contact,
    contentType,
    department,
    dialog,
    error,
    files,
    locale,
    login,
    page,
    project,
    progress,
    save,
    selected
  }
}
