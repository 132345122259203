import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import { amber, green } from '@material-ui/core/colors'
import { Snackbar, SnackbarContent } from '@material-ui/core'
import { CheckCircle, Error, Info, Warning } from '@material-ui/icons'

const styles = {
  neutral: {
    backgroundColor: '#f5f5f5',
    opacity: 0.8
  },
  success: {
    backgroundColor: green[600],
    opacity: 0.8
  },
  warning: {
    backgroundColor: amber[700],
    opacity: 0.8
  },
  error: {
    backgroundColor: 'red',
    opacity: 0.8
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  messageIcon: {
    marginRight: '0.5em'
  }
}

const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: Error,
  info: Info
}

class Feedback extends Component {
  render () {
    const { open, message, onAutoClose, closeTimeout, variant, classes } = this.props
    const Icon = variantIcon[variant]
    const timeout = closeTimeout || 3000
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={open}
        autoHideDuration={timeout}
        onClose={onAutoClose}
      >
        <SnackbarContent
          classes={{ root: classes[variant] }}
          message={
            <span className={classes.message}>
              <Icon className={classes.messageIcon} />
              {message}
            </span>
          }
        />
      </Snackbar>
    )
  }
}

Feedback.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
  onAutoClose: PropTypes.func.isRequired,
  closeTimeout: PropTypes.number
}

export default withStyles(styles)(Feedback)
