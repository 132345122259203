import {
  SHOW_DIALOG_ACTION,
  HIDE_DIALOG_ACTION
} from './actions'

const DEFAULT = {
  name: null,
  meta: null
}

/**
 * Reducer for system dialog.
 */
export function dialog (state = DEFAULT, action) { // eslint-disable-line
  switch (action.type) {
    case SHOW_DIALOG_ACTION: {
      const { name, meta } = action
      return Object.assign({}, state, { name, meta })
    }
    case HIDE_DIALOG_ACTION:
      return DEFAULT
    default:
      return state
  }
}
