import { ERROR_ACTION } from '../error/actions.js'
import {
  BEGIN_PROGRESS_DIALOG_ACTION,
  UPDATE_PROGRESS_DIALOG_ACTION,
  END_PROGRESS_DIALOG_ACTION
} from './actions.js'

const DEFAULT = {
  show: false,
  title: '',
  status: '',
  statusParam: ''
}

// TODO when we receieve an error, we should automatically
// reset the progress dialog

/**
 * Reducer for progress dialog
 */
export function progress (state = DEFAULT, action) { // eslint-disable-line
  switch (action.type) {
    case BEGIN_PROGRESS_DIALOG_ACTION: {
      const { title, status, statusParam } = action
      return Object.assign({}, state, { show: true, title, status, statusParam })
    }
    case UPDATE_PROGRESS_DIALOG_ACTION: {
      const { status, statusParam } = action
      return Object.assign({}, state, { status, statusParam })
    }
    case ERROR_ACTION:
    case END_PROGRESS_DIALOG_ACTION:
      return Object.assign({}, state, DEFAULT)
    default:
      return state
  }
}
