import {
  SET_SAVE_DIALOG_FILENAME_ACTION,
  READ_DOCUMENT_PROPERTIES_ACTION,
  SET_PROPERTY_ACTION
} from './actions'
import {
  FILES_SET_SELECTED_FILE_ACTION
} from '../files/actions'

const SAVE_DATA_DEFAULT = {
  documentProperties: {},
  fileName: ''
}

/**
 * Reducer for the save dialog
 */
export function save (state = SAVE_DATA_DEFAULT, action) { // eslint-disable-line
  switch (action.type) {
    case SET_SAVE_DIALOG_FILENAME_ACTION: {
      const { fileName } = action
      return Object.assign({}, state, { fileName })
    }
    case FILES_SET_SELECTED_FILE_ACTION: {
      const { file } = action
      return Object.assign({}, state, { fileName: file.name })
    }
    case READ_DOCUMENT_PROPERTIES_ACTION: {
      const { documentProperties } = action
      return Object.assign({}, state, { documentProperties })
    }
    case SET_PROPERTY_ACTION: {
      const { key, value } = action
      const { documentProperties } = state
      const updated = Object.assign({}, documentProperties, {
        [key]: value
      })
      return Object.assign({}, state, { documentProperties: updated })
    }
    default:
      return state
  }
}
