import { notBusy } from '../busy/actions'
import ERROR_TYPES from './error-types'

export const ERROR_ACTION = 'ERROR_ACTION'
export const RESET_ERROR_ACTION = 'RESET_ERROR_ACTION'

const ERROR_TYPE_AUTH = 'ERROR_TYPE_AUTH'
const ERROR_TYPE_NETWORK = 'ERROR_TYPE_NETWORK'
const ERROR_TYPE_UNKNOWN = 'ERROR_TYPE_UNKNOWN'

/**
 * Used by:
 * - save when adding new file.save event
 */
export function handleAddErrors (scope, dispatch) {
  return function (err) {
    dispatch(notBusy())
    const { response } = err
    if (response) {
      if (response.status === 400) {
        dispatch(crudError(err, ERROR_TYPES[scope].add))
      } else if (response.status === 401) {
        dispatch(authError())
      } else if (response.status === 403) {
        dispatch(crudError(err, ERROR_TYPES[scope].noAddPermission))
      } else {
        dispatch(unknownError(err))
      }
    } else {
      dispatch(networkError(err))
    }
  }
}

/**
 * TODO make a note of what uses this and make sure it plays nicely with
 * error messages etc.
 */
export function handleGetErrors (scope, dispatch) {
  return function (err) {
    dispatch(notBusy())
    const { response } = err
    if (response) {
      if (response.status === 400) {
        dispatch(crudError(err, ERROR_TYPES[scope].get))
      } else if (response.status === 401) {
        dispatch(authError())
      } else if (response.status === 403) {
        dispatch(crudError(err, ERROR_TYPES[scope].noGetPermission))
      } else if (response.status === 404) {
        dispatch(crudError(err, ERROR_TYPES[scope].notFound))
      }
    } else {
      dispatch(networkError(err))
    }
  }
}

/**
 * TODO make a note of what uses this and make sure it plays nicely with
 * error messages etc.
 */
export function handleUpdateErrors (scope, dispatch) {
  return function (err) {
    dispatch(notBusy())
    const { response } = err
    if (response) {
      if (response.status === 400) {
        dispatch(crudError(err, ERROR_TYPES[scope].update))
      } else if (response.status === 401) {
        dispatch(authError())
      } else if (response.status === 403) {
        dispatch(crudError(err, ERROR_TYPES[scope].noUpdatePermission))
      } else if (response.status === 404) {
        dispatch(crudError(err, ERROR_TYPES[scope].notFound))
      } else {
        dispatch(unknownError(err))
      }
    } else {
      dispatch(networkError(err))
    }
  }
}

function crudError (err, errorType) {
  return {
    type: ERROR_ACTION,
    errorType,
    errorMessage: err.message
  }
}

function authError () {
  return {
    type: ERROR_ACTION,
    errorType: ERROR_TYPE_AUTH
  }
}

export function networkError (err) {
  return {
    type: ERROR_ACTION,
    errorType: ERROR_TYPE_NETWORK,
    errorMessage: err.message
  }
}

export function unknownError (err) {
  return {
    type: ERROR_ACTION,
    errorType: ERROR_TYPE_UNKNOWN,
    errorMessage: err.message
  }
}

export function resetError () {
  return {
    type: RESET_ERROR_ACTION
  }
}
