import React from 'react'
import { connect } from 'react-redux'
import { Button, MenuItem, Tooltip } from '@material-ui/core'
import {
  Favorite,
  FavoriteBorder,
  History,
  FirstPage,
  LastPage,
  NavigateBefore,
  NavigateNext,
  SkipNext,
  SkipPrevious
} from '@material-ui/icons'
import TextField from '../components/TextField.js'
import { SortAscending, SortDescending } from '../icons/index.js'
import Toolbar from './Toolbar.js'
import { FAVORITE_COLOR, RECENT_COLOR } from '../styles.js'

const LIMIT_ITEMS = [10, 25, 50, 100, 500]

const FilterToolbar = ({
  title,

  onFilterFavoriteToggle,
  showFavorites,
  onFilterRecentToggle,
  showRecent,

  onSortChange,
  order,

  pageCount,
  currentPage,
  onPageChange,
  onChangeLimit,
  limit,

  // From redux
  strings,
  sharePointSaveEnabled
}) => {
  const renderRecentButton = () => {
    if (sharePointSaveEnabled) {
      return (
        <Tooltip title={strings.show_recent} arrow>
          <span>
            <Button
              onClick={onFilterRecentToggle}
              style={recentButtonStyle(showRecent)}
            >
              <History />
            </Button>
          </span>
        </Tooltip>
      )
    } else {
      return null
    }
  }

  const setPage = (page) => {
    if (page <= 1) page = 1
    if (page >= pageCount) page = pageCount
    onPageChange(page)
  }

  const sortDisabled = sharePointSaveEnabled && showRecent

  return (
    <Toolbar>
      <div style={rightIconsStyle}>
        <TextField
          select
          value={limit}
          fullWidth={false}
          style={limitSelectStyle}
          onChange={(ev) => onChangeLimit(ev.target.value)}
        >
          {LIMIT_ITEMS.map(l => <MenuItem key={l} value={l}>{l}</MenuItem>)}
        </TextField>
        <Tooltip title={strings.navigate_first_page} arrow>
          <span>
            <Button
              onClick={() => setPage(1)}
              disabled={pageCount === 0 || currentPage === 1}
              style={navigateButtonStyle}
            >
              <FirstPage />
            </Button>
          </span>
        </Tooltip>
        <Tooltip title={strings.navigate_back_many} arrow>
          <span>
            <Button
              onClick={() => setPage(currentPage - 10)}
              disabled={pageCount === 0 || currentPage === 1}
              style={navigateButtonStyle}
            >
              <SkipPrevious />
            </Button>
          </span>
        </Tooltip>
        <Tooltip title={strings.navigate_back} arrow>
          <span>
            <Button
              onClick={() => setPage(currentPage - 1)}
              disabled={pageCount === 0 || currentPage === 1}
              style={navigateButtonStyle}
            >
              <NavigateBefore />
            </Button>
          </span>
        </Tooltip>
        <Tooltip title={strings.navigate_forward} arrow>
          <span>
            <Button
              onClick={() => setPage(currentPage + 1)}
              disabled={pageCount === 0 || currentPage === pageCount}
              style={navigateButtonStyle}
            >
              <NavigateNext />
            </Button>
          </span>
        </Tooltip>
        <Tooltip title={strings.navigate_forward_many} arrow>
          <span>
            <Button
              onClick={() => setPage(currentPage + 10)}
              disabled={pageCount === 0 || currentPage === pageCount}
              style={navigateButtonStyle}
            >
              <SkipNext />
            </Button>
          </span>
        </Tooltip>
        <Tooltip title={strings.navigate_last_page} arrow>
          <span>
            <Button
              onClick={() => setPage(pageCount)}
              disabled={pageCount === 0 || currentPage === pageCount}
              style={navigateButtonStyle}
            >
              <LastPage />
            </Button>
          </span>
        </Tooltip>
        <Tooltip title={strings.change_sort} arrow>
          <span>
            <Button
              disabled={sortDisabled}
              onClick={onSortChange}
              style={sortButtonStyle(sortDisabled)}
            >
              {order === 'asc' ? <SortAscending /> : <SortDescending />}
            </Button>
          </span>
        </Tooltip>
        <Tooltip title={strings.show_favorites} arrow>
          <span>
            <Button
              onClick={onFilterFavoriteToggle}
              style={favoriteButtonStyle}
            >
              {showFavorites ? <Favorite /> : <FavoriteBorder />}
            </Button>
          </span>
        </Tooltip>
        {renderRecentButton()}
      </div>
    </Toolbar>
  )
}

const buttonPadding = 1
const rightIconsStyle = { display: 'flex' }
const limitSelectStyle = { margin: 0 }
const navigateButtonStyle = {
  minWidth: 24,
  height: '100%',
  paddingLeft: buttonPadding,
  paddingRight: buttonPadding
}

const favoriteButtonStyle = {
  color: FAVORITE_COLOR,
  minWidth: 24,
  height: '100%',
  paddingLeft: buttonPadding,
  paddingRight: buttonPadding
}

const sortButtonStyle = (disabled) => {
  return {
    color: disabled ? 'grey' : RECENT_COLOR,
    minWidth: 24,
    height: '100%',
    paddingLeft: buttonPadding,
    paddingRight: buttonPadding
  }
}

const recentButtonStyle = (showRecent) => {
  return {
    color: showRecent ? RECENT_COLOR : 'grey',
    minWidth: 24,
    height: '100%',
    paddingLeft: buttonPadding,
    paddingRight: buttonPadding
  }
}

const mapStateToProps = (state) => {
  return {
    strings: state.locale.strings,
    sharePointSaveEnabled: state.login.userData.sharePointSaveEnabled
  }
}

export default connect(mapStateToProps)(FilterToolbar)
