import { CONTENT_TYPE_GET_OK_ACTION } from './actions'

/**
 * Reducer for handling content types and fields.
 */
export function contentType (state = { items: [] }, action) { // eslint-disable-line
  switch (action.type) {
    case CONTENT_TYPE_GET_OK_ACTION:
      return Object.assign({}, state, {
        items: action.items.slice()
      })
    default:
      return state
  }
}
