import React from 'react'
import { connect } from 'react-redux'
import {
  InputAdornment,
  Tooltip
} from '@material-ui/core'
import {
  CancelOutlined,
  Search
} from '@material-ui/icons'
import TextField from '../components/TextField.js'
import redux from '../redux/index.js'

const searchInputProps = {
  startAdornment: (
    <InputAdornment position='start'>
      <Search />
    </InputAdornment>
  ),
  inputProps: { autoCapitalize: 'off', autoComplete: 'off', autoCorrect: 'off', spellCheck: 'false' }
}

const ENTER = ['Enter', 'NumpadEnter']

const FileSearchField = ({
  selected,
  searchValue,
  showSearchResults,
  isSearching,
  strings,
  onSearchValueChange,
  onStartSearch,
  onResetSearch
}) => {
  const fieldEnabled = selected.current && !isSearching
  const buttonEnabled = fieldEnabled && (showSearchResults || searchValue)

  const onChange = (event) => onSearchValueChange(event.target.value)

  const onKeyPress = (event) => {
    if (ENTER.includes(event.key) && searchValue.length >= 2) {
      onStartSearch(selected.current, searchValue)
    }
  }

  const onButtonClick = () => {
    if (buttonEnabled) {
      onResetSearch()
    }
  }

  return (
    <div>
      <TextField
        value={searchValue}
        onChange={onChange}
        onKeyPress={onKeyPress}
        InputProps={searchInputProps}
        style={searchFieldStyle}
        disabled={!fieldEnabled}
      />
      <Tooltip title={strings.file_search_clear} arrow>
        <CancelOutlined
          style={closeButtonStyle(buttonEnabled)}
          onClick={onButtonClick}
        />
      </Tooltip>
    </div>
  )
}

const searchFieldStyle = {
  background: 'white',
  margin: 0
}

const closeButtonStyle = (enabled) => {
  return {
    position: 'absolute',
    cursor: 'pointer',
    zIndex: 7,
    right: 0,
    bottom: 9,
    marginRight: 5,
    color: enabled ? '#000000' : '#888888'
  }
}

const mapStateToProps = (state) => {
  const {
    searchValue,
    showSearchResults,
    isSearching
  } = state.files
  return {
    strings: state.locale.strings,
    selected: state.selected,
    searchValue,
    showSearchResults,
    isSearching
  }
}

const mapDispatchToProps = (dispatch) => {
  const { files } = redux.actions
  return {
    onSearchValueChange: (value) => {
      dispatch(files.setSearchValue(value))
    },
    onStartSearch: (current, searchValue) => {
      dispatch(files.searchFiles(current, searchValue))
    },
    onResetSearch: () => {
      dispatch(files.resetSearch())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FileSearchField)
