import React from 'react'

const PdsSpinner = ({ size = 32, space = 10 }) => {
  const middle = size + space
  const totSize = 2 * middle + size
  return (
    <div className='pds-spinner' style={{ minWidth: `${totSize}px`, height: `${totSize}px` }}>
      <div style={{ width: `${size}px`, height: `${size}px`, top: '0px', left: '0px', animationDelay: '0s' }} />
      <div style={{ width: `${size}px`, height: `${size}px`, top: '0px', left: `${middle}px`, animationDelay: '-0.4s' }} />
      <div style={{ width: `${size}px`, height: `${size}px`, top: '0px', left: `${2 * (size + space)}px`, animationDelay: '-0.8s' }} />
      <div style={{ width: `${size}px`, height: `${size}px`, top: `${middle}px`, left: `${middle}px`, animationDelay: '-0.8s' }} />
      <div style={{ width: `${size}px`, height: `${size}px`, top: `${middle}px`, left: `${2 * middle}px`, animationDelay: '-1.2s' }} />
      <div style={{ width: `${size}px`, height: `${size}px`, top: `${2 * middle}px`, left: '0px', animationDelay: '-0.8s' }} />
      <div style={{ width: `${size}px`, height: `${size}px`, top: `${2 * middle}px`, left: `${2 * middle}px`, animationDelay: '-1.6s' }} />
    </div>
  )
}

export default PdsSpinner
