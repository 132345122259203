import storage from '../storage'
import {
  PROJECT_UPDATE_ACTION,
  PROJECT_DELETE_ACTION,
  PROJECT_GET_PAGE_ITEM_ACTION,
  PROJECT_GET_PAGE_ITEMS_ACTION,
  PROJECT_SET_ORDER_ACTION,
  PROJECT_SET_LIMIT_ACTION,
  PROJECT_SET_OFFSET_ACTION,
  PROJECT_SET_SEARCH_ACTION,
  PROJECT_TOGGLE_SHOW_FAVORITES,
  PROJECT_TOGGLE_SHOW_RECENT
} from './actions'

const PAGE_DEFAULT = {
  // Page items, used for pagination on item pages
  pageItems: [],
  // Total count, for pagination
  totalCount: 0,
  // Number of items per page
  limit: 10,
  // Offset in database query for pagination
  offset: 0,
  // Which column data to sort/order by ('' for no specific sort order)
  orderBy: 'name',
  // Ascending ('asc'), descending ('desc') or no ('') sort order
  order: 'asc',
  // Search term, currently used as frontend filter on page items
  search: '',
  // Show favorites filter
  showFavorites: storage.getProjectFavorites(),
  // Show recent filter
  showRecent: storage.getProjectRecent()
}

/**
 * Reducer for handling projects.
 */
export function project (state = PAGE_DEFAULT, action) { // eslint-disable-line
  switch (action.type) {
    case PROJECT_UPDATE_ACTION: {
      const { project } = action
      const { showFavorites } = state
      return {
        ...state,
        pageItems: state.pageItems.map(p => {
          if (p.id === project.id) {
            if ((showFavorites && project.is_favorite) || !showFavorites) {
              return project
            } else {
              return null
            }
          } else {
            return p
          }
        }).filter(Boolean)
      }
    }
    case PROJECT_DELETE_ACTION:
      return {
        ...state,
        pageItems: state.pageItems.filter(p => p.id !== action.project.id)
      }
    case PROJECT_GET_PAGE_ITEMS_ACTION:
      return {
        ...state,
        pageItems: action.pageItems,
        totalCount: action.totalCount
      }

    case PROJECT_GET_PAGE_ITEM_ACTION: {
      const exists = state.pageItems.find(p => p.id === action.pageItem.id)
      if (exists) {
        return { ...state }
      }

      const item = { ...action.pageItem }
      return {
        ...state,
        pageItems: [item, ...state.pageItems]
      }
    }
    case PROJECT_SET_ORDER_ACTION: {
      const { order, orderBy } = action
      return { ...state, order, orderBy, offset: 0 }
    }
    case PROJECT_SET_LIMIT_ACTION:
      return { ...state, limit: action.limit, offset: 0 }
    case PROJECT_SET_OFFSET_ACTION:
      return { ...state, offset: action.offset }
    case PROJECT_SET_SEARCH_ACTION:
      return { ...state, search: action.search }
    case PROJECT_TOGGLE_SHOW_FAVORITES: {
      const showFavorites = !state.showFavorites
      setTimeout(() => storage.putProjectFavorites(showFavorites), 10)
      return { ...state, showFavorites, offset: 0 }
    }
    case PROJECT_TOGGLE_SHOW_RECENT: {
      const showRecent = !state.showRecent
      setTimeout(() => storage.putProjectRecent(showRecent), 10)
      return { ...state, showRecent, offset: 0 }
    }
    default:
      return state
  }
}
