import React, { useState } from 'react'

import { InputAdornment, SvgIcon } from '@material-ui/core'
import { CancelOutlined } from '@material-ui/icons'
import TextField from '../components/TextField.js'
import { HEADER_BACKGROUND, HEADER_HEIGHT } from '../styles.js'

const ENTER = ['Enter', 'NumpadEnter']

const Header = ({
  title,
  value,
  onSearchChange
}) => {
  const [search, setSearch] = useState(value || '')
  const buttonEnabled = search.length > 0

  const resetSearch = () => {
    if (buttonEnabled) {
      setSearch('')
      onSearchChange('')
    }
  }

  const doSearch = () => onSearchChange(search)
  const onChange = (event) => setSearch(event.target.value)

  const onKeyPress = (event) => {
    if (ENTER.includes(event.key)) {
      doSearch()
    }
  }

  const inputProps = {
    endAdornment: (
      <InputAdornment position='end'>
        <CancelOutlined
          style={closeButtonStyle(buttonEnabled)}
          onClick={resetSearch}
        />
      </InputAdornment>
    )
  }

  return (
    <div style={headerStyle}>
      <div style={{ display: 'flex', alignItems: 'center', width: '50%' }}>
        <a href={process.env.REACT_APP_CM_URL} target='_new'><PergasIcon /></a>
        <span style={titleStyle}>{title}</span>
      </div>
      {onSearchChange && <div style={{ width: '50%' }}><TextField fullWidth={false} value={search} onChange={onChange} InputProps={inputProps} style={searchFieldStyle} onKeyPress={onKeyPress} /></div>}
    </div>
  )
}

const closeButtonStyle = (enabled) => {
  return {
    position: 'absolute',
    cursor: 'pointer',
    zIndex: 7,
    right: 0,
    bottom: 9,
    marginRight: 5,
    color: enabled ? '#000000' : '#888888'
  }
}

const titleStyle = {
  color: '#ffffff',
  marginLeft: 10
}

const headerStyle = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  background: HEADER_BACKGROUND,
  height: 50
}

const searchFieldStyle = {
  background: 'white',
  marginTop: 0,
  marginBottom: 0,
  marginRight: 10,
  float: 'right'
}

const PergasIcon = ({ onClick }) => {
  return (
    <SvgIcon
      style={{ cursor: 'pointer', height: HEADER_HEIGHT, paddingLeft: '15px' }}
      viewBox='0 0 87 87'
      onClick={onClick}
    >
      <g id='Group_12901' transform='translate(3357.426 1104.298)'>
        <path id='Path_8433' d='M2.52,0,48.511,11.671a2.386,2.386,0,0,1,2.52,2.223V63.358a2.386,2.386,0,0,1-2.52,2.223L2.52,53.354A2.386,2.386,0,0,1,0,51.131V2.223A2.386,2.386,0,0,1,2.52,0Z' transform='translate(-3321.456 -1104.298)' fill='#073a53' />
        <path id='Path_8363' d='M2.52,0,48.511,11.671a2.386,2.386,0,0,1,2.52,2.223V63.358a2.386,2.386,0,0,1-2.52,2.223L2.52,53.354A2.386,2.386,0,0,1,0,51.131V2.223A2.386,2.386,0,0,1,2.52,0Z' transform='translate(-3339.441 -1094.099)' fill='#4d84a0' />
        <g id='Group_12898' transform='translate(-3357.426 -1081.597)'>
          <g id='Group_12875'>
            <path id='Path_8422' d='M2.524,0,48.551,11.443a2.37,2.37,0,0,1,2.523,2.177v48.5A2.369,2.369,0,0,1,48.556,64.3h0L2.524,52.31A2.37,2.37,0,0,1,0,50.133V2.181A2.369,2.369,0,0,1,2.519,0h0' transform='translate(0 0)' fill='#9aadba' />
            <path id='Path_8423' d='M54.8,52.772,27.632,45.708a2.58,2.58,0,0,0-3.4,2.615V79.806l6.791-5.23L54.8,80.756a2.58,2.58,0,0,0,3.4-2.615V57.153a4.787,4.787,0,0,0-3.4-4.381' transform='translate(-14.743 -32.793)' fill='#fff' />
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

export default Header
