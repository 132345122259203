import React from 'react'
import { connect } from 'react-redux'
import {
  Dialog,
  DialogContent,
  DialogTitle
} from '@material-ui/core'
import { HourglassFull } from '@material-ui/icons'
import PdsSpinner from '../components/PdsSpinner.js'
import { HEADER_BACKGROUND } from '../styles.js'

const ProgressDialog = ({ title, status }) => {
  return (
    <Dialog open fullWidth maxWidth='xs'>
      <DialogTitle style={titleStyle}>
        <HourglassFull style={iconStyle} />
        <div style={titleContentStyle}>
          {title}
        </div>
      </DialogTitle>
      <DialogContent style={contentStyle}>
        <PdsSpinner size={20} space={8} />
        <div style={statusStyle}>
          {status}...
        </div>
      </DialogContent>
    </Dialog>
  )
}

const titleStyle = {
  background: HEADER_BACKGROUND,
  color: 'white',
  paddingLeft: '10px',
  paddingTop: '4px',
  paddingBottom: '4px'
}

const iconStyle = {
  marginTop: '3px',
  float: 'left'
}

const titleContentStyle = {
  marginLeft: '30px'
}

const contentStyle = {
  padding: '15px',
  display: 'flex'
}

const statusStyle = {
  marginLeft: '15px',
  marginTop: '-3px',
  maxWidth: '320px'
}

const mapStateToProps = (state) => {
  const { strings } = state.locale
  const title = strings[state.progress.title] || ''
  let status = strings[state.progress.status] || ''
  const { statusParam } = state.progress
  if (statusParam) {
    status = status.replace('%s', statusParam)
  }
  return { title, status }
}

export default connect(mapStateToProps)(ProgressDialog)
