import * as MSAL from '@azure/msal-browser'
import config from '../config.js'

const msal = new MSAL.PublicClientApplication(config.msal)

/**
 * Fetch an access token for SharePoint
 */
export function getSpAccessToken (userData) {
  const url = new URL(userData.sc_root)
  const scopes = [`${url.protocol}//${url.host}//.default`]
  return getAccessToken(scopes, userData.email)
}

/**
 * Fetch an access token for Graph API (outlook only)
 */
export function getGraphAccessToken (userData) {
  const scopes = ['mail.read', 'mail.send']
  return getAccessToken(scopes, userData.email)
}

/**
 * Common access token function.
 */
async function getAccessToken (scopes, email) {
  const account = msal.getAccountByUsername(email)
  const request = { scopes, account }
  try {
    const result = await msal.acquireTokenSilent(request)
    return result.accessToken
  } catch (err) {
    console.error('Failed to get access token', err)
    console.info('Trying acquireTokenRedirect')
    await msal.acquireTokenRedirect(request)
  }
}

export default msal
