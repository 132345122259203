import storage from '../storage'
import {
  DEPARTMENT_UPDATE_ACTION,
  DEPARTMENT_DELETE_ACTION,
  DEPARTMENT_EXPAND_NODE_ACTION,

  DEPARTMENT_GET_PAGE_ITEMS_ACTION,
  DEPARTMENT_GET_CATEGORIES_ACTION,
  DEPARTMENT_SET_ORDER_ACTION,
  DEPARTMENT_SET_LIMIT_ACTION,
  DEPARTMENT_SET_OFFSET_ACTION,
  DEPARTMENT_SET_SEARCH_ACTION,
  DEPARTMENT_TOGGLE_SHOW_FAVORITES,
  DEPARTMENT_TOGGLE_SHOW_RECENT
} from './actions'

import {
  CATEGORY_UPDATE_ACTION,
  CATEGORY_DELETE_ACTION
} from '../category/actions.js'

const PAGE_DEFAULT = {
  // Page items, used for pagination on item pages
  pageItems: [],
  // Expand state
  expand: {},
  // Total count, for pagination
  totalCount: 0,
  // Number of items per page
  limit: 10,
  // Offset in database query for pagination
  offset: 0,
  // Which column data to sort/order by ('' for no specific sort order)
  orderBy: 'name',
  // Ascending ('asc'), descending ('desc') or no ('') sort order
  order: 'asc',
  // Search term, currently used as frontend filter on page items
  search: '',
  // Show favorites filter
  showFavorites: storage.getDepartmentFavorites(),
  // Show recent filter
  showRecent: storage.getDepartmentRecent()
}

/**
 * Reducer for handling departments.
 */
export function department (state = PAGE_DEFAULT, action) { // eslint-disable-line
  switch (action.type) {
    case DEPARTMENT_UPDATE_ACTION: {
      const { department } = action
      const { showFavorites } = state
      return {
        ...state,
        pageItems: state.pageItems.map(c => {
          if (c.id === department.id) {
            if ((showFavorites && department.is_favorite) || !showFavorites) {
              return { ...department, children: c.children }
            } else {
              return null
            }
          } else {
            return c
          }
        }).filter(Boolean)
      }
    }
    case CATEGORY_UPDATE_ACTION: {
      const { category } = action
      const { expand } = state
      return {
        ...state,
        pageItems: state.pageItems.map(d => {
          if (expand[d.id] && d.id === category.collection_id) {
            return {
              ...d,
              children: d.children.map(c => c.id === category.id ? category : c)
            }
          } else {
            return d
          }
        })
      }
    }
    case DEPARTMENT_DELETE_ACTION:
      return {
        ...state,
        pageItems: state.pageItems.filter(c => c.id !== action.department.id)
      }
    case CATEGORY_DELETE_ACTION: {
      const { category } = action
      const { expand } = state
      return {
        ...state,
        pageItems: state.pageItems.map(d => {
          if (expand[d.id] && d.id === category.collection_id) {
            return {
              ...d,
              children: d.children.filter(c => c.id !== category.id)
            }
          } else {
            return d
          }
        })
      }
    }
    case DEPARTMENT_EXPAND_NODE_ACTION: {
      const { department } = action
      const currentValue = state.expand[department.id]
      const expand = { ...state.expand, [department.id]: !currentValue }
      return { ...state, expand }
    }

    case DEPARTMENT_GET_PAGE_ITEMS_ACTION:
      return {
        ...state,
        pageItems: action.pageItems.map(item => {
          return { ...item, children: [] }
        }),
        totalCount: action.totalCount
      }
    case DEPARTMENT_GET_CATEGORIES_ACTION: {
      const { departmentId, categories } = action
      return {
        ...state,
        pageItems: state.pageItems.map(c => {
          if (c.id === departmentId) {
            return { ...c, children: categories }
          } else {
            return c
          }
        })
      }
    }
    case DEPARTMENT_SET_ORDER_ACTION: {
      const { order, orderBy } = action
      return { ...state, order, orderBy, offset: 0 }
    }
    case DEPARTMENT_SET_LIMIT_ACTION:
      return { ...state, limit: action.limit, offset: 0 }
    case DEPARTMENT_SET_OFFSET_ACTION:
      return { ...state, offset: action.offset }
    case DEPARTMENT_SET_SEARCH_ACTION:
      return { ...state, search: action.search }
    case DEPARTMENT_TOGGLE_SHOW_FAVORITES: {
      const showFavorites = !state.showFavorites
      setTimeout(() => storage.putDepartmentFavorites(showFavorites), 10)
      return { ...state, showFavorites, offset: 0 }
    }
    case DEPARTMENT_TOGGLE_SHOW_RECENT: {
      const showRecent = !state.showRecent
      setTimeout(() => storage.putDepartmentRecent(showRecent), 10)
      return { ...state, showRecent, offset: 0 }
    }
    default:
      return state
  }
}
