import store from '../store.js'
import api from '../api.js'
import { busy, notBusy } from '../busy/actions'
import { handleGetErrors, handleUpdateErrors } from '../error/actions'

export const DEPARTMENT_UPDATE_ACTION = 'DEPARTMENT_UPDATE_ACTION'
export const DEPARTMENT_DELETE_ACTION = 'DEPARTMENT_DELETE_ACTION'
export const DEPARTMENT_EXPAND_NODE_ACTION = 'DEPARTMENT_EXPAND_NODE_ACTION'

export const DEPARTMENT_GET_PAGE_ITEMS_ACTION = 'DEPARTMENT_GET_PAGE_ITEMS_ACTION'
export const DEPARTMENT_GET_CATEGORIES_ACTION = 'DEPARTMENT_GET_CATEGORIES_ACTION'
export const DEPARTMENT_SET_ORDER_ACTION = 'DEPARTMENT_SET_ORDER_ACTION'
export const DEPARTMENT_SET_LIMIT_ACTION = 'DEPARTMENT_SET_LIMIT_ACTION'
export const DEPARTMENT_SET_OFFSET_ACTION = 'DEPARTMENT_SET_OFFSET_ACTION'
export const DEPARTMENT_SET_SEARCH_ACTION = 'DEPARTMENT_SET_SEARCH_ACTION'
export const DEPARTMENT_TOGGLE_SHOW_FAVORITES = 'DEPARTMENT_TOGGLE_SHOW_FAVORITES'
export const DEPARTMENT_TOGGLE_SHOW_RECENT = 'DEPARTMENT_TOGGLE_SHOW_RECENT'

export function departmentUpdateAction (department) {
  return {
    type: DEPARTMENT_UPDATE_ACTION,
    department
  }
}

export function departmentDeleteAction (department) {
  return {
    type: DEPARTMENT_DELETE_ACTION,
    department
  }
}

export function getPageItems () {
  const state = store.getState()
  const {
    search,
    limit,
    offset,
    orderBy,
    order,
    showFavorites,
    showRecent
  } = state.department

  const sort = orderBy ? `${orderBy}.${order}` : null
  const params = {
    query: [],
    limit,
    offset,
    sort,
    recent: showRecent
  }

  if (typeof search === 'string' && search.length > 0) {
    params.query.push({ key: 'name', value: search, op: '~' })
  }

  if (showFavorites) {
    params.query.push({ key: 'is_favorite', value: showFavorites, op: '=' })
  }

  return function (dispatch) {
    dispatch(busy())
    api.getDepartments(params).then(result => {
      dispatch(notBusy())
      dispatch({
        type: DEPARTMENT_GET_PAGE_ITEMS_ACTION,
        pageItems: result.data.value,
        totalCount: result.data.total_count
      })
    }).catch(handleGetErrors('department', dispatch))
  }
}

export function getCategories (departmentId) {
  const state = store.getState()
  const { showFavorites } = state.department

  const query = [{ key: 'collection_id', value: departmentId, op: '=' }]
  if (showFavorites) {
    query.push({ key: 'is_favorite', value: showFavorites, op: '=' })
  }

  return function (dispatch) {
    dispatch(busy())
    api.getCategories({ query }).then(result => {
      dispatch(notBusy())
      dispatch({
        type: DEPARTMENT_GET_CATEGORIES_ACTION,
        departmentId,
        categories: result.data.value
      })
    }).catch(handleGetErrors('category', dispatch))
  }
}

export function toggleExpandNode (department) {
  return {
    type: DEPARTMENT_EXPAND_NODE_ACTION,
    department
  }
}

export function toggleFavorite (department) {
  return function (dispatch) {
    const favorite = !department.is_favorite
    dispatch(busy())
    api.setDepartmentFavorite(department.id, favorite).then(() => {
      dispatch(notBusy())
    }).catch(handleUpdateErrors('department', dispatch))
  }
}

/**
 * Sort order
 */
export function setOrder (orderBy, order) {
  return {
    type: DEPARTMENT_SET_ORDER_ACTION,
    orderBy,
    order
  }
}

/**
 * Set limit for pagination
 */
export function setLimit (limit) {
  return {
    type: DEPARTMENT_SET_LIMIT_ACTION,
    limit
  }
}

/**
 * Set offset for pagination
 */
export function setOffset (offset) {
  return {
    type: DEPARTMENT_SET_OFFSET_ACTION,
    offset
  }
}

/**
 * Set search
 */
export function setSearch (search) {
  return {
    type: DEPARTMENT_SET_SEARCH_ACTION,
    search
  }
}

/**
 * Toggle show favorites
 */
export function toggleShowFavorites () {
  return {
    type: DEPARTMENT_TOGGLE_SHOW_FAVORITES
  }
}

/**
 * Toggle show recent
 */
export function toggleShowRecent () {
  return {
    type: DEPARTMENT_TOGGLE_SHOW_RECENT
  }
}
