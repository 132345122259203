import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import Header from './Header.js'
import Footer from './Footer.js'
import FilterToolbar from './FilterToolbar.js'
import FileView from './FileView.js'
import FileSearchField from './FileSearchField.js'
import TreeView from './TreeView.js'
import { renderCustomIcons } from './common.js'

import redux from '../redux/index.js'
import { isSelected } from '../util.js'

const ContactPage = ({
  pdsId,
  getPageItems,
  setOrder,
  setLimit,
  setOffset,
  setSearch,

  resetSelectedItem,

  pageItems,
  expand,
  totalCount,
  limit,
  offset,
  orderBy,
  order,
  search,
  showFavorites,
  showRecent,

  onFilterFavoriteToggle,
  onFilterRecentToggle,

  onSetSelectedItem,
  onToggleContactFavorite,
  onToggleProjectFavorite,
  onClearFileView,
  onContactPersonClick,
  onPersonWithRoleClick,
  selected,
  sharePointSaveEnabled,
  templateEnabled,
  locale
}) => {
  useEffect(() => {
    getPageItems(pdsId)
  }, [getPageItems, pdsId, limit, offset, orderBy, order, search, showFavorites, showRecent])
  const checkSelected = isSelected(selected)

  const onFavoriteClick = (item) => {
    const clearFileView = (showFavorites &&
                           item &&
                           item.is_favorite &&
                           checkSelected(item))
    if (item.type === 'contact') {
      onToggleContactFavorite(item)
    } else if (item.type === 'project') {
      onToggleProjectFavorite(item)
    }
    if (clearFileView) {
      onClearFileView()
    }
  }

  const [isDragging, setIsDragging] = useState(false)
  const [origDbViewHeight, setOrigDbViewHeight] = useState(0)
  const [origFileViewHeight, setOrigFileViewHeight] = useState(0)
  const [dbViewHeight, setDbViewHeight] = useState(sharePointSaveEnabled ? '60%' : '100%')
  const [fileViewHeight, setFileViewHeight] = useState('40%')
  const [dragY, setDragY] = useState(0)

  const onMouseDown = (e) => {
    e.preventDefault()
    setIsDragging(true)
    setDragY(e.pageY)
    const dbView = document.getElementById('db-view-wrapper')
    setOrigDbViewHeight(dbView.clientHeight)
    const fileView = document.getElementById('file-view-wrapper')
    setOrigFileViewHeight(fileView.clientHeight)
  }

  const onMouseUp = (e) => setIsDragging(false)

  const onMouseMove = (e) => {
    if (isDragging) {
      const diffY = e.pageY - dragY
      setDbViewHeight(origDbViewHeight + diffY)
      setFileViewHeight(origFileViewHeight - diffY)
    }
  }

  const pageCount = Math.ceil(totalCount / limit)
  const currentPage = Math.ceil(offset / limit) + 1
  const onPageChange = (nextPage) => setOffset((nextPage - 1) * limit)
  const onChangeLimit = (l) => setLimit(l)
  const onSortChange = () => {
    onClearFileView()
    setOrder(orderBy, order === 'asc' ? 'desc' : 'asc')
  }
  const onSearchChange = (str) => {
    if (search !== str) resetSelectedItem()
    setOffset(0)
    setSearch(str)
  }

  const paginatorText = pageCount > 0 ? ` (${currentPage}/${pageCount})` : '  '
  const title = `${locale.contact_page_title}${paginatorText}`
  const onTemplateManagerClick = (item) => {
    window.open(`${process.env.REACT_APP_TM_URL}/?pds_id=${item.id}&pds_type=${item.type}`, '_blank')
  }

  const customIcons = renderCustomIcons({
    locale,
    onContactPersonClick: (item) => {
      onContactPersonClick(item, locale.contact_person_title)
    },
    onPersonWithRoleClick,
    onFavoriteClick,
    sharePointSaveEnabled,
    onTemplateManagerClick: templateEnabled && onTemplateManagerClick
  })

  return (
    <>
      <Header
        title={title}
        value={search}
        onSearchChange={onSearchChange}
      />
      <FilterToolbar
        onFilterFavoriteToggle={onFilterFavoriteToggle}
        showFavorites={showFavorites}
        onFilterRecentToggle={onFilterRecentToggle}
        showRecent={showRecent}
        onSortChange={onSortChange}
        order={order}
        pageCount={pageCount}
        currentPage={currentPage}
        onPageChange={onPageChange}
        limit={limit}
        onChangeLimit={onChangeLimit}
      />
      <div
        style={contentWrapperStyle(sharePointSaveEnabled)}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
      >
        <div id='db-view-wrapper' style={treeViewStyle(dbViewHeight)}>
          <TreeView
            items={pageItems}
            expand={expand}
            isSelected={checkSelected}
            onItemSelected={(e, item) => {
              onSetSelectedItem(item, expand, sharePointSaveEnabled)
            }}
            renderCustomIcons={customIcons}
          />
        </div>
        {sharePointSaveEnabled ? <div style={resizeSliderStyle} onMouseDown={onMouseDown} /> : null}
        {sharePointSaveEnabled ? <div id='file-view-wrapper' style={fileViewStyle(fileViewHeight)}><FileView /></div> : null}
      </div>
      {sharePointSaveEnabled ? <div style={fileSearchFieldStyle}><FileSearchField /></div> : null}
      <div style={footerStyle}><Footer /></div>
    </>
  )
}

const border = '1px solid #a3a3a3'

const contentWrapperStyle = (sharePointSaveEnabled) => {
  return {
    position: 'absolute',
    top: 90,
    bottom: sharePointSaveEnabled ? 90 : 50,
    width: '100%'
  }
}

const treeViewStyle = (height) => {
  return {
    background: 'white',
    overflow: 'auto',
    borderTop: border,
    borderLeft: border,
    borderRight: border,
    height
  }
}

const fileSearchFieldStyle = {
  background: 'white',
  position: 'absolute',
  bottom: 50,
  width: '100%'
}

const resizeSliderStyle = {
  width: '100%',
  height: 3,
  borderTop: border,
  borderLeft: border,
  borderRight: border,
  background: 'rgb(202, 221, 204)',
  cursor: 'ns-resize'
}

const fileViewStyle = (height) => {
  return {
    background: 'white',
    overflow: 'auto',
    height,
    border
  }
}

const footerStyle = {
  position: 'absolute',
  bottom: 0,
  width: '100%'
}

const mapStateToProps = (state) => {
  const {
    pageItems,
    expand,
    totalCount,
    limit,
    offset,
    orderBy,
    order,
    search,
    showFavorites,
    showRecent
  } = state.contact

  return {
    pageItems,
    expand,
    totalCount,
    limit,
    offset,
    orderBy,
    order,
    search,
    showFavorites,
    showRecent,

    selected: state.selected.current,
    locale: state.locale.strings,
    sharePointSaveEnabled: state.login.userData.sharePointSaveEnabled,
    templateEnabled: state.login.userData.sharePointTemplatesEnabled
  }
}

const mapDispatchToProps = (dispatch) => {
  const {
    contact,
    project,
    dialog,
    files,
    selected
  } = redux.actions

  return {
    getPageItems: (id) => {
      dispatch(contact.getPageItems(id))
    },
    setOrder: (orderBy, order) => dispatch(contact.setOrder(orderBy, order)),
    setLimit: (limit) => dispatch(contact.setLimit(limit)),
    setOffset: (offset) => dispatch(contact.setOffset(offset)),
    resetSelectedItem: () => dispatch(selected.resetSelectedItem()),
    setSearch: (search) => {
      dispatch(contact.setSearch(search))
    },

    onSetSelectedItem: (item, expand, sharePointSaveEnabled) => {
      if (item.type === 'contact') {
        if (!expand[item.id]) {
          dispatch(contact.getProjects(item.id))
        }
        dispatch(contact.toggleExpandNode(item))
      }
      dispatch(selected.setSelectedItem(item))
      if (sharePointSaveEnabled) dispatch(files.getRootFolder(item))
    },
    onContactPersonClick: (item, title) => {
      dispatch(contact.showContactPeopleDialog(item.id, title))
    },
    onPersonWithRoleClick: (contactId, item) => {
      dispatch(dialog.showPeopleDialog(contactId, item.person_role))
    },
    onClearFileView: () => {
      dispatch(selected.resetSelectedItem())
    },
    onToggleContactFavorite: (c) => {
      dispatch(contact.toggleFavorite(c))
    },
    onToggleProjectFavorite: (p) => {
      dispatch(project.toggleFavorite(p))
    },
    onFilterFavoriteToggle: () => {
      dispatch(selected.resetSelectedItem())
      dispatch(contact.toggleShowFavorites())
    },
    onFilterRecentToggle: () => {
      dispatch(selected.resetSelectedItem())
      dispatch(contact.toggleShowRecent())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactPage)
