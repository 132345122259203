import { CHANGE_LOCALE_ACTION } from './actions'
import storage from '../storage'
import resources from '../../resources'
const DEFAULT_LOCALE = 'sv_consultancy'

export function locale () {
  const INITIAL_LOCALE = storage.getLocale() || DEFAULT_LOCALE

  const getStrings = (locale) => {
    let strings = resources[locale]
    if (!strings) {
      console.error(`Invalid locale ${locale}, defaulting to ${DEFAULT_LOCALE}`)
      storage.putLocale(locale = DEFAULT_LOCALE)
      strings = resources[locale]
    }
    return Object.assign({}, { locale, strings })
  }

  /**
   * Reducer for language resources
   */
  return function (state = getStrings(INITIAL_LOCALE), action) { // eslint-disable-line
    switch (action.type) {
      case CHANGE_LOCALE_ACTION:
        return getStrings(action.locale)
      default:
        return state
    }
  }
}
