import clone from 'clone-deep'
import {
  FILES_SET_SELECTED_FOLDER_ACTION,
  FILES_SET_SELECTED_FILE_ACTION,
  FILES_RESET_SELECTED_FILE_ACTION,
  FILES_GET_ROOT_FOLDER_ACTION,
  FILES_GET_SUB_FOLDER_ACTION,
  FILES_SET_SEARCH_VALUE_ACTION,
  FILES_GOT_SEARCH_RESULTS_ACTION,
  FILES_BEGIN_SEARCH_ACTION,
  FILES_END_SEARCH_ACTION,
  FILES_RESET_SEARCH_ACTION
} from './actions'
import {
  RESET_SELECTED_ITEM_ACTION,
  SELECT_ITEM_ACTION
} from '../selected/actions'

const DEFAULT = {
  // Id of currently shown tree in file tree view
  currentTreeId: null,
  // Selected node in the file tree view, i.e. a file or a folder
  selectedFileNode: null,
  // All file trees with key set to `${item.type}:${item.id}` e.g.
  // 'contact:1', 'project:314' etc and each value corresponds
  // to an object { expanded: {}, items: [] } where expanded
  // stores the expand state in the tree and items the actual
  // file tree
  trees: {},
  // File Search
  isSearching: false,
  searchValue: '',
  searchResults: [],
  showSearchResults: false
}

/**
 * Reducer for the file view.
 */
export function files (state = DEFAULT, action) { // eslint-disable-line
  switch (action.type) {
    case SELECT_ITEM_ACTION: {
      const { item } = action
      const currentTreeId = `${item.type}:${item.id}`
      return Object.assign({}, state, { currentTreeId })
    }

    case RESET_SELECTED_ITEM_ACTION:
      return Object.assign({}, state, { currentTreeId: null })

    case FILES_SET_SELECTED_FOLDER_ACTION: {
      const { currentTreeId, trees } = state
      if (currentTreeId) {
        const { folder } = action
        const tree = trees[currentTreeId]
        if (tree) {
          const currentValue = tree.expand[folder.id]
          // root folder should always be expanded
          const newValue = folder.type === 'rootfolder' ? true : !currentValue
          const expand = Object.assign({}, tree.expand, {
            [folder.id]: newValue
          })
          const newTree = Object.assign({}, tree, { expand })
          return Object.assign({}, state, {
            selectedFileNode: folder,
            trees: {
              [currentTreeId]: newTree
            }
          })
        }
      }
      return state
    }

    case FILES_SET_SELECTED_FILE_ACTION: {
      const selected = action.file
      return Object.assign({}, state, { selectedFileNode: selected })
    }

    case FILES_RESET_SELECTED_FILE_ACTION: {
      return Object.assign({}, state, { selectedFileNode: null })
    }

    case FILES_GET_ROOT_FOLDER_ACTION: {
      const { siteItem, path, items } = action
      const treeId = `${siteItem.type}:${siteItem.id}`
      const { trees } = state
      let selectedFileNode = state.selectedFileNode

      let newTree
      const tree = trees[treeId]

      if (tree) {
        const root = tree.items[0]
        if (!equalItems(root.children, items)) {
          // There was a difference, just overwrite items and
          // reset expanded state
          newTree = Object.assign({}, tree, {
            expand: { [root.id]: true },
            items: [
              Object.assign({}, root, { children: items })
            ]
          })
        } else {
          // No difference, use unmodified tree
          newTree = tree
        }
      } else {
        // No previous tree, create new one
        const root = {
          name: siteItem.name,
          path,
          type: 'rootfolder',
          children: items
        }
        selectedFileNode = root
        newTree = {
          expand: { [root.id]: true },
          items: [root]
        }
      }

      const newTrees = Object.assign({}, trees, {
        [treeId]: newTree
      })

      return Object.assign({}, state, {
        selectedFileNode,
        trees: newTrees
      })
    }

    case FILES_GET_SUB_FOLDER_ACTION: {
      const { item, parent, items } = action
      const treeId = `${item.type}:${item.id}`
      const { trees } = state
      const tree = trees[treeId]

      if (tree) {
        const copy = clone(tree.items)

        let replaced = false
        mapTree(copy, (i) => {
          if (i && i.id === parent.id) {
            if (!equalItems(i.children, items)) {
              i.children = items
              replaced = true
            }
          }
        })

        if (!replaced) {
          // No replacement, just return current state.
          return state
        }
        const newExpand = Object.assign({}, tree.expand)
        items.forEach(item => {
          newExpand[item.id] = false
        })

        const newTree = Object.assign({}, tree, {
          expand: newExpand,
          items: copy
        })
        const newTrees = Object.assign({}, trees, {
          [treeId]: newTree
        })

        return Object.assign({}, state, { trees: newTrees })
      }

      return state
    }

    case FILES_SET_SEARCH_VALUE_ACTION: {
      const { searchValue } = action
      return Object.assign({}, state, { searchValue })
    }

    case FILES_GOT_SEARCH_RESULTS_ACTION: {
      const { results } = action
      const currentResults = state.searchResults
      const searchResults = currentResults.concat(results)
      return Object.assign({}, state, { searchResults })
    }

    case FILES_BEGIN_SEARCH_ACTION: {
      return Object.assign({}, state, {
        searchResults: [],
        showSearchResults: true,
        isSearching: true
      })
    }

    case FILES_END_SEARCH_ACTION: {
      return Object.assign({}, state, { isSearching: false })
    }

    case FILES_RESET_SEARCH_ACTION: {
      return Object.assign({}, state, {
        isSearching: false,
        searchValue: '',
        searchResults: [],
        showSearchResults: false
      })
    }

    default:
      return state
  }
}

/**
 * Sort items based on id.
 */
function sortItems (items) {
  return items.slice().sort((A, B) => {
    const a = A.id
    const b = B.id
    if (a < b) return -1
    else if (a > b) return 1
    else return 0
  })
}

/**
 * Compare two item arrays. Simple comparison to avoid rewriting
 * root item array if it's not needed.
 */
function equalItems (_lhs, _rhs) {
  if (_lhs.length !== _rhs.length) return false
  const lhs = sortItems(_lhs)
  const rhs = sortItems(_rhs)
  for (let i = 0; i < lhs.length; i++) {
    if (lhs[i].name !== rhs[i].name) return false
  }
  return true
}

/**
 * Takes an array and traverses down the tree.
 * Calls iterator with current element and it's parent, if any.
 */
function mapTree (items, iterator) {
  function map (item, parent = null) {
    iterator(item, parent)
    if (Array.isArray(item.children)) {
      item.children.slice().forEach(child => {
        map(child, item)
      })
    }
  }
  items.forEach(item => map(item))
}
