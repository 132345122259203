import storage from '../storage'

export const SET_CURRENT_PAGE_ACTION = 'SET_CURRENT_PAGE_ACTION'

export function setCurrentPage (page) {
  if (page !== 'templates') {
    setTimeout(() => storage.putPage(page), 20)
  }
  return {
    type: SET_CURRENT_PAGE_ACTION,
    page
  }
}
