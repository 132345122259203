import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip
} from '@material-ui/core'
import {
  AssignmentOutlined,
  AssignmentReturnOutlined,
  Close
} from '@material-ui/icons'
import redux from '../redux/index.js'
import {
  FOOTER_BACKGROUND,
  FOOTER_HEIGHT,
  HEADER_BACKGROUND,
  HEADER_HEIGHT
} from '../styles.js'
import { httpify } from '../util.js'
import {
  isWord,
  fillInContentControls,
  fillInContactContentControls,
  pastePersonData,
  isOutlookComposeMode,
  setEmailAddressComposeMode,
  pasteContactData
} from '../lib/office.js'

const onPhoneClick = phone => window.open(`tel:${phone}`)
const onEmailClick = async (emails) => await setEmailAddressComposeMode(emails)
const PeopleDialog = ({
  locale,
  contactId,
  people,
  title,
  onHidePeopleDialog
}) => {
  const contactDetails = useSelector((state) => state.contact.items.find(({ id }) => id === contactId))
  const dispatch = useDispatch()
  useEffect(() => {
    if (contactId) {
      dispatch(redux.actions.contact.getContact(contactId))
    }
  }, [dispatch, contactId])

  const renderPersons = () => {
    return people.map((p, i) => {
      const renderActions = () => {
        if (isWord()) {
          return (
            <div>
              <Tooltip title={locale.fill_content_control}>
                <AssignmentReturnOutlined
                  onClick={() => fillInContentControls(p)}
                  style={{ marginRight: 5, cursor: 'pointer' }}
                />
              </Tooltip>
              <Tooltip title={locale.paste_person_data}>
                <AssignmentOutlined
                  onClick={() => pastePersonData(p)}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            </div>
          )
        } else {
          return null
        }
      }

      const first = [
        p.person_name,
        p.role_name,
        // TODO remove person?.collection_name after views have been updated
        p.person_collection_name || p.collection_name
      ].filter(Boolean).join(', ')

      const composingOutlook = isOutlookComposeMode()

      return (
        <div key={i} style={{ marginBottom: 20 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div><b>{first}</b></div>
            {renderActions()}
          </div>
          <div>{p.person_address}</div>
          <div>{p.person_postal_code} {p.person_city}</div>
          {p.person_email && (
            <div>
              <Tooltip title={composingOutlook ? locale.paste_person_data : locale.open}>
                <a
                  href={composingOutlook ? '#' : `mailto:${p.person_email}`} onClick={() => {
                    composingOutlook && onEmailClick([{ displayName: p.person_name, emailAddress: p.person_email }])
                  }}
                >
                  {p.person_email}
                </a>
              </Tooltip>
            </div>
          )}
          {p.person_phone && <div><a href='#' onClick={() => onPhoneClick(p.person_phone)}>{p.person_phone}</a></div>}
          {p.person_homepage && <div><a href={httpify(p.person_homepage)} target='_new'>{p.person_homepage}</a></div>}
        </div>
      )
    })
  }

  return (
    <Dialog fullScreen open>
      <DialogTitle style={titleStyle}>
        <div style={titleContentStyle}>
          {title || locale.people_dialog_title}
        </div>
      </DialogTitle>
      <DialogContent style={contentStyle}>
        {contactDetails && (
          <div style={addressCard.div}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div><b><h4 style={addressCard.text}>{contactDetails.name}</h4></b></div>
                <div>
                  <Tooltip title={locale.fill_content_control}>
                    <AssignmentReturnOutlined
                      onClick={() => fillInContactContentControls(contactDetails)}
                      style={{ marginRight: 5, cursor: 'pointer' }}
                    />
                  </Tooltip>
                  <Tooltip title={locale.paste_person_data}>
                    <AssignmentOutlined
                      onClick={() => pasteContactData(contactDetails)}
                      style={{ cursor: 'pointer' }}
                    />
                  </Tooltip>
                </div>

              </div>
              <div>
                <p style={addressCard.text}>{contactDetails?.address?.address}</p>
                <p style={addressCard.text}>{contactDetails?.address?.postal_code} {contactDetails?.address?.city}</p>
                <p style={addressCard.text}>{contactDetails?.address?.email}</p>
                <p style={addressCard.text}>{contactDetails?.address?.phone}</p>
              </div>
            </div>
          </div>
        )}
        <div style={{ margin: 20, display: 'flex', flexDirection: 'column' }}>
          {renderPersons()}
        </div>
      </DialogContent>
      <DialogActions style={actionsStyle}>
        <Button
          onClick={onHidePeopleDialog}
          style={closeButtonStyle}
        >
          <Close style={closeIconStyle} />{locale.close}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const titleStyle = {
  background: HEADER_BACKGROUND,
  color: 'white',
  padding: '0px 19px',
  fontSize: '20px',
  height: HEADER_HEIGHT
}

const titleContentStyle = {
  marginTop: '8px'
}

const contentStyle = {
  background: 'white',
  padding: '0px'
}

const actionsStyle = {
  background: FOOTER_BACKGROUND,
  height: FOOTER_HEIGHT,
  padding: 0
}

const closeButtonStyle = {
  color: 'white',
  textTransform: 'none',
  marginRight: '10px'
}

const closeIconStyle = {
  marginRight: '5px'
}

const addressCard = {
  div: {
    borderBottom: '1px solid #dae1e5',
    paddingBottom: '8px',
    paddingTop: '8px'
  },
  text: {
    color: '#4f4f4f',
    margin: 0,
    padding: 0
  }
}

const mapStateToProps = (state) => {
  const { meta } = state.dialog
  return {
    locale: state.locale.strings,
    people: meta.people || [],
    title: meta.title || '',
    contactId: meta.contactId

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onHidePeopleDialog: () => {
      dispatch(redux.actions.dialog.hideDialog())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PeopleDialog)
