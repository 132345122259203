import React from 'react'
import { connect } from 'react-redux'
import redux from '../redux/index.js'
import {
  MicrosoftIcon,
  PergasIconColored
} from '../icons/index.js'

const renderButton = (title, onButtonClick) => {
  return (
    <div style={buttonStyle} onClick={onButtonClick}>
      <MicrosoftIcon style={msIconStyle} />
      <div style={buttonTextStyle}>{title}</div>
    </div>
  )
}

const buttonStyle = {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#f5f5f5',
  color: '#3a4a54',
  textTransform: 'none',
  fontFamily: 'Helvetica',
  fontSize: '14px',
  letterSpacing: '0.025em',
  lineHeight: '11px',
  borderRadius: '0',
  border: '0.5px solid #3a4a54',
  cursor: 'pointer',
  paddingTop: '8px',
  paddingLeft: '10px',
  paddingRight: '10px',
  paddingBottom: '4px',
  marginBottom: '15px'
}

const msIconStyle = {
  width: '36px',
  height: '36px'
}

const buttonTextStyle = {
  marginLeft: '10px'
}

const NoAccount = ({
  strings,
  doRetry,
  doLogout
}) => {
  const mailto = `mailto:support@pergas.se?subject=${strings.mailto_subject}`
  return (
    <div style={pageStyle}>
      <div style={middleStyle}>
        <div style={boxStyle}>
          <div style={titleStyle}>
            <PergasIconColored style={pergasIconStyle} />
            <h1 style={oopsStyle}>{strings.oops_title}</h1>
          </div>
          <h3 style={explainLoginStyle}>
            {strings.explain_login}
          </h3>
          <h3 style={emailStyle}>
            <a href={mailto}>support@pergas.se</a>
          </h3>
          {renderButton(strings.login_error_retry_button, doRetry)}
          {renderButton(strings.login_error_logout_button, doLogout)}
        </div>
      </div>
    </div>
  )
}

const pageStyle = {
  display: 'flex',
  width: '100%',
  height: '100%',
  justifyContent: 'center'
}

const middleStyle = {
  display: 'flex',
  alignItems: 'center'
}

const boxStyle = {
  border: '1px dashed rgb(234, 69, 69)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '40px'
}

const titleStyle = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '20px'
}

const pergasIconStyle = {
  width: '36px',
  height: '36px'
}

const oopsStyle = {
  marginLeft: '20px',
  fontSize: '20px',
  color: '#3a4a54'
}

const explainLoginStyle = {
  fontSize: '16px',
  color: '#3a4a54',
  maxWidth: '350px',
  padding: '0px',
  margin: '0px'
}

const emailStyle = {
  fontSize: '16px',
  color: '#3a4a54',
  marginTop: '20px',
  marginBottom: '30px',
  maxWidth: '350px'
}

const mapStateToProps = (state) => {
  return {
    strings: state.locale.strings
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    doRetry: () => {
      window.location.reload()
    },
    doLogout: () => {
      dispatch(redux.actions.login.doLogout())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NoAccount)
