import storage from '../storage'
import Permissions from '@pergas-pds/pds-permissions'
import { ERROR_ACTION } from '../error/actions.js'
import {
  LOGIN_SUCCESS_ACTION,
  ERROR_TYPE_LOGIN,
  ERROR_LOGIN_NO_ACCOUNT
} from './actions.js'

const calculatePermissions = (userData) => {
  return Permissions(userData ? userData.permissions : '')
}

/**
 * Reducer for handling login/logout.
 */
export function login () {
  const userData = storage.getUserData()
  const { token } = userData

  if (token) {
    console.info('Cached login!')
    console.info('api token:', token)
  } else {
    console.info('No cached tokens found')
  }

  console.info('email:', userData.email)
  console.info('name:', userData.name)
  console.info('address:', userData.address)
  console.info('postal_code:', userData.postal_code)
  console.info('city:', userData.city)
  console.info('homepage:', userData.homepage)
  console.info('phone:', userData.phone)

  console.info('permissions:', userData.permissions)
  console.info('tenant:', userData.tenant_name)
  console.info('sharepoint save enabled:', userData.sharePointSaveEnabled)
  console.info('sharepoint templates enabled:', userData.sharePointTemplatesEnabled)
  console.info('sc root:', userData.sc_root)

  const LOGIN_DEFAULT = {
    userData,
    permissions: calculatePermissions(userData),
    showNoAccountPage: false
  }

  return function (state = LOGIN_DEFAULT, action) { // eslint-disable-line
    switch (action.type) {
      case LOGIN_SUCCESS_ACTION: {
        const { userData } = action
        return {
          ...state,
          userData,
          permissions: calculatePermissions(userData),
          showNoAccountPage: false
        }
      }
      case ERROR_ACTION: {
        if (action.errorType === ERROR_TYPE_LOGIN &&
            action.errorMessage === ERROR_LOGIN_NO_ACCOUNT) {
          return { ...state, showNoAccountPage: true }
        } else {
          return state
        }
      }
      default:
        return state
    }
  }
}
