import storage from '../storage'
import {
  CONTACT_GET_OK_ACTION,
  CONTACT_UPDATE_ACTION,
  CONTACT_DELETE_ACTION,
  CONTACT_EXPAND_NODE_ACTION,

  CONTACT_GET_PAGE_ITEMS_ACTION,
  CONTACT_GET_PROJECTS_ACTION,
  CONTACT_SET_ORDER_ACTION,
  CONTACT_SET_LIMIT_ACTION,
  CONTACT_SET_OFFSET_ACTION,
  CONTACT_SET_SEARCH_ACTION,
  CONTACT_TOGGLE_SHOW_FAVORITES,
  CONTACT_TOGGLE_SHOW_RECENT,
  CONTACT_GET_PAGE_ITEM_ACTION
} from './actions.js'

import {
  PROJECT_UPDATE_ACTION,
  PROJECT_DELETE_ACTION
} from '../project/actions.js'

const PAGE_DEFAULT = {
  // For listing items via search (currently getting ALL contacts)
  items: [],
  // Page items, used for pagination on item pages
  pageItems: [],
  // Expand state
  expand: {},
  // Total count, for pagination
  totalCount: 0,
  // Number of items per page
  limit: 10,
  // Offset in database query for pagination
  offset: 0,
  // Which column data to sort/order by ('' for no specific sort order)
  orderBy: 'name',
  // Ascending ('asc'), descending ('desc') or no ('') sort order
  order: 'asc',
  // Search term, currently used as frontend filter on page items
  search: '',
  // Show favorites filter
  showFavorites: storage.getContactFavorites(),
  // Show recent filter
  showRecent: storage.getContactRecent()
}

/**
 * Reducer for handling contacts.
 */
export function contact (state = PAGE_DEFAULT, action) { // eslint-disable-line
  switch (action.type) {
    case CONTACT_GET_OK_ACTION:
      return { ...state, items: action.items.slice() }
    case CONTACT_UPDATE_ACTION: {
      const { contact } = action
      const { showFavorites } = state
      return {
        ...state,
        pageItems: state.pageItems.map(c => {
          if (c.id === contact.id) {
            if ((showFavorites && contact.is_favorite) || !showFavorites) {
              return { ...contact, children: c.children }
            } else {
              return null
            }
          } else {
            return c
          }
        }).filter(Boolean)
      }
    }
    case PROJECT_UPDATE_ACTION: {
      const { project } = action
      const { expand } = state
      return {
        ...state,
        pageItems: state.pageItems.map(c => {
          if (expand[c.id] && c.id === project.collection_id) {
            return {
              ...c,
              children: c.children.map(p => p.id === project.id ? project : p)
            }
          } else {
            return c
          }
        })
      }
    }
    case CONTACT_DELETE_ACTION:
      return {
        ...state,
        pageItems: state.pageItems.filter(c => c.id !== action.contact.id)
      }
    case PROJECT_DELETE_ACTION: {
      const { project } = action
      const { expand } = state
      return {
        ...state,
        pageItems: state.pageItems.map(c => {
          if (expand[c.id] && c.id === project.collection_id) {
            return {
              ...c,
              children: c.children.filter(p => p.id !== project.id)
            }
          } else {
            return c
          }
        })
      }
    }
    case CONTACT_EXPAND_NODE_ACTION: {
      const { contact } = action
      const currentValue = state.expand[contact.id]
      const expand = {
        ...state.expand,
        [contact.id]: !currentValue
      }
      return { ...state, expand }
    }
    case CONTACT_GET_PAGE_ITEM_ACTION: {
      const exists = state.pageItems.find(p => p.id === action.pageItem.id)
      if (exists) {
        return { ...state }
      }
      const item = { ...action.pageItem, children: [] }
      return {
        ...state,
        pageItems: [item, ...state.pageItems]
      }
    }
    case CONTACT_GET_PAGE_ITEMS_ACTION:
      return {
        ...state,
        pageItems: action.pageItems.map(item => {
          return { ...item, children: [] }
        }),
        totalCount: action.totalCount
      }
    case CONTACT_GET_PROJECTS_ACTION: {
      const { contactId, projects } = action
      return {
        ...state,
        pageItems: state.pageItems.map(c => {
          if (c.id === contactId) {
            return { ...c, children: projects }
          } else {
            return c
          }
        })
      }
    }
    case CONTACT_SET_ORDER_ACTION: {
      const { order, orderBy } = action
      return { ...state, order, orderBy, offset: 0 }
    }
    case CONTACT_SET_LIMIT_ACTION:
      return { ...state, limit: action.limit, offset: 0 }
    case CONTACT_SET_OFFSET_ACTION:
      return { ...state, offset: action.offset }
    case CONTACT_SET_SEARCH_ACTION:
      return { ...state, search: action.search }
    case CONTACT_TOGGLE_SHOW_FAVORITES: {
      const showFavorites = !state.showFavorites
      setTimeout(() => storage.putContactFavorites(showFavorites), 10)
      return { ...state, showFavorites, offset: 0 }
    }
    case CONTACT_TOGGLE_SHOW_RECENT: {
      const showRecent = !state.showRecent
      setTimeout(() => storage.putContactRecent(showRecent), 10)
      return { ...state, showRecent, offset: 0 }
    }
    default:
      return state
  }
}
