import { ERROR_ACTION, RESET_ERROR_ACTION } from './actions.js'

/**
 * Reducer for setting/resetting an error string.
 */
export function error (state = null, action) { // eslint-disable-line
  switch (action.type) {
    case ERROR_ACTION:
      return {
        errorType: action.errorType,
        errorMessage: action.errorMessage || ''
      }
    case RESET_ERROR_ACTION:
      return null
    default:
      return state
  }
}
