import React from 'react'
import { getItemIcon } from '../icons/index.js'
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'
import {
  ExpandLess,
  ExpandMore
} from '@material-ui/icons'

const TreeView = ({
  items,
  onItemSelected,
  expand,
  isSelected,
  renderCreateFolder = () => null,
  renderCustomIcons = () => null
}) => {
  const renderItem = (item, level) => {
    const ItemIcon = getItemIcon(item)
    const children = Array.isArray(item.children) ? item.children : []

    const renderArrow = () => {
      if (Array.isArray(item.children) && item.type !== 'rootfolder') {
        if (expand[item.id]) {
          return <ExpandLess style={{ height: 16, width: 16 }} />
        } else {
          return <ExpandMore style={{ height: 16, width: 16 }} />
        }
      } else {
        return null
      }
    }

    const renderedChildren = children.length > 0
      ? (<Collapse in={expand[item.id]}><List style={{ padding: 0, marginLeft: '20px' }}>{renderItems(children, level + 1)}</List></Collapse>)
      : null

    const primary = (<div style={listItemTextStyle}>{item.name}</div>)

    return (
      <div key={item.id}>
        <ListItem
          onClick={(e) => onItemSelected(e, item)}
          button
          selected={isSelected(item)}
          style={listItemStyle(item)}
        >
          {renderArrow()}
          <ListItemIcon style={{ minWidth: 18 }}>
            <ItemIcon style={{ height: 16 }} />
          </ListItemIcon>
          <ListItemText primary={primary} />
          {renderCreateFolder(item)}
          {renderCustomIcons(item)}
        </ListItem>
        {renderedChildren}
      </div>
    )
  }

  const renderItems = (items, level) => items.map(item => renderItem(item, level))

  return (
    <List style={{ padding: 0 }}>
      {renderItems(items, 0)}
    </List>
  )
}

const listItemStyle = (item) => {
  return {
    paddingLeft: item.type === 'rootfolder' ? '10px' : 0,
    paddingRight: 0,
    paddingTop: '2px',
    paddingBottom: '2px'
  }
}

const listItemTextStyle = {
  marginTop: 2,
  marginLeft: 4,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '0.7rem'
}

export default TreeView
