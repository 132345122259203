import React, { useEffect, useState } from 'react'
import { Button, Tooltip } from '@material-ui/core'
import { connect } from 'react-redux'
import {
  canOpenDocument,
  openDocument,
  constructDocumentParams
} from '../util.js'
import {
  AttachFile,
  OpenInBrowser as Open,
  Reply,
  ReplyAll,
  Save,
  SaveAlt,
  Send
} from '@material-ui/icons'
import {
  FOOTER_BACKGROUND,
  FOOTER_HEIGHT
} from '../styles.js'
import {
  displayReplyForm,
  displayReplyAllForm,
  hasAttachments,
  isOutlookReadMode,
  isOutlookComposeMode
} from '../lib/office.js'
import redux from '../redux/index.js'

// Reneders save attachment button for specifically Outlook Compose Mode
const HasAttachemtComposeModeButton = ({ onSaveAttachmentsClick, isFolder }) => {
  const [isEnabled, setIsEnabled] = useState(false)
  useEffect(() => {
    const fetchAttachment = async () => {
      const item = Office.context.mailbox.item
      item.getAttachmentsAsync({ asyncContext: { currentItem: item } }, (res) => {
        setIsEnabled(res.value.length > 0)
      })
    }
    if (isOutlookComposeMode()) {
      fetchAttachment()
    }
  }, [])
  const isDisabled = !(isFolder && isEnabled)
  return (
    <Button
      disabled={isDisabled}
      onClick={onSaveAttachmentsClick}
      style={actionButtonStyle(isDisabled)}
    >
      <SaveAlt style={actionIconStyle} />
    </Button>
  )
}
const APP_VERSION = (() => {
  const appVersion = process.env.REACT_APP_VERSION
  const isDev = process.env.NODE_ENV === 'development'
  if (typeof appVersion === 'string' && appVersion !== '') {
    return `${appVersion}${isDev ? ' (dev)' : ''}`
  } else {
    return ''
  }
})()

const Footer = ({
  strings,
  currentPage,
  saveDisabled,
  saveText,
  onShowSaveDialog,
  saveAttachmentsDisabled,
  onSaveAttachmentsClick,
  openDisabled,
  attachDisabled,
  selected,
  selectedFileNode,
  sendAndFileDisabled,
  onAttachFile,
  onSendAndFileClick,
  sharePointSaveEnabled,
  sharePointTemplatesEnabled,
  userData,
  showSaveButton = true,
  showSendAndFileButton = true
}) => {
  const { current, previous } = selected

  const onOpenClick = () => {
    if (selectedFileNode && selectedFileNode.type === 'file') {
      // FIXME clean this up once we remove the template page from add-in,
      // previous remembers the context when we open a file from the template page
      const context = current && current.type !== 'templates' ? current : previous
      const params = constructDocumentParams(userData, context)
      openDocument(selectedFileNode.uri, params)
    }
  }

  const renderOutlookComposeMode = () => {
    const renderAttachButton = () => {
      const onAttachClick = () => {
        onAttachFile(selectedFileNode)
      }
      if (sharePointSaveEnabled) {
        return (
          <Tooltip title={strings.attach} arrow>
            <span>
              <Button
                disabled={attachDisabled}
                onClick={onAttachClick}
                style={actionButtonStyle(attachDisabled)}
              >
                <AttachFile style={actionIconStyle} />
              </Button>
            </span>
          </Tooltip>
        )
      } else {
        return null
      }
    }

    const renderSendAndFileButton = () => {
      if (sharePointSaveEnabled && showSendAndFileButton) {
        return (
          <Tooltip title={strings.send_and_file} arrow>
            <span>
              <Button
                disabled={sendAndFileDisabled}
                onClick={() => onSendAndFileClick(current, selectedFileNode)}
                style={actionButtonStyle(sendAndFileDisabled)}
              >
                <Send style={actionIconStyle} />
              </Button>
            </span>
          </Tooltip>
        )
      } else {
        return null
      }
    }
    const renderSavePdfButton = () => {
      if (sharePointSaveEnabled) {
        return (
          <Tooltip title={strings.save_attachments} arrow>
            <span>
              <HasAttachemtComposeModeButton
                isFolder={selectedFileNode && (selectedFileNode.type === 'folder' || selectedFileNode.type === 'rootfolder')}
                onSaveAttachmentsClick={() => onSaveAttachmentsClick(current, selectedFileNode)}
              />
            </span>
          </Tooltip>
        )
      } else {
        return null
      }
    }
    return (
      <div style={footerStyle}>
        <span style={versionStyle}><i>{APP_VERSION}</i></span>
        <div style={{ float: 'right' }}>
          {renderSavePdfButton()}
          {renderAttachButton()}
          {renderSendAndFileButton()}
        </div>
      </div>
    )
  }

  const renderOpenSaveMode = () => {
    const renderReplyButtons = () => {
      if (isOutlookReadMode()) {
        return (
          <>
            <Tooltip title={strings.reply} arrow>
              <Button
                onClick={displayReplyForm}
                style={actionButtonStyle(false)}
              >
                <Reply style={actionIconStyle} />
              </Button>
            </Tooltip>
            <Tooltip title={strings.reply_all} arrow>
              <Button
                onClick={displayReplyAllForm}
                style={actionButtonStyle(false)}
              >
                <ReplyAll style={actionIconStyle} />
              </Button>
            </Tooltip>
          </>
        )
      } else {
        return null
      }
    }

    const renderSaveAttachmentsButton = () => {
      if (sharePointSaveEnabled && showSaveButton && isOutlookReadMode()) {
        return (
          <Tooltip title={strings.save_attachments} arrow>
            <span>
              <Button
                disabled={saveAttachmentsDisabled}
                onClick={() => onSaveAttachmentsClick(current, selectedFileNode)}
                style={actionButtonStyle(saveAttachmentsDisabled)}
              >
                <SaveAlt style={actionIconStyle} />
              </Button>
            </span>
          </Tooltip>
        )
      } else {
        return null
      }
    }

    const renderSaveButton = () => {
      if (sharePointSaveEnabled && showSaveButton) {
        return (
          <Tooltip title={saveText} arrow>
            <span>
              <Button
                disabled={saveDisabled}
                onClick={onShowSaveDialog}
                style={actionButtonStyle(saveDisabled)}
              >
                <Save style={actionIconStyle} />
              </Button>
            </span>
          </Tooltip>
        )
      } else {
        return null
      }
    }

    const renderOpenButton = () => {
      if (sharePointSaveEnabled || (sharePointTemplatesEnabled && currentPage === 'templates')) {
        return (
          <Tooltip title={strings.open} arrow>
            <span>
              <Button
                disabled={openDisabled}
                onClick={onOpenClick}
                style={actionButtonStyle(openDisabled)}
              >
                <Open style={actionIconStyle} />
              </Button>
            </span>
          </Tooltip>
        )
      } else {
        return null
      }
    }

    return (
      <div style={footerStyle}>
        <span style={versionStyle}><i>{APP_VERSION}</i></span>
        <div style={{ float: 'right' }}>
          {renderReplyButtons()}
          {renderOpenButton()}
          {renderSaveAttachmentsButton()}
          {renderSaveButton()}
        </div>
      </div>
    )
  }

  if (isOutlookComposeMode()) {
    return renderOutlookComposeMode()
  } else {
    return renderOpenSaveMode()
  }
}

const footerStyle = {
  backgroundColor: FOOTER_BACKGROUND,
  height: FOOTER_HEIGHT
}

const versionStyle = {
  color: 'white',
  fontSize: '10px',
  position: 'absolute'
}

export const actionButtonStyle = (disabled = false) => {
  return {
    color: disabled ? '#aaafb2' : 'white',
    marginTop: '8px',
    marginRight: '0px',
    minWidth: 24
  }
}

export const actionIconStyle = {
  marginRight: '5px'
}

const mapStateToProps = (state) => {
  const { strings } = state.locale
  const { selectedFileNode } = state.files
  const isFile = selectedFileNode && selectedFileNode.type === 'file'
  const isFolder = selectedFileNode && (selectedFileNode.type === 'folder' || selectedFileNode.type === 'rootfolder')
  const saveText = isFile ? strings.save_as : strings.save
  const canOpen = isFile && canOpenDocument(selectedFileNode.uri)
  const selectedItem = state.selected.current
  return {
    strings,
    currentPage: state.page,
    saveDisabled: !(selectedItem && selectedItem.url),
    saveText,
    openDisabled: !canOpen,
    attachDisabled: !isFile,
    selected: state.selected,
    selectedFileNode,
    sendAndFileDisabled: !isFolder,
    saveAttachmentsDisabled: !(isFolder && hasAttachments()),
    sharePointSaveEnabled: state.login.userData.sharePointSaveEnabled,
    sharePointTemplatesEnabled: state.login.userData.sharePointTemplatesEnabled,
    userData: state.login.userData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { dialog, files, save } = redux.actions
  return {
    onSendAndFileClick: (siteItem, fileItem) => {
      dispatch(save.sendAndFile(siteItem, fileItem))
    },
    onShowSaveDialog: () => {
      dispatch(save.readDocumentProperties())
      dispatch(dialog.showSaveDialog())
    },
    onAttachFile: (selectedFileNode) => {
      dispatch(files.attachFile(selectedFileNode))
    },
    onSaveAttachmentsClick: (siteItem, fileItem) => {
      dispatch(save.uploadFileAttachments(siteItem, fileItem, () => {
        const { type } = fileItem
        if (type === 'rootfolder') {
          dispatch(files.getRootFolder(siteItem))
        } else if (type === 'folder') {
          dispatch(files.getSubFolder(siteItem, fileItem))
        }
      }))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
