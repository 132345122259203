const en = {
  attach: 'Attach',
  cancel: 'Cancel',
  change_sort: 'Sort Order',
  clear: 'Clear',
  close: 'Close',
  contact_page_title: 'Contacts',
  contact_person_title: 'Contact Persons',
  create_folder: 'Create folder',
  document_library: 'Document Library',
  email: 'Email',
  favorite: 'Favorite',
  explain_login: 'You don\'t have an account with PDS. Contact your PDS administrator or send en email to us to set it up for your organization.',
  filename: 'File Name',
  file_search_clear: 'Clear Search',
  file_search_no_results: 'No Results.',
  fill_content_control: 'Fill in',
  intranet_page_title: 'Intranet',
  language: 'Language',
  loading: 'Loading...',
  login_error_retry_button: 'Reload page and try again',
  login_error_logout_button: 'Logout and try again',
  logout: 'Logout',
  mailto_subject: 'I\'d like to order / set up PDS for my organization!',
  navigate_first_page: 'First Page',
  navigate_back_many: '-10',
  navigate_back: '-1',
  navigate_forward: '+1',
  navigate_forward_many: '+10',
  navigate_last_page: 'Last Page',
  no_options: 'No options',
  open: 'Open',
  oops_title: 'Whooops !',
  paste_person_data: 'Paste',
  people_dialog_title: 'People with roles',
  project_page_title: 'Projects',
  reply: 'Reply',
  reply_all: 'Reply All',
  save: 'Save',
  save_as: 'Save As',
  save_dialog_title: 'Save',
  save_attachments: 'Save Attachments',
  send_and_file: 'Send and File',
  session: 'Session',
  settings_dialog_title: 'Settings',
  show_favorites: 'Favorites',
  show_recent: 'Recent',
  templates_page_title: 'Templates',
  templates_root_node: 'Templates',
  tenant: 'Tenant',
  user_name: 'Name',

  PROGRESS_TITLE_UPLOAD_FILE: 'Save',
  PROGRESS_STATUS_READ_FILE: 'Reading file data',
  PROGRESS_STATUS_WRITE_CUSTOM_XML: 'Writing custom xml',
  PROGRESS_STATUS_UPLOAD_FILE: 'Uploading to SharePoint',
  PROGRESS_STATUS_OPEN_FILE: 'Opening file on SharePoint. Please wait',

  PROGRESS_TITLE_SEND_AND_FILE: 'Send and File',
  PROGRESS_STATUS_SAVE_DRAFT_EMAIL: 'Saving draft',
  PROGRESS_STATUS_FETCH_EMAIL_WITH_RETRIES: 'Fetching draft email data from server. This can take some time. Please wait',
  PROGRESS_STATUS_SEND_DRAFT_EMAIL: 'Sending draft email',

  PROGRESS_TITLE_UPLOAD_ATTACHMENTS: 'Save Attachments',
  PROGRESS_STATUS_READ_ATTACHMENTS: 'Reading attachment file data from Outlook',
  PROGRESS_STATUS_UPLOAD_ATTACHMENTS: 'Uploading attachment %s to SharePoint',

  PROGRESS_TITLE_LOGOUT: 'Log out',
  PROGRESS_STATUS_LOGOUT: 'Logging out',

  PROGRESS_TITLE_ATTACH_FILE: 'Add Attachment',
  PROGRESS_STATUS_READ_SHAREPOINT_FILE: 'Reading file from SharePoint',
  PROGRESS_STATUS_ATTACH_FILE: 'Attaching file to email',

  // Error messages

  ERROR_TYPE_CATEGORY_GET: 'Failed to get intranet folders.',
  ERROR_TYPE_CATEGORY_NOT_FOUND: 'Could not find intranet folder.',
  ERROR_TYPE_CATEGORY_UPDATE: 'Failed to update intranet folder.',
  ERROR_TYPE_CATEGORY_NO_GET_PERMISSION: 'Not enough permissions to read intranet folder.',
  ERROR_TYPE_CATEGORY_NO_UPDATE_PERMISSION: 'Not enough permissions to update intranet folder.',

  ERROR_TYPE_CONTACT_GET: 'Failed to get contacts.',
  ERROR_TYPE_CONTACT_NOT_FOUND: 'Could not find contact.',
  ERROR_TYPE_CONTACT_UPDATE: 'Failed to update contact.',
  ERROR_TYPE_CONTACT_NO_GET_PERMISSION: 'Not enough permissions to read contact.',
  ERROR_TYPE_CONTACT_NO_UPDATE_PERMISSION: 'Not enough permissions to update contact.',

  ERROR_TYPE_CONTENT_TYPE_GET: 'Failed to get content types.',
  ERROR_TYPE_CONTENT_TYPE_NOT_FOUND: 'Could not find content type.',
  ERROR_TYPE_CONTENT_TYPE_NO_GET_PERMISSION: 'Not enough permissions to read content types.',

  ERROR_TYPE_DEPARTMENT_GET: 'Failed to get intranet sections.',
  ERROR_TYPE_DEPARTMENT_NOT_FOUND: 'Could not find intranet section.',
  ERROR_TYPE_DEPARTMENT_UPDATE: 'Failed to update intranet sections.',
  ERROR_TYPE_DEPARTMENT_NO_GET_PERMISSION: 'Not enough permissions to read intranet section.',
  ERROR_TYPE_DEPARTMENT_NO_UPDATE_PERMISSION: 'Not enough permissions to update intranet section.',

  ERROR_TYPE_DOC_LIB_GET: 'Failed to get files from document library.',

  ERROR_TYPE_EMAIL_READ: 'Failed to read email.',
  ERROR_TYPE_EMAIL_SAVE_DRAFT: 'Failed to save draft email.',
  ERROR_TYPE_EMAIL_SEND: 'Failed to send email.',

  ERROR_TYPE_FILE_ATTACH: 'Failed to attach file.',
  ERROR_TYPE_FILE_READ: 'Failed to read file data.',
  ERROR_TYPE_FILE_READ_ATTACHMENTS: 'Failed to read file attachments.',
  ERROR_TYPE_FILE_SAVED_ADD: 'Failed to add file saved event.',
  ERROR_TYPE_FILE_SAVED_NO_ADD_PERMISSION: 'Not enough permissions to add file saved event.',
  ERROR_TYPE_FILE_SAVE: 'Failed to save file.',
  ERROR_TYPE_FILE_SEARCH: 'Searching for files failed.',
  ERROR_TYPE_FILE_UPLOAD: 'Failed to upload file.',
  ERROR_TYPE_FILE_UPLOAD_ATTACHMENTS: 'Failed to upload file attachments.',
  ERROR_TYPE_FOLDER_CREATED_ADD: 'Failed to create folder.',
  ERROR_TYPE_PROJECT_GET: 'Failed to get projects.',
  ERROR_TYPE_PROJECT_NOT_FOUND: 'Could not find project.',
  ERROR_TYPE_PROJECT_UPDATE: 'Failed to update project.',
  ERROR_TYPE_PROJECT_NO_GET_PERMISSION: 'Not enough permissions to read project.',
  ERROR_TYPE_PROJECT_NO_UPDATE_PERMISSION: 'Not enough permissions to update project.',

  // Generic errors.

  ERROR_TYPE_AUTH: 'Authentication error. Try to login again.',
  ERROR_TYPE_LOGIN: 'Failed to login. Contact your administrator and make sure you have an account registered with PDS.',
  ERROR_TYPE_NETWORK: 'Connection refused. Make sure you have a working internet connection.',
  ERROR_TYPE_UNKNOWN: 'Unknown error. Please try again later.',

  // Content type fields
  pdscol_archived_date: 'Archived Date',
  pdscol_contact_name: 'Contact',
  pdscol_document_class: 'Document Type',
  pdscol_document_date: 'Document Date',
  pdscol_document_information: 'Document Information',
  pdscol_document_status: 'Document Status',
  pdscol_entity: 'Area',
  pdscol_gdpr: 'GDPR',
  pdscol_object_type: 'Type',
  pdscol_responsible: 'Responsible',
  pdscol_system_owner: 'PDS System Owner',
  pdscol_tags: 'Tags',
  pdscol_contact_number: 'Contact Number',
  pdscol_project_name: 'Project',
  pdscol_project_number: 'Project Number',
  pdscol_project_responsible: 'Project Responsible',
  pdscol_department: 'Department',
  pdscol_reference_id1: 'Reference Id 1',
  pdscol_reference_id2: 'Reference Id 2',
  pdscol_time_reference: 'Time Reference',
  pdscol_email_receiver_address: 'Receiver of Email',
  pdscol_email_send_address: 'Sender of Email',
  pdscol_email_sent_time: 'Email Sent Time',
  pdscol_archived_time: 'Time in months until archived',
  pdscol_template_group: 'Template Group',
  pdscol_template_information: 'Template Information'
}

const sv = {
  attach: 'Bifoga',
  cancel: 'Avbryt',
  change_sort: 'Sortering',
  clear: 'Rensa',
  close: 'Stäng',
  contact_page_title: 'Kontakter',
  contact_person_title: 'Kontaktpersoner',
  create_folder: 'Skapa mapp',
  document_library: 'Dokumentbibliotek',
  email: 'Email',
  favorite: 'Favorit',
  explain_login: 'Du har inget konto registrerat hos PDS. Kontakta din administratör för PDS eller skicka ett mejl till oss för att sätta upp det i din organisation.',
  filename: 'Filnamn',
  file_search_clear: 'Återställ',
  file_search_no_results: 'Inga resultat.',
  fill_content_control: 'Fyll i',
  intranet_page_title: 'Intranät',
  language: 'Språk',
  loading: 'Laddar...',
  login_error_retry_button: 'Ladda om sidan och försök igen',
  login_error_logout_button: 'Logga ut och försök igen',
  logout: 'Logga ut',
  mailto_subject: 'Jag vill beställa/sätta upp PDS i min organisation!',
  navigate_first_page: 'Första sidan',
  navigate_back_many: '-10',
  navigate_back: '-1',
  navigate_forward: '+1',
  navigate_forward_many: '+10',
  navigate_last_page: 'Sista sidan',
  no_options: 'Inga resultat',
  open: 'Öppna',
  oops_title: 'Hoppsan !',
  paste_person_data: 'Klistra in',
  people_dialog_title: 'Personer med roller',
  project_page_title: 'Projekt',
  reply: 'Svara',
  reply_all: 'Svara alla',
  save: 'Spara',
  save_as: 'Spara Som',
  save_dialog_title: 'Spara',
  save_attachments: 'Spara bilagor',
  send_and_file: 'Skicka och spara',
  session: 'Session',
  settings_dialog_title: 'Inställningar',
  show_favorites: 'Favoriter',
  show_recent: 'Senaste',
  templates_page_title: 'Mallhanteraren',
  templates_root_node: 'Mallar',
  tenant: 'Tenant',
  user_name: 'Namn',

  PROGRESS_TITLE_UPLOAD_FILE: 'Spara',
  PROGRESS_STATUS_READ_FILE: 'Läser fildata',
  PROGRESS_STATUS_WRITE_CUSTOM_XML: 'Skriver egenskaper till xml',
  PROGRESS_STATUS_UPLOAD_FILE: 'Laddar upp till SharePoint',
  PROGRESS_STATUS_OPEN_FILE: 'Öppnar filen från SharePoint. Var god vänta',

  PROGRESS_TITLE_SEND_AND_FILE: 'Skicka och spara',
  PROGRESS_STATUS_SAVE_DRAFT_EMAIL: 'Sparar utkast',
  PROGRESS_STATUS_FETCH_EMAIL_WITH_RETRIES: 'Hämtar utkast från server. Detta kan ta lite tid. Var god vänta',
  PROGRESS_STATUS_SEND_DRAFT_EMAIL: 'Skickar utkast',

  PROGRESS_TITLE_UPLOAD_ATTACHMENTS: 'Spara bilagor',
  PROGRESS_STATUS_READ_ATTACHMENTS: 'Läser in bilagor från Outlook',
  PROGRESS_STATUS_UPLOAD_ATTACHMENTS: 'Laddar upp bilaga %s till SharePoint',

  PROGRESS_TITLE_LOGOUT: 'Logga ut',
  PROGRESS_STATUS_LOGOUT: 'Loggar ut',

  PROGRESS_TITLE_ATTACH_FILE: 'Lägg till bilaga',
  PROGRESS_STATUS_READ_SHAREPOINT_FILE: 'Läser fildata från SharePoint',
  PROGRESS_STATUS_ATTACH_FILE: 'Lägger till bilaga',

  ERROR_TYPE_CATEGORY_GET: 'Kunde inte hämta intranätsmappar.',
  ERROR_TYPE_CATEGORY_NOT_FOUND: 'Intranätsmapp saknas.',
  ERROR_TYPE_CATEGORY_UPDATE: 'Kunde inte ändra intranätsmapp.',
  ERROR_TYPE_CATEGORY_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa intranätsmapp.',
  ERROR_TYPE_CATEGORY_NO_UPDATE_PERMISSION: 'Inte tillräcklig behörighet att ändra intranätsmapp.',

  ERROR_TYPE_CONTACT_GET: 'Kunde inte hämta kontakter.',
  ERROR_TYPE_CONTACT_NOT_FOUND: 'Kontakten saknas.',
  ERROR_TYPE_CONTACT_UPDATE: 'Kunde inte ändra kontakten.',
  ERROR_TYPE_CONTACT_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa kontakt.',
  ERROR_TYPE_CONTACT_NO_UPDATE_PERMISSION: 'Inte tillräcklig behörighet att ändra kontakt.',

  ERROR_TYPE_CONTENT_TYPE_GET: 'Kunde inte hämta innehållstyper.',
  ERROR_TYPE_CONTENT_TYPE_NOT_FOUND: 'Innehållstypen saknas.',
  ERROR_TYPE_CONTENT_TYPE_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa innehållstyper.',

  ERROR_TYPE_DEPARTMENT_GET: 'Kunde inte hämta intranätsdelar.',
  ERROR_TYPE_DEPARTMENT_NOT_FOUND: 'Intranätsdelen saknas.',
  ERROR_TYPE_DEPARTMENT_UPDATE: 'Kunde inte ändra intranätsdelen.',
  ERROR_TYPE_DEPARTMENT_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa intranätsdel.',
  ERROR_TYPE_DEPARTMENT_NO_UPDATE_PERMISSION: 'Inte tillräcklig behörighet att ändra intranätsdel.',

  ERROR_TYPE_DOC_LIB_GET: 'Kunde inte hämta filer från dokumentbibliotek.',

  ERROR_TYPE_EMAIL_READ: 'Kunde inte läsa mejl.',
  ERROR_TYPE_EMAIL_SAVE_DRAFT: 'Kunde inte spara mejlutkast.',
  ERROR_TYPE_EMAIL_SEND: 'Kunde inte skicka mejl.',

  ERROR_TYPE_FILE_ATTACH: 'Kunde inte lägga till bilaga.',
  ERROR_TYPE_FILE_READ: 'Kunde inte läsa fildata.',
  ERROR_TYPE_FILE_READ_ATTACHMENTS: 'Kunde inte läsa filbilagor.',
  ERROR_TYPE_FILE_SAVED_ADD: 'Kunde inte lägga till filevent.',
  ERROR_TYPE_FILE_SAVED_NO_ADD_PERMISSION: 'Inte tillräcklig behörighet att lägga till filevent.',
  ERROR_TYPE_FILE_SAVE: 'Kunde inte spara filen.',
  ERROR_TYPE_FILE_SEARCH: 'Kunde inte söka efter filer.',
  ERROR_TYPE_FILE_UPLOAD: 'Kunde inte ladda upp filen.',
  ERROR_TYPE_FILE_UPLOAD_ATTACHMENTS: 'Kunde inte ladda upp filbilagor.',
  ERROR_TYPE_FOLDER_CREATED_ADD: 'Kunde inte skapa upp mappen.',
  ERROR_TYPE_PROJECT_GET: 'Kunde inte hämta projekt.',
  ERROR_TYPE_PROJECT_NOT_FOUND: 'Projektet saknas.',
  ERROR_TYPE_PROJECT_UPDATE: 'Kunde inte ändra projektet.',
  ERROR_TYPE_PROJECT_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa projekt.',
  ERROR_TYPE_PROJECT_NO_UPDATE_PERMISSION: 'Inte tillräcklig behörighet att ändra projekt.',

  // Generic errors.

  ERROR_TYPE_AUTH: 'Autentiseringsfel. Prova att logga in igen.',
  ERROR_TYPE_LOGIN: 'Kunde inte logga in. Kontakta din administrator och se till att du har ett konto registrerat hos PDS.',
  ERROR_TYPE_NETWORK: 'Nätverksproblem. Se till att du har en fungerande internetuppkoppling.',
  ERROR_TYPE_UNKNOWN: 'Okänt problem. Var god försök igen om en stund.',

  // Content type fields
  pdscol_archived_date: 'Arkiveringsdatum',
  pdscol_contact_name: 'Kontakt',
  pdscol_document_class: 'Dokumenttyp',
  pdscol_document_date: 'Dokumentdatum',
  pdscol_document_information: 'Dokumentinformation',
  pdscol_document_status: 'Dokumentstatus',
  pdscol_entity: 'Område',
  pdscol_gdpr: 'GDPR',
  pdscol_object_type: 'Typ',
  pdscol_responsible: 'Ansvarig',
  pdscol_system_owner: 'Systemägare',
  pdscol_tags: 'Taggar',
  pdscol_contact_number: 'Kontaktnummer',
  pdscol_project_name: 'Projekt',
  pdscol_project_number: 'Projektnummer',
  pdscol_project_responsible: 'Projektansvarig',
  pdscol_department: 'Avdelning',
  pdscol_reference_id1: 'Avser referens till id 1',
  pdscol_reference_id2: 'Avser referens till id 2',
  pdscol_time_reference: 'Avser referens till tid',
  pdscol_email_receiver_address: 'Mottagare av epost',
  pdscol_email_send_address: 'Avsändare av epost',
  pdscol_email_sent_time: 'Tid för avsänt epost',
  pdscol_archived_time: 'Månader till arkivering',
  pdscol_template_group: 'Mallkategori',
  pdscol_template_information: 'Mallinformation'
}

const resources = {
  en_consultancy: en,
  en_lawfirm: {
    ...en,
    ERROR_TYPE_CONTACT_GET: 'Failed to get clients.',
    ERROR_TYPE_CONTACT_NOT_FOUND: 'Could not find client.',
    ERROR_TYPE_CONTACT_NO_GET_PERMISSION: 'Not enough permissions to read client.',
    ERROR_TYPE_CONTACT_NO_UPDATE_PERMISSION: 'Not enough permissions to update client.',
    ERROR_TYPE_CONTACT_UPDATE: 'Failed to update client.',
    ERROR_TYPE_PROJECT_GET: 'Failed to get matters.',
    ERROR_TYPE_PROJECT_NOT_FOUND: 'Could not find matter.',
    ERROR_TYPE_PROJECT_NO_GET_PERMISSION: 'Not enough permissions to read matter.',
    ERROR_TYPE_PROJECT_NO_UPDATE_PERMISSION: 'Not enough permissions to update matter.',
    ERROR_TYPE_PROJECT_UPDATE: 'Failed to update matter.',
    contact_page_title: 'Clients',
    pdscol_contact_name: 'Client',
    pdscol_contact_number: 'Client Number',
    pdscol_document_class: 'Document Class',
    pdscol_project_name: 'Matter',
    pdscol_project_number: 'Matter Number',
    pdscol_project_responsible: 'Matter Responsible',
    project_page_title: 'Matters'
  },
  es_consultancy: {
    attach: 'Adjuntar',
    cancel: 'Cancelar',
    change_sort: 'Cambiar ordenación',
    clear: 'Limpiar',
    close: 'Cerrar',
    contact_page_title: 'Contactos',
    contact_person_title: 'Personas de contacto',
    create_folder: 'Crear carpeta',
    document_library: 'Documentos',
    email: 'Email',
    favorite: 'Favorito',
    explain_login: 'No tiene una cuenta con PDS. Contacte con su administrador o envíenos un email para que lo configuremos para su organización',
    filename: 'Nombre de archivo',
    file_search_clear: 'Limpiar búsqueda',
    file_search_no_results: 'No hay resultados.',
    fill_content_control: 'Rellenar',
    intranet_page_title: 'Intranet',
    language: 'Idioma',
    loading: 'Cargando...',
    login_error_retry_button: 'Recargar la página e intentar de nuevo',
    login_error_logout_button: 'Cerrar sesión e intentar de nuevo',
    logout: 'Cerrar sesión',
    mailto_subject: '¡Me gustaría contratar / configurar PDS para mi organización!',
    navigate_first_page: 'Primera página',
    navigate_back_many: '-10',
    navigate_back: '-1',
    navigate_forward: '+1',
    navigate_forward_many: '+10',
    navigate_last_page: 'Última página',
    no_options: 'No hay opciones',
    open: 'Abrir',
    oops_title: '¡Oops!',
    paste_person_data: 'Pegar',
    people_dialog_title: 'Usuarios con roles',
    project_page_title: 'Proyectos',
    reply: 'Contestar',
    reply_all: 'Contestar a todos',
    save: 'Guardar',
    save_as: 'Cuardar como',
    save_dialog_title: 'Guardar',
    save_attachments: 'Guardar adjuntos',
    send_and_file: 'Enviar y archivar',
    session: 'Sesión',
    settings_dialog_title: 'Configuración',
    show_favorites: 'Favoritos',
    show_recent: 'Recientes',
    templates_page_title: 'Plantillas',
    templates_root_node: 'Plantillas',
    tenant: 'Instancia',
    user_name: 'Nombre',

    PROGRESS_TITLE_UPLOAD_FILE: 'Guardar',
    PROGRESS_STATUS_READ_FILE: 'Leyendo datos del archivo',
    PROGRESS_STATUS_WRITE_CUSTOM_XML: 'Generando XML personalizado',
    PROGRESS_STATUS_UPLOAD_FILE: 'Subiendo datos a SharePoint',
    PROGRESS_STATUS_OPEN_FILE: 'Abriendo archivo en SharePoint. Por favor espere',

    PROGRESS_TITLE_SEND_AND_FILE: 'Enviar y archivar',
    PROGRESS_STATUS_SAVE_DRAFT_EMAIL: 'Guardando borrador',
    PROGRESS_STATUS_FETCH_EMAIL_WITH_RETRIES: 'Obteniendo borradores desde el servidor. Esta operación puede tardar un poco, por favor espere',
    PROGRESS_STATUS_SEND_DRAFT_EMAIL: 'Enviando borrador',

    PROGRESS_TITLE_UPLOAD_ATTACHMENTS: 'Guardar archivos adjuntos',
    PROGRESS_STATUS_READ_ATTACHMENTS: 'Leyendo datos de archivos adjuntos desde Outlook',
    PROGRESS_STATUS_UPLOAD_ATTACHMENTS: 'Subiendo archivo adjunto %s a SharePoint',

    PROGRESS_TITLE_LOGOUT: 'Cerrar sesión',
    PROGRESS_STATUS_LOGOUT: 'Cerrando sesión',

    PROGRESS_TITLE_ATTACH_FILE: 'Añadir adjunto',
    PROGRESS_STATUS_READ_SHAREPOINT_FILE: 'Leyendo archivo desde SharePoint',
    PROGRESS_STATUS_ATTACH_FILE: 'Adjuntando archivo al correo',

    // Error messages

    ERROR_TYPE_CATEGORY_GET: 'Error al obtener las carpetas de la intranet.',
    ERROR_TYPE_CATEGORY_NOT_FOUND: 'No se pudo encontrar la carpeta de la intranet.',
    ERROR_TYPE_CATEGORY_UPDATE: 'Error al actualizar la carpeta de la intranet.',
    ERROR_TYPE_CATEGORY_NO_GET_PERMISSION: 'No tiene permisos para acceder a la carpeta de la intranet.',
    ERROR_TYPE_CATEGORY_NO_UPDATE_PERMISSION: 'No tiene permisos para actualizar la carpeta de la intranet.',

    ERROR_TYPE_CONTACT_GET: 'Error al obtener los contactos.',
    ERROR_TYPE_CONTACT_NOT_FOUND: 'No se pudo encontrar el contacto.',
    ERROR_TYPE_CONTACT_UPDATE: 'Error al actualizar el contacto.',
    ERROR_TYPE_CONTACT_NO_GET_PERMISSION: 'No tiene permisos para acceder al contacto.',
    ERROR_TYPE_CONTACT_NO_UPDATE_PERMISSION: 'No tiene permisos para actualizar el contacto.',

    ERROR_TYPE_CONTENT_TYPE_GET: 'Error al obtener los tipos de contenido.',
    ERROR_TYPE_CONTENT_TYPE_NOT_FOUND: 'No se pudo encontrar el tipo de contenido.',
    ERROR_TYPE_CONTENT_TYPE_NO_GET_PERMISSION: 'No tiene permisos para obtener los tipos de contenido.',

    ERROR_TYPE_DEPARTMENT_GET: 'Error al obtener las secciones de la intranet.',
    ERROR_TYPE_DEPARTMENT_NOT_FOUND: 'No se pudo encontrar la sección de la intranet.',
    ERROR_TYPE_DEPARTMENT_UPDATE: 'Error al actualizar las secciones de la intranet.',
    ERROR_TYPE_DEPARTMENT_NO_GET_PERMISSION: 'No tiene permisos para leer las secciones de la intranet.',
    ERROR_TYPE_DEPARTMENT_NO_UPDATE_PERMISSION: 'No tiene permisos para actualizar la sección de la intranet.',

    ERROR_TYPE_DOC_LIB_GET: 'Error al obtener archivos desde la galería de documentos.',

    ERROR_TYPE_EMAIL_READ: 'Error al obtener email.',
    ERROR_TYPE_EMAIL_SAVE_DRAFT: 'Error al guardar borrador de email.',
    ERROR_TYPE_EMAIL_SEND: 'Error al enviar email.',

    ERROR_TYPE_FILE_ATTACH: 'Error al adjuntar el archivo.',
    ERROR_TYPE_FILE_READ: 'Error al leer el archivo.',
    ERROR_TYPE_FILE_READ_ATTACHMENTS: 'Error al leer los archivos adjuntos.',
    ERROR_TYPE_FILE_SAVED_ADD: 'Error al añadir el evento de archivo guardado.',
    ERROR_TYPE_FILE_SAVED_NO_ADD_PERMISSION: 'No tiene permisos para añadir el evento de archivo guardado.',
    ERROR_TYPE_FILE_SAVE: 'Error al guardar archivo.',
    ERROR_TYPE_FILE_SEARCH: 'Error buscando archivos.',
    ERROR_TYPE_FILE_UPLOAD: 'Error subiendo archivo.',
    ERROR_TYPE_FILE_UPLOAD_ATTACHMENTS: 'Error subiendo los archivos adjuntos.',
    ERROR_TYPE_FOLDER_CREATED_ADD: 'No se pudo crear la carpeta',
    ERROR_TYPE_PROJECT_GET: 'Error al obtener los proyectos.',
    ERROR_TYPE_PROJECT_NOT_FOUND: 'No se pudo encontrar el proyecto.',
    ERROR_TYPE_PROJECT_UPDATE: 'Error al actualizar el proyecto.',
    ERROR_TYPE_PROJECT_NO_GET_PERMISSION: 'No tiene permisos para leer el proyecto.',
    ERROR_TYPE_PROJECT_NO_UPDATE_PERMISSION: 'No tiene permisos para actualizar el proyecto.',

    // Generic errors.

    ERROR_TYPE_AUTH: 'Error de autenticación. Intente acceder de nuevo.',
    ERROR_TYPE_LOGIN: 'Error al iniciar sesión. Contacte con su administrador y asegúrese de que tiene una cuenta registrada con PDS.',
    ERROR_TYPE_NETWORK: 'No se puede conectar. Asegúrese de que dispone de conexión a Internet.',
    ERROR_TYPE_UNKNOWN: 'Error no especificado. Por favor vuelva a intentarlo de nuevo más tarde.',

    // Content type fields
    pdscol_archived_date: 'Fecha de archivado',
    pdscol_contact_name: 'Contacto',
    pdscol_document_class: 'Tipo de documento',
    pdscol_document_date: 'Fecha del documento',
    pdscol_document_information: 'Información del documento',
    pdscol_document_status: 'Estado del documento',
    pdscol_entity: 'Área',
    pdscol_gdpr: 'GDPR',
    pdscol_object_type: 'Tipo',
    pdscol_responsible: 'Responsable',
    pdscol_system_owner: 'Propietario del sistema',
    pdscol_tags: 'Etiquetas',
    pdscol_contact_number: 'Número del contacto',
    pdscol_project_name: 'Proyecto',
    pdscol_project_number: 'Número del proyecto',
    pdscol_project_responsible: 'Responsable del proyecto',
    pdscol_department: 'Departamento',
    pdscol_reference_id1: 'Id. de referencia 1',
    pdscol_reference_id2: 'Id. de referencia 2',
    pdscol_time_reference: 'Fecha de referencia',
    pdscol_email_receiver_address: 'Receptor del email',
    pdscol_email_send_address: 'Emisor del email',
    pdscol_email_sent_time: 'Fecha de envío del email',
    pdscol_archived_time: 'Meses hasta el archivado',
    pdscol_template_group: 'Grupo de la plantilla',
    pdscol_template_information: 'Información de la plantilla'
  },
  sv_consultancy: sv,
  sv_lawfirm: {
    ...sv,
    ERROR_TYPE_CONTACT_GET: 'Kunde inte hämta klienter.',
    ERROR_TYPE_CONTACT_NOT_FOUND: 'Klienten saknas.',
    ERROR_TYPE_CONTACT_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa klient.',
    ERROR_TYPE_CONTACT_NO_UPDATE_PERMISSION: 'Inte tillräcklig behörighet att ändra klient.',
    ERROR_TYPE_CONTACT_UPDATE: 'Kunde inte ändra klienten.',
    ERROR_TYPE_PROJECT_GET: 'Kunde inte hämta ärenden.',
    ERROR_TYPE_PROJECT_NOT_FOUND: 'Ärendet saknas.',
    ERROR_TYPE_PROJECT_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa ärende.',
    ERROR_TYPE_PROJECT_NO_UPDATE_PERMISSION: 'Inte tillräcklig behörighet att ändra ärende.',
    ERROR_TYPE_PROJECT_UPDATE: 'Kunde inte ändra ärendet.',
    contact_page_title: 'Klienter',
    pdscol_contact_name: 'Klient',
    pdscol_contact_number: 'Klientnummer',
    pdscol_document_class: 'Dokumentklass',
    pdscol_project_name: 'Ärende',
    pdscol_project_number: 'Ärendenummer',
    pdscol_project_responsible: 'Ärendeansvarig',
    project_page_title: 'Ärenden'
  }
}

export default resources
