const convert = require('xml-js')
const clone = require('clone-deep')

/**
 * This file uses require and module.exports for testability with tape
 */

const XML_DEFAULT_DATA = '<?xml version="1.0"?><p:properties xmlns:p="http://schemas.microsoft.com/office/2006/metadata/properties" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:pc="http://schemas.microsoft.com/office/infopath/2007/PartnerControls"><documentManagement></documentManagement></p:properties>'

const XML_EMPTY_DATA = '<?xml version="1.0" encoding="utf-8"?><p:properties xmlns:p="http://schemas.microsoft.com/office/2006/metadata/properties" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:pc="http://schemas.microsoft.com/office/infopath/2007/PartnerControls"><documentManagement></documentManagement></p:properties>'
const XML_EMPTY_SCHEMA = '<?xml version="1.0" encoding="utf-8"?><ct:contentTypeSchema xmlns:ct="http://schemas.microsoft.com/office/2006/metadata/contentType" xmlns:ma="http://schemas.microsoft.com/office/2006/metadata/properties/metaAttributes"></ct:contentTypeSchema>'
const XML_EMPTY_DOCPROPS = '<?xml version="1.0" encoding="UTF-8" standalone="yes"?><Properties xmlns="http://schemas.openxmlformats.org/officeDocument/2006/custom-properties" xmlns:vt="http://schemas.openxmlformats.org/officeDocument/2006/docPropsVTypes"></Properties>'

/**
 * Returns true if json element is a nil element
 */
function hasNilAttribute (element) {
  // TODO export and test
  return element.attributes['xsi:nil'] === 'true'
}

/**
 * Empty arrays are considered having no values
 * Falsey strings are considered nil
 */
function isNilValue (value) {
  // TODO export and test
  if (Array.isArray(value)) {
    return value.length === 0
  } else {
    return !value
  }
}

function elementsArrayFromValue (value) {
  if (Array.isArray(value)) {
    return value.map(v => {
      return {
        type: 'element',
        name: 'Value',
        elements: [
          {
            type: 'text',
            text: v
          }
        ]
      }
    })
  } else {
    return [
      {
        type: 'text',
        text: value
      }
    ]
  }
}

function setCustomXmlProperty (state, name, value) {
  // 2 apply value from property
  const stateCopy = clone(state)
  const pProperties = stateCopy.elements[0]
  const documentManagement = pProperties.elements[0]
  const elements = documentManagement.elements

  for (let i = 0; i < elements.length; ++i) {
    const element = elements[i]
    if (element.name === name) {
      const isNil = hasNilAttribute(element)
      const nilValue = isNilValue(value)

      if (isNil && nilValue) {
        // Element is nil and setting nil -> Do nothing
      } else if (isNil && !nilValue) {
        // Element is nil and setting non nil value
        const newElement = clone(element)
        delete newElement.attributes['xsi:nil']
        newElement.elements = elementsArrayFromValue(value)
        elements[i] = newElement
      } else if (!isNil && nilValue) {
        // Element is not nil and setting nil
        const newElement = clone(element)
        newElement.attributes['xsi:nil'] = 'true'
        newElement.elements = []
        elements[i] = newElement
      } else if (!isNil && !nilValue) {
        // Element is not nil and setting non nil value
        const newElement = clone(element)
        newElement.elements = elementsArrayFromValue(value)
        elements[i] = newElement
      }

      break
    }
  }

  return stateCopy
}

/**
 * Convert xml to json using xml-js
 */
function xmlToJs (xml) {
  return convert.xml2js(xml, {
    compact: false,
    alwaysChildren: true
  })
}

/**
 * Convert xml to js but return custom xml as properties in
 * a single object. For reading out custom xml properties and
 * fitting that state into redux.
 */
function xmlToObject (xml) {
  const result = {}
  const js = xmlToJs(xml)

  const pProperties = js.elements[0]
  const documentManagement = pProperties.elements[0]
  const topElements = documentManagement.elements || []

  topElements.forEach((topElement) => {
    const { name, elements } = topElement
    if (hasNilAttribute(topElement)) {
      result[name] = null
    } else if (Array.isArray(elements)) {
      if (elements.length === 0) {
        // It may be the case that elements array is empty, but no
        // nil attribute was set.
        result[name] = null
      } else if (elements.length === 1 && elements[0].type === 'text') {
        // Text node
        result[name] = elements[0].text
      } else if (elements.length > 1) {
        // Array node, e.g. pdsTags with multiple values
        result[name] = elements.map((el) => {
          if (el.type === 'element' && el.name === 'Value') {
            return el.elements[0].text
          } else {
            return null
          }
        }).filter(Boolean)
      }
    }
  })

  return result
}

/**
 * Convert js to xml.
 */
function jsToXml (js) {
  return convert.js2xml(js)
}

module.exports = {
  XML_DEFAULT_DATA,
  XML_EMPTY_DATA,
  XML_EMPTY_SCHEMA,
  XML_EMPTY_DOCPROPS,
  setCustomXmlProperty,
  xmlToJs,
  xmlToObject,
  jsToXml
}
