export const INCREASE_BUSY_ACTION = 'INCREASE_BUSY_ACTION'
export const DECREASE_BUSY_ACTION = 'DECREASE_BUSY_ACTION'

export function busy () {
  return {
    type: INCREASE_BUSY_ACTION
  }
}

export function notBusy () {
  return {
    type: DECREASE_BUSY_ACTION
  }
}
