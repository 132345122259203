import { io } from 'socket.io-client'

import {
  contactUpdateAction,
  contactDeleteAction
} from './contact/actions.js'
import {
  projectUpdateAction,
  projectDeleteAction
} from './project/actions.js'
import {
  departmentUpdateAction,
  departmentDeleteAction
} from './department/actions.js'
import {
  categoryUpdateAction,
  categoryDeleteAction
} from './category/actions.js'

class SocketIOClient {
  constructor () {
    this.socket = undefined
  }

  connect (token, dispatch) {
    if (this.socket) {
      console.info('socket.io already connected, will re-connect on new socket')
      const socket = this.socket
      this.socket = undefined
      socket.disconnect()
      setTimeout(() => this.connect(token, dispatch), 500)
    } else {
      const socket = this.socket = io(process.env.REACT_APP_WS_URL, {
        path: '/ws',
        extraHeaders: {
          authorization: `Bearer ${token}`,
          'x-pds-app': 'pds-fm'
        }
      })

      const messageHandler = createMessageHandler(dispatch)
      socket.on('connect', (reason) => {
        console.info('socket.io connected, registering message handler')
        socket.removeAllListeners('message')
        socket.on('message', messageHandler)
      })
    }
  }
}

function createMessageHandler (dispatch) {
  function messageHandler (message) {
    console.info(`socket.io message '${message.type}'`, message.data)
    switch (message.type) {
      case 'contact/update':
      case 'contact/favorite':
      case 'event/error/contact.sharepoint.create':
      case 'event/error/contact.sharepoint.update':
        dispatch(contactUpdateAction(message.data))
        break
      case 'contact/delete':
        dispatch(contactDeleteAction(message.data))
        break

      case 'project/update':
      case 'project/favorite':
      case 'event/error/project.sharepoint.create':
      case 'event/error/project.sharepoint.update':
        dispatch(projectUpdateAction(message.data))
        break
      case 'project/delete':
        dispatch(projectDeleteAction(message.data))
        break

      case 'department/update':
      case 'department/favorite':
      case 'event/error/department.sharepoint.create':
      case 'event/error/department.sharepoint.update':
        dispatch(departmentUpdateAction(message.data))
        break
      case 'department/delete':
        dispatch(departmentDeleteAction(message.data))
        break

      case 'category/update':
      case 'category/favorite':
      case 'event/error/category.sharepoint.create':
      case 'event/error/category.sharepoint.update':
        dispatch(categoryUpdateAction(message.data))
        break
      case 'category/delete':
        dispatch(categoryDeleteAction(message.data))
        break

      default:
        console.info(`unhandled websocket message ${message.type}`, message.data)
    }
  }

  return messageHandler
}

export default new SocketIOClient()
