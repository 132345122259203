export const RESET_SELECTED_ITEM_ACTION = 'RESET_SELECTED_ITEM_ACTION'
export const SELECT_ITEM_ACTION = 'SELECT_ITEM_ACTION'

export function setSelectedItem (item) {
  return {
    type: SELECT_ITEM_ACTION,
    item
  }
}

export function resetSelectedItem () {
  return {
    type: RESET_SELECTED_ITEM_ACTION
  }
}
