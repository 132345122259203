import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import pick from 'lodash.pick'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core'
import { Close, Save } from '@material-ui/icons'
import TextField from '../components/TextField.js'
import redux from '../redux/index.js'
import {
  emailFileNameOutlookReadMode,
  isOffice,
  isOutlook,
  deleteDocumentProperty
} from '../lib/office.js'
import {
  CONTENT_BACKGROUND,
  FOOTER_BACKGROUND,
  FOOTER_HEIGHT,
  HEADER_BACKGROUND,
  HEADER_HEIGHT
} from '../styles.js'

import Choice from './fields/Choice.js'
import ContactChoice from './fields/ContactChoice.js'
import MultiChoice from './fields/MultiChoice.js'
import Note from './fields/Note.js'
import Text from './fields/Text.js'

const getFieldComponent = (ct) => {
  const type = ct.ui_type || ct.field_type_as_string
  switch (type) {
    case 'Choice':
      return Choice
    case 'ContactChoice':
      return ContactChoice
    case 'MultiChoice':
      return MultiChoice
    case 'Note':
      return Note
    case 'Text':
      return Text
    default:
      console.log('SaveDialog: unknown component for type', type)
      return null
  }
}

const SaveDialog = ({
  strings,
  selectedSiteItem,
  selectedFileNode,
  fileName,
  contentTypes,
  properties,
  onHideSaveDialog,
  onSetFileName,
  onSaveFile,
  getData
}) => {
  useEffect(() => getData(selectedSiteItem?.type), [])

  const isSaveDisabled = () => {
    if (fileName.length === 0) return true
    if (isOffice()) {
      return contentTypes
        .filter(ct => ct.ui_required)
        .some(ct => {
          const key = ct.field_internal_name
          return !properties[key]
        })
    } else {
      return false
    }
  }

  const renderFields = () => {
    if (isOffice()) {
      return contentTypes.map(ct => {
        const Field = getFieldComponent(ct)
        return Field ? <Field style={{ marginBottom: '10px' }} key={ct.field_id} contentType={ct} /> : null
      })
    } else {
      return null
    }
  }

  const disableSave = isSaveDisabled()

  return (
    <Dialog fullScreen open>
      <DialogTitle style={titleStyle}>
        <div style={titleContentStyle}>
          {strings.save_dialog_title}
        </div>
      </DialogTitle>
      <DialogContent style={contentStyle}>
        <TextField
          required
          label={strings.filename}
          value={fileName}
          style={{ background: 'white', marginBottom: '10px' }}
          onChange={(event) => onSetFileName(event.target.value)}
        />
        {renderFields()}
      </DialogContent>
      <DialogActions style={actionsStyle}>
        <Button
          disabled={disableSave}
          onClick={() => onSaveFile(contentTypes, properties, selectedSiteItem, selectedFileNode, fileName)}
          style={actionButtonStyle(disableSave)}
        >
          <Save style={actionIconStyle} />{strings.save}
        </Button>
        <Button
          onClick={onHideSaveDialog}
          style={actionButtonStyle()}
        >
          <Close style={actionIconStyle} />{strings.cancel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const titleStyle = {
  background: HEADER_BACKGROUND,
  color: 'white',
  padding: '0px 19px',
  fontSize: '20px',
  height: HEADER_HEIGHT
}

const titleContentStyle = {
  marginTop: '8px'
}

const contentStyle = {
  background: CONTENT_BACKGROUND
}

const actionsStyle = {
  borderTop: '1px solid white',
  background: FOOTER_BACKGROUND,
  height: FOOTER_HEIGHT,
  padding: 0
}

export const actionButtonStyle = (disabled = false) => {
  return {
    color: disabled ? '#aaafb2' : 'white',
    textTransform: 'none',
    marginRight: '10px'
  }
}

export const actionIconStyle = {
  marginRight: '5px'
}

const mapStateToProps = (state) => {
  return {
    strings: state.locale.strings,
    selectedSiteItem: state.selected.current,
    selectedFileNode: state.files.selectedFileNode,
    fileName: state.save.fileName,
    contentTypes: state.contentType.items,
    properties: state.save.documentProperties
  }
}

const mapDispatchToProps = (dispatch) => {
  const { contentType, dialog, files, save } = redux.actions
  return {
    getData: (uiContext) => {
      dispatch(contentType.getContentTypes(uiContext))
      if (isOutlook()) {
        dispatch(save.setSaveDialogFileName(emailFileNameOutlookReadMode()))
      }
    },
    onHideSaveDialog: () => {
      dispatch(dialog.hideDialog())
    },
    onSaveFile: async (contentTypes, properties, siteItem, fileItem, fileName) => {
      const filteredNames = contentTypes.map(ct => ct.field_internal_name)
      const xmlProperties = pick(properties, filteredNames)
      // If document property exists, remove it before trying to save.
      if (isOffice()) {
        try {
          await deleteDocumentProperty('pds_id')
          await deleteDocumentProperty('pds_type')
        } catch (e) {
          console.warn('failed to remove document property', e)
        }
      }

      dispatch(save.uploadFile(siteItem, fileItem, fileName, xmlProperties, () => {
        const { type } = fileItem
        if (type === 'rootfolder') {
          dispatch(files.getRootFolder(siteItem))
        } else if (type === 'folder') {
          dispatch(files.getSubFolder(siteItem, fileItem))
        }
      }))

      dispatch(dialog.hideDialog())
    },
    onSetFileName: (fileName) => {
      dispatch(save.setSaveDialogFileName(fileName))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveDialog)
