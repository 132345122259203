const config = Object.freeze({
  api: {
    baseUrl: process.env.REACT_APP_API_URL
  },
  msal: {
    auth: {
      clientId: '0e23c66a-ca4a-4952-9057-d83de57ce902'
    },
    cache: {
      cacheLocation: 'sessionStorage'
    },
    system: {
      allowRedirectInIframe: true
    }
  },
  outlook: {
    readRetryCount: 30,
    readRetryTimeout: 2000
  },
  autoLoginTimeout: 200,
  errorTimeout: 7000,
  waitForSaveTimeout: 5000
})

export default config
