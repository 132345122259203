import storage from '../storage'
export const CHANGE_LOCALE_ACTION = 'CHANGE_LOCALE_ACTION'

export function changeLocale (locale) {
  storage.putLocale(locale)
  return {
    type: CHANGE_LOCALE_ACTION,
    locale
  }
}
