// TODO add translations for error types
export default Object.freeze({
  category: {
    get: 'ERROR_TYPE_CATEGORY_GET',
    update: 'ERROR_TYPE_CATEGORY_UPDATE',
    notFound: 'ERROR_TYPE_CATEGORY_NOT_FOUND',
    noGetPermission: 'ERROR_TYPE_CATEGORY_NO_GET_PERMISSION',
    noUpdatePermission: 'ERROR_TYPE_CATEGORY_NO_UPDATE_PERMISSION'
  },
  contact: {
    get: 'ERROR_TYPE_CONTACT_GET',
    update: 'ERROR_TYPE_CONTACT_UPDATE',
    notFound: 'ERROR_TYPE_CONTACT_NOT_FOUND',
    noGetPermission: 'ERROR_TYPE_CONTACT_NO_GET_PERMISSION',
    noUpdatePermission: 'ERROR_TYPE_CONTACT_NO_UPDATE_PERMISSION'
  },
  contentType: {
    get: 'ERROR_TYPE_CONTENT_TYPE_GET',
    notFound: 'ERROR_TYPE_CONTENT_TYPE_NOT_FOUND',
    noGetPermission: 'ERROR_TYPE_CONTENT_TYPE_NO_GET_PERMISSION'
  },
  department: {
    get: 'ERROR_TYPE_DEPARTMENT_GET',
    update: 'ERROR_TYPE_DEPARTMENT_UPDATE',
    notFound: 'ERROR_TYPE_DEPARTMENT_NOT_FOUND',
    noGetPermission: 'ERROR_TYPE_DEPARTMENT_NO_GET_PERMISSION',
    noUpdatePermission: 'ERROR_TYPE_DEPARTMENT_NO_UPDATE_PERMISSION'
  },
  fileSaved: {
    add: 'ERROR_TYPE_FILE_SAVED_ADD',
    noAddPermission: 'ERROR_TYPE_FILE_SAVED_NO_ADD_PERMISSION'
  },
  project: {
    get: 'ERROR_TYPE_PROJECT_GET',
    update: 'ERROR_TYPE_PROJECT_UPDATE',
    notFound: 'ERROR_TYPE_PROJECT_NOT_FOUND',
    noGetPermission: 'ERROR_TYPE_PROJECT_NO_GET_PERMISSION',
    noUpdatePermission: 'ERROR_TYPE_PROJECT_NO_UPDATE_PERMISSION'
  }
})
