import store from '../store.js'
import api from '../api.js'
import msal, { getSpAccessToken, getGraphAccessToken } from '../../lib/msal.js'
import storage from '../storage.js'
import { isOutlook } from '../../lib/office.js'
import {
  ERROR_ACTION,
  resetError,
  unknownError,
  networkError
} from '../error/actions.js'
import { busy, notBusy } from '../busy/actions.js'
import { changeLocale } from '../locale/actions.js'
import { hideDialog } from '../dialog/actions.js'
import { beginProgressDialog } from '../progress/actions.js'
import { isValidToken } from '../../util.js'
import resources from '../../resources'

export const LOGIN_SUCCESS_ACTION = 'LOGIN_SUCCESS_ACTION'
export const ERROR_TYPE_LOGIN = 'ERROR_TYPE_LOGIN'

/**
 * Different messages during failed login.
 */
export const ERROR_LOGIN_NO_ACCOUNT = 'ERROR_LOGIN_NO_ACCOUNT'
export const ERROR_LOGIN_ACCESS_TOKEN = 'ERROR_LOGIN_ACCESS_TOKEN'

let heartBeatInterval = null

/**
 * Heart beat.
 */
export function doStartHeartBeat () {
  return function (dispatch) {
    if (heartBeatInterval) {
      clearInterval(heartBeatInterval)
      heartBeatInterval = null
    }

    heartBeatInterval = setInterval(() => {
      const { userData } = store.getState().login
      const { token } = userData
      if (!isValidToken(token)) {
        storage.deleteApiToken()
        window.location.reload()
      }
    }, 60 * 1000)
  }
}

/**
 * Pre request hook that sets api token for all requests
 */
api.setPreHook(async (req) => {
  const ud = store.getState().login.userData
  api.setHeader('Authorization', `Bearer ${ud.token}`)
})

/**
 * Get an api token from pds using idToken
 */
export function doLogin (idToken) {
  return function (dispatch) {
    dispatch(resetError())

    /**
     * Fetch a new api token.
     */
    function apiTokenStep (idToken) {
      dispatch(busy())
      api.login(idToken).then(async (result) => {
        dispatch(notBusy())
        const userData = result.data.value
        const tenantSettings = userData?.tenant_settings || {}

        const sharePointSaveEnabled = tenantSettings.sharepoint_save_enabled || false
        userData.sharePointSaveEnabled = sharePointSaveEnabled
        const sharePointTemplatesEnabled = tenantSettings.sharepoint_templates_enabled || false
        userData.sharePointTemplatesEnabled = sharePointTemplatesEnabled
        userData.idToken = idToken

        const { locale, branch } = tenantSettings
        if (typeof locale === 'string' && typeof branch === 'string') {
          const localLocale = `${locale.split('-')[0]}_${branch}`
          if (resources[localLocale] && !storage.getLocale()) {
            console.info(`setting ${localLocale} locale from backend`)
            dispatch(changeLocale(localLocale))
          }
        }

        if (sharePointSaveEnabled || sharePointTemplatesEnabled) {
          setTimeout(async () => {
            console.info('warming up sp token cache')
            await getSpAccessToken(userData)
          }, 10)
        }

        if (sharePointSaveEnabled && isOutlook()) {
          setTimeout(async () => {
            console.info('warming up graph token cache')
            await getGraphAccessToken(userData)
          }, 10)
        }

        dispatch(loginSuccessful(userData))
      }).catch(err => {
        console.error('api.login() failed', err)
        dispatch(notBusy())
        const { response } = err
        if (response) {
          if (response.status === 401) {
            dispatch(loginError(ERROR_LOGIN_NO_ACCOUNT))
          } else {
            dispatch(unknownError(err))
          }
        } else {
          dispatch(networkError(err))
        }
      })
    }

    apiTokenStep(idToken)
  }
}

function loginSuccessful (userData) {
  console.info('login successful!')
  setTimeout(() => {
    storage.putUserData(userData)
  }, 50)
  return {
    type: LOGIN_SUCCESS_ACTION,
    userData
  }
}

function loginError (message) {
  return {
    type: ERROR_ACTION,
    errorType: ERROR_TYPE_LOGIN,
    errorMessage: message
  }
}

/**
 * Logs out the current user from Microsoft and clears cached data.
 */
export function doLogout () {
  return function (dispatch) {
    const { email } = store.getState().login.userData
    const account = msal.getAccountByUsername(email)
    dispatch(hideDialog())
    dispatch(
      beginProgressDialog(
        'PROGRESS_TITLE_LOGOUT',
        'PROGRESS_STATUS_LOGOUT'
      )
    )
    storage.deleteUserData()
    msal.logout({ account })
  }
}
