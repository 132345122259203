import storage from '../storage'
import { SET_CURRENT_PAGE_ACTION } from './actions'

/**
 * Reducer for current selected page.
 */
export function page () {
  return function (state = storage.getPage(), action) { // eslint-disable-line
    switch (action.type) {
      case SET_CURRENT_PAGE_ACTION:
        return action.page
      default:
        return state
    }
  }
}
