import msal from './lib/msal.js'
import redux from './redux'
import React from 'react'
import ReactDOM from 'react-dom'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import { Provider } from 'react-redux'
import './index.css'
import App from './App.js'
import { onReady, printDiagnostics } from './lib/office.js'
import storage from './redux/storage.js'
import { isValidToken } from './util.js'

// https://material-ui.com/customization/themes
const theme = createTheme({
  tooltip: {
    color: '#eeffff',
    backgroundColor: '#d57b00',
    fontSize: '16px',
    fontWeight: 'lighter',
    padding: '5px 15px 5px 15px',
    maxWidth: '600px',
    borderRadius: '3px',
    userSelect: 'text'
  },
  // https://v4.mui.com/customization/globals/#css
  overrides: {
    MuiSvgIcon: {
      root: {
        // Need smaller icons for toolbar
        fontSize: '1.3rem'
      }
    }
  }
})

function onValidToken ({ idToken = '', token = '' }) {
  onReady(() => {
    printDiagnostics()
    ReactDOM.render(
      <React.StrictMode>
        <Provider store={redux.store}>
          <MuiThemeProvider theme={theme}>
            <App idToken={idToken} token={token} />
          </MuiThemeProvider>
        </Provider>
      </React.StrictMode>,
      document.getElementById('root')
    )
  })
}

function redirectLogin () {
  msal.handleRedirectPromise().then((tokenResponse) => {
    if (tokenResponse !== null) {
      console.info('got id token from redirect method')
      onValidToken({ idToken: tokenResponse.idToken })
    } else {
      try {
        console.info('initiating redirect method')
        msal.loginRedirect()
      } catch (err) {
        // TODO this is a fatal error -> rende Error page with details
        console.error('msal.loginRedirect failed')
      }
    }
  }).catch((err) => {
    // TODO this is a fatal error -> rende Error page with details
    console.error('msal.handleRedirectPromise failed', err)
  })
}

const { token, email } = redux.store.getState().login.userData

if (isValidToken(token)) {
  console.info('found cached valid token')
  onValidToken({ token })
} else {
  const loginHint = email
  if (typeof loginHint === 'string') {
    console.info('msal.ssoSilent() with login hint', loginHint)
    const silentRequest = { loginHint }
    msal.ssoSilent(silentRequest).then(result => {
      onValidToken({ idToken: result.idToken })
    }).catch((err) => {
      console.error('msal.ssoSilent failed', err)
      storage.deleteUserData()
      redirectLogin()
    })
  } else {
    redirectLogin()
  }
}
