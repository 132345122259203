import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { isValidToken } from './util.js'
import config from './config.js'
import redux from './redux/index.js'
import socket from './redux/socket-io.js'
import WorkSpace from './WorkSpace.js'
import PdsSpinner from './components/PdsSpinner.js'
import NoAccount from './components/NoAccount.js'
import Feedback from './components/Feedback.js'

/**
 * We're logged in if the api token is valid. If not, we try to login
 * using the idToken.
 */
const App = ({
  error,
  strings,
  idToken,
  token,
  sharePointSaveEnabled,
  sharePointTemplatesEnabled,
  showNoAccountPage,
  connectWebsockets,
  onResetError,
  doLogin,
  doStartHeartBeat
}) => {
  const isLoggedIn = isValidToken(token)

  const renderError = () => {
    const errorMessage = () => {
      if (error) {
        console.error(error)
        const { errorType, errorMessage } = error
        const translated = strings[errorType] || 'N/A'
        if (errorMessage) {
          return `${translated} (${errorMessage})`
        } else {
          return translated
        }
      } else {
        return ''
      }
    }
    return (
      <Feedback
        open={!!error}
        message={errorMessage()}
        variant='error'
        closeTimeout={config.errorTimeout}
        onAutoClose={onResetError}
      />
    )
  }

  // Start heartbeat on initial render.
  useEffect(doStartHeartBeat, [])

  // (Re-)Connect to websockets if token changed.
  useEffect(() => {
    if (isLoggedIn) {
      connectWebsockets(token)
    }
  }, [token])

  // Auto login if not logged in.
  useEffect(() => {
    if (!isLoggedIn) {
      setTimeout(() => doLogin(idToken), config.autoLoginTimeout)
    }
  }, [isLoggedIn])

  if (showNoAccountPage) {
    return <NoAccount />
  } else {
    return (
      <>
        {renderError()}
        {isLoggedIn ? <WorkSpace /> : <Spinner />}
      </>
    )
  }
}

const Spinner = () => {
  return (
    <div style={alignSpinnerStyle}>
      <PdsSpinner />
    </div>
  )
}

const alignSpinnerStyle = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const mapStateToProps = (state) => {
  const {
    token,
    sharePointSaveEnabled,
    sharePointTemplatesEnabled
  } = state.login.userData
  return {
    error: state.error,
    strings: state.locale.strings,
    showNoAccountPage: state.login.showNoAccountPage,
    token,
    sharePointSaveEnabled,
    sharePointTemplatesEnabled
  }
}

const mapDispatchToProps = (dispatch) => {
  const { error, login } = redux.actions
  return {
    doLogin: (idToken) => {
      dispatch(login.doLogin(idToken))
    },
    doStartHeartBeat: () => {
      dispatch(redux.actions.login.doStartHeartBeat())
    },
    connectWebsockets: (token) => {
      socket.connect(token, dispatch)
    },
    onResetError: () => {
      dispatch(error.resetError())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
