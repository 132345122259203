import api from '../api.js'
import { busy, notBusy } from '../busy/actions'
import { handleGetErrors } from '../error/actions'

export const CONTENT_TYPE_GET_OK_ACTION = 'CONTENT_TYPE_GET_OK_ACTION'

export function getContentTypes (uiContext) {
  return function (dispatch) {
    dispatch(busy())
    const query = [
      { key: 'ui_context', value: uiContext || 'contact', op: '=' }
    ]
    api.getContentTypeUIFields({ query, sort: 'ui_order' }).then(result => {
      dispatch(notBusy())
      dispatch(receivedContentTypes(result.data.value))
    }).catch(handleGetErrors('contentType', dispatch))
  }
}

function receivedContentTypes (items) {
  return {
    type: CONTENT_TYPE_GET_OK_ACTION,
    items
  }
}
