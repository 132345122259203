import store from '../store.js'
import api from '../api.js'
import { getSpAccessToken, getGraphAccessToken } from '../../lib/msal.js'
import { ERROR_ACTION } from '../error/actions.js'
import {
  beginProgressDialog,
  updateProgressDialog,
  endProgressDialog
} from '../progress/actions.js'
import {
  closeOutlookWindow,
  emailFileNameOutlookComposeMode,
  getFileExtensionFromHost,
  isOffice,
  isOutlook,
  isOutlookComposeMode,
  readCustomXml,
  readFileAttachments,
  readOfficeFile,
  readCurrentMailFile,
  readMailFile,
  readMailMetadata,
  saveDraftEmail,
  sendMail,
  setDocumentTitle,
  setPDSCategory,
  setDocumentProperty,
  writeCustomXml,
  resetCustomXml,
  getEmailMetadataReadMode,
  setEmailSubjectComposeMode,
  getEmailSubjectComposeMode
} from '../../lib/office.js'
import {
  readSharePointFile,
  writeSharePointFile,
  updateEmailListItemFields
} from '../../lib/sharepoint'
import {
  stringToUint,
  cleanFileName,
  openDocument,
  getFileExtension,
  getItemUrl,
  wait
} from '../../util.js'
import {
  xmlToObject,
  XML_DEFAULT_DATA
} from '../../lib/xml.js'
import config from '../../config.js'

export const SET_SAVE_DIALOG_FILENAME_ACTION = 'SET_SAVE_DIALOG_FILENAME_ACTION'
export const READ_DOCUMENT_PROPERTIES_ACTION = 'READ_DOCUMENT_PROPERTIES_ACTION'
export const SET_PROPERTY_ACTION = 'SET_PROPERTY_ACTION'

const ERROR_TYPE_FILE_SAVE = 'ERROR_TYPE_FILE_SAVE'
const ERROR_TYPE_FILE_READ_ATTACHMENTS = 'ERROR_TYPE_FILE_READ_ATTACHMENTS'
const ERROR_TYPE_FILE_UPLOAD = 'ERROR_TYPE_FILE_UPLOAD'
const ERROR_TYPE_FILE_UPLOAD_ATTACHMENTS = 'ERROR_TYPE_FILE_UPLOAD_ATTACHMENTS'
const ERROR_TYPE_EMAIL_READ = 'ERROR_TYPE_EMAIL_READ'
const ERROR_TYPE_EMAIL_SAVE_DRAFT = 'ERROR_TYPE_EMAIL_SAVE_DRAFT'
const ERROR_TYPE_EMAIL_SEND = 'ERROR_TYPE_EMAIL_SEND'

export function setSaveDialogFileName (fileName) {
  return {
    type: SET_SAVE_DIALOG_FILENAME_ACTION,
    fileName: cleanFileName(fileName)
  }
}

/**
 * Sets a property, but only for saving the state. It doesn't save
 * this property to the document.
 */
export function setProperty (key, value) {
  return {
    type: SET_PROPERTY_ACTION,
    key,
    value
  }
}

/**
 * Async action for reading document properties.
 */
export function readDocumentProperties () {
  return async function (dispatch) {
    let xml

    try {
      xml = await readCustomXml()
      console.info('read custom xml', xml)
    } catch (e) {
      console.warn('failed to read custom xml', e)
      dispatch({
        type: READ_DOCUMENT_PROPERTIES_ACTION,
        documentProperties: {}
      })
      return
    }

    if (!xml) {
      console.info('no custom xml found, using default nil values')
      xml = XML_DEFAULT_DATA
    }
    dispatch({
      type: READ_DOCUMENT_PROPERTIES_ACTION,
      documentProperties: xmlToObject(xml)
    })
  }
}

/**
 * Async action for uploading a file to SharePoint.
 * siteItem: item picked in top tree, e.g. contact, project, intranet, department
 * fileItem: file node picked in the bottom tree
 * fileName: file name for this file
 * xmlProperties: properties to populate custom xml with
 * onSaveDone: call this function when save is done
 */
export function uploadFile (siteItem, fileItem, fileName, xmlProperties, onSaveDone) {
  const state = store.getState()
  const userData = state.login.userData
  fileName = computeFileName(fileName)

  return async function (dispatch) {
    try {
      // TODO figure out how to do this using jszip later, currently modifying
      // existing document!
      await setDocumentTitle(fileName.split('.')[0])
    } catch (e) {
      console.warn('failed to set document title', e)
    }

    const siteUrl = getItemUrl(siteItem)
    const path = fileItem.path
    let fileData = null

    try {
      dispatch(
        beginProgressDialog(
          'PROGRESS_TITLE_UPLOAD_FILE',
          'PROGRESS_STATUS_READ_FILE'
        )
      )

      const spToken = await getSpAccessToken(userData)

      if (isOffice()) {
        fileData = await readOfficeFile()
        // Read office file data into uint8array
        dispatch(updateProgressDialog('PROGRESS_STATUS_WRITE_CUSTOM_XML'))
        // Set custom xml to default xml, if it exists
        fileData = await resetCustomXml(fileData)

        // Upload file to have SharePoint populate/add custom xml
        console.info('upload: saving document for custom xml', siteUrl, path, fileName)
        const result = await writeSharePointFile({ siteUrl, path, fileName, fileData }, spToken)
        // Give SharePoint some time to process the file
        await wait(config.waitForSaveTimeout)

        // Read the file data back from SharePoint.
        fileData = await readSharePointFile(result.absoluteUrl, spToken)
        // Now we can populate the custom xml with the properties from the UI
        fileData = await writeCustomXml(fileData, xmlProperties)
      } else if (isOutlook()) {
        const graphToken = await getGraphAccessToken(userData)
        fileData = await readCurrentMailFile(graphToken)
      } else {
        console.info('not office and not outlook -> assuming browser')
        fileData = stringToUint('This is a test file')
      }

      console.info('upload: saving document', siteUrl, path, fileName)
      dispatch(updateProgressDialog('PROGRESS_STATUS_UPLOAD_FILE'))

      const result = await writeSharePointFile({
        siteUrl,
        path,
        fileName,
        fileData
      }, spToken)

      console.info('upload: file saved successfully')

      if (typeof onSaveDone === 'function') onSaveDone()

      try {
        // Signal to close the currently opened document
        await setDocumentProperty('PDSCloseDocument', 1)
      } catch (e) {
        console.warn('failed to set document property', e)
      }

      if (isOffice()) {
        dispatch(updateProgressDialog('PROGRESS_STATUS_OPEN_FILE'))
        openDocument(result.absoluteUrl)
      } else if (isOutlook()) {
        setPDSCategory()
        await updateEmailListItemFields({
          siteUrl,
          listItemUrl: result.listItemUrl,
          fields: getEmailMetadataReadMode()
        }, spToken)
      }

      const meta = {
        type: siteItem.type,
        id: siteItem.id,
        path,
        fileName,
        url: encodeURI(`${siteUrl}/${path}/${fileName}`)
      }
      try {
        await api.addFileSavedEvent(meta)
      } catch (e) {
        console.warn('failed to add file saved event', e)
      }

      setTimeout(() => dispatch(endProgressDialog()), 1000)
    } catch (e) {
      dispatch({
        type: ERROR_ACTION,
        errorType: ERROR_TYPE_FILE_SAVE,
        errorMessage: e.message
      })
    }
  }
}

/**
 * Called from read mode in outlook when saving attachments.
 * siteItem: item picked in top tree, e.g. contact, project, intranet, department
 * fileItem: file node picked in the bottom tree
 * onSaveDone: call this function when save is done
 */
export function uploadFileAttachments (siteItem, fileItem, onSaveDone) {
  const state = store.getState()
  const userData = state.login.userData

  return function (dispatch) {
    dispatch(
      beginProgressDialog(
        'PROGRESS_TITLE_UPLOAD_ATTACHMENTS',
        'PROGRESS_STATUS_READ_ATTACHMENTS'
      )
    )

    readFileAttachments(async (err, attachments) => {
      if (err) {
        return dispatch({
          type: ERROR_ACTION,
          errorType: ERROR_TYPE_FILE_READ_ATTACHMENTS,
          errorMessage: err.message
        })
      }

      const spToken = await getSpAccessToken(userData)
      const siteUrl = getItemUrl(siteItem)
      const path = fileItem.path

      try {
        for (const attachment of attachments) {
          const { fileData } = attachment
          const fileName = cleanFileName(attachment.fileName)
          console.info('upload: saving attachment', siteUrl, path, fileName)
          dispatch(updateProgressDialog(
            'PROGRESS_STATUS_UPLOAD_ATTACHMENTS',
            fileName
          ))
          await writeSharePointFile({
            siteUrl,
            path,
            fileName,
            fileData
          }, spToken)
        }
      } catch (err) {
        console.error('failed to write attachment to sharepoint', err)
        return dispatch({
          type: ERROR_ACTION,
          errorType: ERROR_TYPE_FILE_UPLOAD_ATTACHMENTS,
          errorMessage: err.message
        })
      }

      if (typeof onSaveDone === 'function') {
        onSaveDone()
      }

      try {
        for (const attachment of attachments) {
          const { fileName } = attachment
          const meta = {
            type: siteItem.type,
            id: siteItem.id,
            path,
            fileName,
            url: encodeURI(`${siteUrl}/${path}/${fileName}`)
          }
          await api.addFileSavedEvent(meta)
        }
      } catch (err) {
        console.error('failed to add file save event', err)
        return dispatch({
          type: ERROR_ACTION,
          errorType: ERROR_TYPE_FILE_UPLOAD_ATTACHMENTS,
          errorMessage: err.message
        })
      }

      console.info('Successfully uploaded attachments')
      dispatch(endProgressDialog())
    })
  }
}

/**
 * Called from compose mode in outlook.
 *
 * Saves the email -> So the email will get an itemId
 * Reads back the email data (retries until success)
 * Sends the email using the Graph API
 * Files the email in the selected folder
 * Closes the mail window (doesn't work when in inline mode)
 */
export function sendAndFile (siteItem, fileItem) {
  const state = store.getState()
  const userData = state.login.userData

  // TODO we need some sort of progress dialog here
  // since the user doesn't know anything of what is going on

  // TODO make this function async instead of using callbacks
  function readEmailWithRetries (itemId, cb) {
    let retries = config.outlook.readRetryCount
    const retryTimeout = config.outlook.readRetryTimeout

    async function tryReadEmail () {
      let fileData = null

      try {
        const graphToken = await getGraphAccessToken(userData)
        fileData = await readMailFile(itemId, graphToken)
      } catch (err) {
        if (--retries > 0) {
          console.info('Failed to read draft email,', retries, 'retries left')
          setTimeout(tryReadEmail, retryTimeout)
        } else {
          console.error('Failed to read draft email', err)
          cb(err)
        }
        return
      }

      console.info('Read draft email successfully')
      cb(null, fileData)
    }

    setTimeout(tryReadEmail, retryTimeout)
  }

  return async function (dispatch) {
    if (!isOutlookComposeMode()) {
      console.warn('Can only call sendAndFile() in outlook compose mode -> no op')
      return
    }

    if (siteItem.type === 'project') {
      let subject = await getEmailSubjectComposeMode()
      if (!subject.match(`(pds-p${siteItem.id})`)) {
        subject = subject + ` (pds-p${siteItem.id})`
        await setEmailSubjectComposeMode(subject)
      }
    }

    dispatch(beginProgressDialog(
      'PROGRESS_TITLE_SEND_AND_FILE',
      'PROGRESS_STATUS_SAVE_DRAFT_EMAIL'
    ))

    let itemId = null
    try {
      let retries = 5
      do {
        itemId = await saveDraftEmail()
        if (typeof itemId === 'string' && itemId.length > 0) {
          console.info('Got draft mail id', itemId)
          break
        } else if (--retries > 0) {
          console.warn('Retrying save draft email..')
          await wait(2000)
        } else {
          throw new Error('Failed to save draft email')
        }
      } while (true)
    } catch (err) {
      return dispatch({
        type: ERROR_ACTION,
        errorType: ERROR_TYPE_EMAIL_SAVE_DRAFT,
        errorMessage: err.message
      })
    }

    dispatch(updateProgressDialog(
      'PROGRESS_STATUS_FETCH_EMAIL_WITH_RETRIES'
    ))
    readEmailWithRetries(itemId, async (err, fileData) => {
      if (err) {
        return dispatch({
          type: ERROR_ACTION,
          errorType: ERROR_TYPE_EMAIL_READ,
          errorMessage: err.message
        })
      }

      console.info('Read draft email with length', fileData.length)

      const graphToken = await getGraphAccessToken(userData)
      const fields = await readMailMetadata(itemId, graphToken)

      dispatch(updateProgressDialog(
        'PROGRESS_STATUS_SEND_DRAFT_EMAIL'
      ))

      try {
        await sendMail(itemId, graphToken)
      } catch (err) {
        return dispatch({
          type: ERROR_ACTION,
          errorType: ERROR_TYPE_EMAIL_SEND,
          errorMessage: err.message
        })
      }

      const siteUrl = getItemUrl(siteItem)
      const path = fileItem.path
      const fileName = encodeURI(cleanFileName(await emailFileNameOutlookComposeMode()))
      console.info('upload: saving document on', siteUrl, path, fileName)

      dispatch(updateProgressDialog(
        'PROGRESS_STATUS_UPLOAD_EMAIL'
      ))

      try {
        const spToken = await getSpAccessToken(userData)
        const result = await writeSharePointFile({
          siteUrl,
          path,
          fileName,
          fileData
        }, spToken)
        await updateEmailListItemFields({
          siteUrl,
          listItemUrl: result.listItemUrl,
          fields
        }, spToken)
      } catch (err) {
        return dispatch({
          type: ERROR_ACTION,
          errorType: ERROR_TYPE_FILE_UPLOAD,
          errorMessage: err.message
        })
      }

      try {
        const meta = {
          type: siteItem.type,
          id: siteItem.id,
          path,
          fileName,
          url: encodeURI(`${siteUrl}/${path}/${fileName}`)
        }
        await api.addFileSavedEvent(meta)
      } catch (e) {
        console.warn('failed to add file saved event', e)
      }

      console.info('send and file: email saved successfully')
      dispatch(endProgressDialog())
      closeOutlookWindow()
    })
  }
}

/**
 * If host extension is the same as the file extension, do nothing.
 * Otherwise append host extension to current file name.
 */
function computeFileName (fileName) {
  const hostExtension = getFileExtensionFromHost()
  if (hostExtension) {
    const fileExtension = getFileExtension(fileName)
    if (fileExtension === hostExtension) {
      return fileName
    } else {
      return `${fileName}.${hostExtension}`
    }
  } else {
    return fileName
  }
}
