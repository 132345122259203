import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Header from './Header.js'
import Footer from './Footer.js'
import Toolbar from './Toolbar.js'
import FileView from './FileView.js'
import FileSearchField from './FileSearchField.js'
import redux from '../redux/index.js'

const TemplatesPage = ({ getData, strings }) => {
  useEffect(getData, [])

  return (
    <>
      <Header title={strings.templates_page_title} />
      <Toolbar />
      <div style={fileViewStyle}>
        <FileView />
      </div>
      <div style={fileSearchFieldStyle}>
        <FileSearchField />
      </div>
      <div style={footerStyle}>
        <Footer showSaveButton={false} showSendAndFileButton={false} />
      </div>
    </>
  )
}

const border = '1px solid #a3a3a3'

const fileViewStyle = {
  position: 'absolute',
  top: 90,
  bottom: 88,
  width: '100%',
  overflow: 'auto',
  background: 'white',
  border
}

const fileSearchFieldStyle = {
  background: 'white',
  position: 'absolute',
  bottom: 50,
  width: '100%'
}

const footerStyle = {
  position: 'absolute',
  bottom: 0,
  width: '100%'
}

const mapStateToProps = (state) => {
  return {
    strings: state.locale.strings
  }
}

const mapDispatchToProps = (dispatch) => {
  const { files } = redux.actions
  return {
    getData: () => {
      dispatch(files.getTemplatesRootFolder())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplatesPage)
