import {
  RESET_SELECTED_ITEM_ACTION,
  SELECT_ITEM_ACTION
} from './actions.js'
import { CONTACT_DELETE_ACTION } from '../contact/actions.js'
import { PROJECT_DELETE_ACTION } from '../project/actions.js'
import { DEPARTMENT_DELETE_ACTION } from '../department/actions.js'
import { CATEGORY_DELETE_ACTION } from '../category/actions.js'

const DEFAULT = {
  current: null
}

/**
 * Reducer for selected item in current tree view.
 */
export function selected (state = DEFAULT, action) { // eslint-disable-line
  switch (action.type) {
    case SELECT_ITEM_ACTION: {
      const { current } = state
      return Object.assign({}, state, {
        current: action.item,
        previous: current
      })
    }
    case RESET_SELECTED_ITEM_ACTION:
      return DEFAULT
    case CONTACT_DELETE_ACTION: {
      const { contact } = action
      const { current } = state
      if (current && current.id === contact.id && current.type === 'contact') {
        return DEFAULT
      } else {
        return state
      }
    }
    case PROJECT_DELETE_ACTION: {
      const { project } = action
      const { current } = state
      if (current && current.id === project.id && current.type === 'project') {
        return DEFAULT
      } else {
        return state
      }
    }
    case DEPARTMENT_DELETE_ACTION: {
      const { department } = action
      const { current } = state
      if (current && current.id === department.id && current.type === 'department') {
        return DEFAULT
      } else {
        return state
      }
    }
    case CATEGORY_DELETE_ACTION: {
      const { category } = action
      const { current } = state
      if (current && current.id === category.id && current.type === 'category') {
        return DEFAULT
      } else {
        return state
      }
    }
    default:
      return state
  }
}
