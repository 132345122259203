export const SHOW_DIALOG_ACTION = 'SHOW_DIALOG_ACTION'
export const HIDE_DIALOG_ACTION = 'HIDE_DIALOG_ACTION'

export function showSaveDialog () {
  return {
    type: SHOW_DIALOG_ACTION,
    name: 'save'
  }
}

export function showSettingsDialog () {
  return {
    type: SHOW_DIALOG_ACTION,
    name: 'settings'
  }
}

export function showPeopleDialog (contactId, people, title) {
  return {
    type: SHOW_DIALOG_ACTION,
    name: 'people',
    meta: { contactId, people, title }
  }
}

export function hideDialog () {
  return {
    type: HIDE_DIALOG_ACTION
  }
}

export function showFolderDialog (item) {
  return {
    type: SHOW_DIALOG_ACTION,
    name: 'folders',
    meta: { item }
  }
}
