import api from '../api.js'
import { busy, notBusy } from '../busy/actions'
import { handleUpdateErrors } from '../error/actions'

export const CATEGORY_UPDATE_ACTION = 'CATEGORY_UPDATE_ACTION'
export const CATEGORY_DELETE_ACTION = 'CATEGORY_DELETE_ACTION'

export function categoryUpdateAction (category) {
  return {
    type: CATEGORY_UPDATE_ACTION,
    category
  }
}

export function categoryDeleteAction (category) {
  return {
    type: CATEGORY_DELETE_ACTION,
    category
  }
}

export function toggleFavorite (category) {
  return function (dispatch) {
    const favorite = !category.is_favorite
    dispatch(busy())
    api.setCategoryFavorite(category.id, favorite).then(() => {
      dispatch(notBusy())
    }).catch(handleUpdateErrors('category', dispatch))
  }
}
