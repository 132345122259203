import store from '../store.js'
import api from '../api.js'
import { busy, notBusy } from '../busy/actions.js'
import { showPeopleDialog } from '../dialog/actions.js'
import { handleGetErrors, handleUpdateErrors } from '../error/actions.js'
import { setSelectedItem } from '../selected/actions.js'
import redux from '../index.js'

export const CONTACT_GET_OK_ACTION = 'CONTACT_GET_OK_ACTION'
export const CONTACT_UPDATE_ACTION = 'CONTACT_UPDATE_ACTION'
export const CONTACT_DELETE_ACTION = 'CONTACT_DELETE_ACTION'
export const CONTACT_EXPAND_NODE_ACTION = 'CONTACT_EXPAND_NODE_ACTION'

export const CONTACT_GET_PAGE_ITEMS_ACTION = 'CONTACT_GET_PAGE_ITEMS_ACTION'
export const CONTACT_GET_PAGE_ITEM_ACTION = 'CONTACT_GET_PAGE_ITEM_ACTION'
export const CONTACT_GET_PROJECTS_ACTION = 'CONTACT_GET_PROJECTS_ACTION'
export const CONTACT_SET_ORDER_ACTION = 'CONTACT_SET_ORDER_ACTION'
export const CONTACT_SET_LIMIT_ACTION = 'CONTACT_SET_LIMIT_ACTION'
export const CONTACT_SET_OFFSET_ACTION = 'CONTACT_SET_OFFSET_ACTION'
export const CONTACT_SET_SEARCH_ACTION = 'CONTACT_SET_SEARCH_ACTION'
export const CONTACT_TOGGLE_SHOW_FAVORITES = 'CONTACT_TOGGLE_SHOW_FAVORITES'
export const CONTACT_TOGGLE_SHOW_RECENT = 'CONTACT_TOGGLE_SHOW_RECENT'

export function contactUpdateAction (contact) {
  return {
    type: CONTACT_UPDATE_ACTION,
    contact
  }
}

export function contactDeleteAction (contact) {
  return {
    type: CONTACT_DELETE_ACTION,
    contact
  }
}

export function getContacts (name) {
  const params = {
    query: [],
    sort: 'name.asc'
  }
  if (typeof name === 'string' && name.length > 0) {
    params.query.push({ key: 'name', value: name, op: '~' })
  } else if (typeof name === 'undefined') {
    params.limit = 25
  }

  return function (dispatch) {
    dispatch(busy())
    api.getContacts(params).then(result => {
      dispatch(notBusy())
      dispatch({ type: CONTACT_GET_OK_ACTION, items: result.data.value })
    }).catch(handleGetErrors('contact', dispatch))
  }
}

export function getContact (id) {
  const params = {
    query: [{ key: 'id', value: id, op: '=' }]
  }
  return function (dispatch) {
    dispatch(busy())
    api.getContacts(params).then(result => {
      dispatch(notBusy())
      dispatch({ type: CONTACT_GET_OK_ACTION, items: result.data.value })
    }).catch(handleGetErrors('contact', dispatch))
  }
}

export function getPageItems (id) {
  const state = store.getState()
  const {
    search,
    limit,
    offset,
    orderBy,
    order,
    showFavorites,
    showRecent
  } = state.contact

  const sort = orderBy ? `${orderBy}.${order}` : null
  const params = {
    query: [],
    limit,
    offset,
    sort,
    recent: showRecent
  }

  if (typeof search === 'string' && search.length > 0) {
    params.query.push({ key: 'name', value: search, op: '~' })
  }

  if (showFavorites) {
    params.query.push({ key: 'is_favorite', value: showFavorites, op: '=' })
  }

  return function (dispatch) {
    dispatch(busy())
    api.getContacts(params).then(result => {
      dispatch(notBusy())
      dispatch({
        type: CONTACT_GET_PAGE_ITEMS_ACTION,
        pageItems: result.data.value,
        totalCount: result.data.total_count
      })
      if (id) {
        getPageItem(id)(dispatch)
      }
    }).catch(handleGetErrors('contact', dispatch))
  }
}

export function getProjects (contactId) {
  const state = store.getState()
  const { showFavorites } = state.contact

  const params = {
    query: [{ key: 'collection_id', value: contactId, op: '=' }],
    sort: 'updated_at.desc'
  }
  if (showFavorites) {
    params.query.push({ key: 'is_favorite', value: showFavorites, op: '=' })
  }

  return function (dispatch) {
    dispatch(busy())
    api.getProjects(params).then(result => {
      dispatch(notBusy())
      dispatch({
        type: CONTACT_GET_PROJECTS_ACTION,
        contactId,
        projects: result.data.value
      })
    }).catch(handleGetErrors('project', dispatch))
  }
}

function getPageItem (id) {
  const state = store.getState()
  const { sharePointSaveEnabled } = state.login.userData

  const params = {
    query: [{ key: 'id', value: id, op: '=' }],
    limit: 1
  }

  return function (dispatch) {
    dispatch(busy())
    api.getContacts(params).then(result => {
      dispatch(notBusy())
      if (Array.isArray(result.data.value) && result.data.value.length === 1) {
        dispatch({
          type: CONTACT_GET_PAGE_ITEM_ACTION,
          pageItem: result.data.value[0]
        })
        dispatch(setSelectedItem(result.data.value[0]))
        if (sharePointSaveEnabled) {
          dispatch(redux.actions.files.getRootFolder(result.data.value[0]))
        }
      }
    }).catch(handleGetErrors('contact', dispatch))
  }
}

export function showContactPeopleDialog (contactId, title) {
  return function (dispatch) {
    const query = [{ key: 'collection_id', value: contactId, op: '=' }]
    dispatch(busy())
    api.getContactPersons({ query }).then(result => {
      dispatch(notBusy())
      const people = result.data.value.map(person => {
        return {
          person_name: person.name,
          person_collection_name: person.collection_name,
          person_address: person.address?.address,
          person_city: person.address?.city,
          person_email: person.address?.email,
          person_homepage: person.address?.homepage,
          person_phone: person.address?.phone,
          person_postal_code: person.address?.postal_code,
          role_name: ''
        }
      })
      dispatch(showPeopleDialog(contactId, people, title))
    }).catch(handleGetErrors('project', dispatch))
  }
}

export function toggleExpandNode (contact) {
  return {
    type: CONTACT_EXPAND_NODE_ACTION,
    contact
  }
}

export function toggleFavorite (contact) {
  return function (dispatch) {
    const favorite = !contact.is_favorite
    dispatch(busy())
    api.setContactFavorite(contact.id, favorite).then(() => {
      dispatch(notBusy())
    }).catch(handleUpdateErrors('contact', dispatch))
  }
}

/**
 * Sort order
 */
export function setOrder (orderBy, order) {
  return {
    type: CONTACT_SET_ORDER_ACTION,
    orderBy,
    order
  }
}

/**
 * Set limit for pagination
 */
export function setLimit (limit) {
  return {
    type: CONTACT_SET_LIMIT_ACTION,
    limit
  }
}

/**
 * Set offset for pagination
 */
export function setOffset (offset) {
  return {
    type: CONTACT_SET_OFFSET_ACTION,
    offset
  }
}

/**
 * Set search
 */
export function setSearch (search) {
  return {
    type: CONTACT_SET_SEARCH_ACTION,
    search
  }
}

/**
 * Toggle show favorites
 */
export function toggleShowFavorites () {
  return {
    type: CONTACT_TOGGLE_SHOW_FAVORITES
  }
}

/**
 * Toggle show recent
 */
export function toggleShowRecent () {
  return {
    type: CONTACT_TOGGLE_SHOW_RECENT
  }
}
