import store from '../store.js'
import { getSpAccessToken } from '../../lib/msal.js'
import { fromByteArray } from 'base64-js'
import { setSelectedItem } from '../selected/actions.js'
import {
  beginProgressDialog,
  updateProgressDialog,
  endProgressDialog
} from '../progress/actions.js'
import {
  absoluteUrl,
  isOfficeZipFile,
  getItemUrl,
  getItemRoot
} from '../../util'
import {
  addFileAttachmentFromBase64,
  clearCustomXml
} from '../../lib/office.js'
import {
  getDocumentLibraryFiles,
  readSharePointFile,
  searchDocumentLibrary
} from '../../lib/sharepoint/index.js'

import { ERROR_ACTION } from '../error/actions.js'
import { hideDialog } from '../dialog/actions.js'

const ERROR_TYPE_FILE_READ = 'ERROR_TYPE_FILE_READ'
const ERROR_TYPE_DOC_LIB_GET = 'ERROR_TYPE_DOC_LIB_GET'
const ERROR_TYPE_FILE_ATTACH = 'ERROR_TYPE_FILE_ATTACH'
const ERROR_TYPE_FILE_SEARCH = 'ERROR_TYPE_FILE_SEARCH'
const ERROR_TYPE_FOLDER_CREATED_ADD = 'ERROR_TYPE_FOLDER_CREATED_ADD'

export const FILES_SET_SELECTED_FOLDER_ACTION = 'FILES_SET_SELECTED_FOLDER_ACTION'
export const FILES_SET_SELECTED_FILE_ACTION = 'FILES_SET_SELECTED_FILE_ACTION'
export const FILES_RESET_SELECTED_FILE_ACTION = 'FILES_RESET_SELECTED_FILE_ACTION'
export const FILES_GET_ROOT_FOLDER_ACTION = 'FILES_GET_ROOT_FOLDER_ACTION'
export const FILES_GET_SUB_FOLDER_ACTION = 'FILES_GET_SUB_FOLDER_ACTION'
export const FILES_SET_SEARCH_VALUE_ACTION = 'FILES_SET_SEARCH_VALUE_ACTION'
export const FILES_GOT_SEARCH_RESULTS_ACTION = 'FILES_GOT_SEARCH_RESULTS_ACTION'
export const FILES_BEGIN_SEARCH_ACTION = 'FILES_BEGIN_SEARCH_ACTION'
export const FILES_END_SEARCH_ACTION = 'FILES_END_SEARCH_ACTION'
export const FILES_RESET_SEARCH_ACTION = 'FILES_RESET_SEARCH_ACTION'

export function setSelectedFolder (folder) {
  return {
    type: FILES_SET_SELECTED_FOLDER_ACTION,
    folder
  }
}

export function setSelectedFile (file) {
  return {
    type: FILES_SET_SELECTED_FILE_ACTION,
    file
  }
}

export function resetSelectedFile () {
  return {
    type: FILES_RESET_SELECTED_FILE_ACTION
  }
}

export function setSearchValue (searchValue) {
  return {
    type: FILES_SET_SEARCH_VALUE_ACTION,
    searchValue
  }
}

export function resetSearch () {
  return {
    type: FILES_RESET_SEARCH_ACTION
  }
}

/**
 * Get files and folders from templates
 */
export function getTemplatesRootFolder () {
  const { login, locale } = store.getState()
  const item = {
    type: 'templates',
    id: 0,
    url: `${login.userData.sc_root}_admin`,
    name: locale.strings.templates_root_node
  }
  return function (dispatch) {
    dispatch(setSelectedItem(item))
    dispatch(getRootFolder(item))
  }
}

/**
 * Get files and folders for a root folder item
 */
export function getRootFolder (siteItem) {
  return async function (dispatch) {
    const siteUrl = getItemUrl(siteItem)
    const path = getItemRoot(siteItem)
    try {
      dispatch({
        type: FILES_GET_ROOT_FOLDER_ACTION,
        siteItem,
        path,
        items: await getFilesHelper({ siteUrl, path })
      })
    } catch (err) {
      dispatch(documentLibraryError(err))
    }
  }
}

/**
 * Get files and folders from a sub folder
 */
export function getSubFolder (item, folderItem) {
  return async function (dispatch) {
    const siteUrl = getItemUrl(item)
    const { path, id } = folderItem
    try {
      dispatch({
        type: FILES_GET_SUB_FOLDER_ACTION,
        item,
        parent: folderItem,
        items: await getFilesHelper({ siteUrl, path, id })
      })
    } catch (err) {
      dispatch(documentLibraryError(err))
    }
  }
}

async function getFilesHelper ({ siteUrl, path, id }) {
  const { userData } = store.getState().login
  const spToken = await getSpAccessToken(userData)
  const result = await getDocumentLibraryFiles({
    siteUrl,
    path,
    id
  }, spToken)

  const folders = result.Folders.results.filter(folder => {
    return folder.Name !== 'Forms'
  }).map(folder => {
    return {
      type: 'folder',
      children: [],
      id: folder.UniqueId,
      name: folder.Name,
      title: folder.Title,
      itemCount: folder.ItemCount,
      created: folder.TimeCreated,
      modified: folder.TimeLastModified,
      path: `${path}/${folder.Name}`
    }
  }).sort(compareFilenames)

  const files = result.Files.results.filter(file => {
    const length = parseInt(file.Length, 10)
    return !isNaN(length) && length > 0
  }).map(file => {
    return {
      type: 'file',
      id: file.UniqueId,
      name: file.Name,
      title: file.Title,
      created: file.TimeCreated,
      modified: file.TimeLastModified,
      path,
      uri: absoluteUrl(userData.sc_root, file.ServerRelativeUrl),
      serverRelativeUrl: file.ServerRelativeUrl
    }
  }).sort(compareFilenames)

  return folders.concat(files)
}

/**
 * Reads a file from SharePoint and attaches it to an email.
 * Can only be called during compose mode in Outlook.
 */
export function attachFile (selectedFileNode) {
  return async function (dispatch) {
    const { uri, name } = selectedFileNode
    const { userData } = store.getState().login

    dispatch(
      beginProgressDialog(
        'PROGRESS_TITLE_ATTACH_FILE',
        'PROGRESS_STATUS_READ_SHAREPOINT_FILE'
      )
    )

    let data = null

    try {
      const spToken = await getSpAccessToken(userData)
      data = await readSharePointFile(uri, spToken)
    } catch (err) {
      return dispatch({
        type: ERROR_ACTION,
        errorType: ERROR_TYPE_FILE_READ,
        errorMessage: err.message
      })
    }

    dispatch(updateProgressDialog('PROGRESS_STATUS_ATTACH_FILE'))

    if (isOfficeZipFile(name)) {
      console.info('clearing custom xml from', name)
      try {
        data = await clearCustomXml(data)
      } catch (err) {
        return dispatch({
          type: ERROR_ACTION,
          errorType: ERROR_TYPE_FILE_ATTACH,
          errorMessage: err.message
        })
      }
    }

    const base64 = fromByteArray(data)
    addFileAttachmentFromBase64(base64, name, (err, id) => {
      if (err) {
        return dispatch({
          type: ERROR_ACTION,
          errorType: ERROR_TYPE_FILE_ATTACH,
          errorMessage: err.message
        })
      } else {
        setTimeout(() => dispatch(endProgressDialog()), 1000)
        console.info('successfully added file attachment', uri, name, id)
      }
    })
  }
}

export function createFolder (siteItem, item, name) {
  function getSiteItemUrl (siteItem) {
    switch (siteItem.type) {
      case 'contact':
      case 'project':
      case 'department':
        return `${(new URL(siteItem.url)).pathname}`
      case 'category':
        return `${(new URL(siteItem.collection_url)).pathname}`
    }
    return ''
  }

  return async function (dispatch) {
    try {
      const userData = store.getState().login.userData
      const token = await getSpAccessToken(userData)
      const url = getItemUrl(siteItem)
      const relativeUrl = `${getSiteItemUrl(siteItem)}/${item.path}`

      const options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json;odata=verbose'
        },
        body: JSON.stringify({
          __metadata: {
            type: 'SP.Folder'
          },
          ServerRelativeUrl: `${relativeUrl}/${name}`
        })
      }

      const response = await fetch(`${url}/_api/web/folders`, options)
      if (!response.ok) {
        throw new Error('ERROR_TYPE_FOLDER_CREATED_ADD')
      }

      dispatch(getSubFolder(siteItem, item))
      dispatch(hideDialog())
    } catch (e) {
      dispatch({
        type: ERROR_ACTION,
        errorType: ERROR_TYPE_FOLDER_CREATED_ADD
      })
    }
  }
}

/**
 * Search files in currently selected context.
 */
export function searchFiles (siteItem, query) {
  return async function (dispatch) {
    const { userData } = store.getState().login
    const scRoot = userData.sc_root

    const siteUrl = getItemUrl(siteItem)
    const serverRelativeUrl = getServerRelativeUrl(siteItem)
    console.info('searching files in', { siteItem, siteUrl, serverRelativeUrl, query })

    dispatch({ type: FILES_BEGIN_SEARCH_ACTION })

    function onResults (results) {
      results = results.filter(file => {
        const length = parseInt(file.Length, 10)
        return !isNaN(length) && length > 0
      }).map(file => {
        return {
          type: 'file',
          id: file.UniqueId,
          name: file.Name,
          title: file.Title,
          created: file.TimeCreated,
          modified: file.TimeLastModified,
          uri: absoluteUrl(scRoot, file.ServerRelativeUrl),
          serverRelativeUrl: file.ServerRelativeUrl
        }
      })
      dispatch({ type: FILES_GOT_SEARCH_RESULTS_ACTION, results })
    }

    function onEnd () {
      dispatch({ type: FILES_END_SEARCH_ACTION })
    }

    try {
      const spToken = await getSpAccessToken(userData)
      await searchDocumentLibrary({
        siteUrl,
        serverRelativeUrl,
        query,
        onResults,
        onEnd
      }, spToken)
    } catch (err) {
      return dispatch({
        type: ERROR_ACTION,
        errorType: ERROR_TYPE_FILE_SEARCH,
        errorMessage: err.message
      })
    }
  }
}

/**
 * Returns server relative url to a document folder for a particular item
 */
function getServerRelativeUrl (siteItem) {
  switch (siteItem.type) {
    case 'contact':
    case 'project':
    case 'department':
      return `${(new URL(siteItem.url)).pathname}/files`
    case 'category':
      return `${(new URL(siteItem.collection_url)).pathname}/files_${siteItem.id}`
    case 'templates':
      return `${(new URL(siteItem.url)).pathname}/pdsMallar`
  }
  return ''
}

function documentLibraryError (err) {
  console.error('documentLibraryerror', err)
  // TODO if error code is 401, then reload page
  return {
    type: ERROR_ACTION,
    errorType: ERROR_TYPE_DOC_LIB_GET,
    errorMessage: err.message
  }
}

function compareFilenames (_lhs, _rhs) {
  const lhs = _lhs.name.toLowerCase()
  const rhs = _rhs.name.toLowerCase()
  if (lhs < rhs) return -1
  if (lhs === rhs) return 0
  if (lhs > rhs) return 1
}
