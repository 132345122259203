import React from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListSubheader
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { LocaleIcon } from '../icons/index.js'
import redux from '../redux/index.js'
import {
  CONTENT_BACKGROUND,
  FOOTER_BACKGROUND,
  FOOTER_HEIGHT,
  HEADER_BACKGROUND,
  HEADER_HEIGHT
} from '../styles.js'
// Is set to false if we're running inside an iframe, e.g. in office online
const isStandAlone = window.parent === window

const SettingsDialog = ({
  strings,
  onHideSettingsDialog,
  onLogout,
  onChangeLocale,
  locale,
  userName,
  email,
  tenantName
}) => {
  const languageSubheader = (<ListSubheader>{strings.language}</ListSubheader>)
  const sessionSubheader = (<ListSubheader>{strings.session}</ListSubheader>)
  return (
    <Dialog fullScreen open>
      <DialogTitle style={titleStyle}>
        <div style={titleContentStyle}>
          {strings.settings_dialog_title}
        </div>
      </DialogTitle>
      <DialogContent style={contentStyle}>
        <List subheader={sessionSubheader}>
          <ListItem style={listItemStyle}>
            <ListItemText primary={strings.user_name} secondary={userName} style={itemTextStyle} />
          </ListItem>
          <ListItem>
            <ListItemText primary={strings.email} secondary={email} style={itemTextStyle} />
          </ListItem>
          <ListItem>
            <ListItemText primary={strings.tenant} secondary={tenantName} style={itemTextStyle} />
          </ListItem>
          {isStandAlone && <Button onClick={onLogout} style={logoutButtonStyle}>{strings.logout}</Button>}
        </List>
        <List subheader={languageSubheader}>
          <ListItem
            button
            onClick={() => onChangeLocale('en_consultancy')}
            selected={locale === 'en_consultancy'}
          >
            <LocaleIcon style={{ marginTop: '-4px' }} />
            <ListItemText primary='English' style={itemTextStyle} />
          </ListItem>
          <ListItem
            button
            onClick={() => onChangeLocale('en_lawfirm')}
            selected={locale === 'en_lawfirm'}
          >
            <LocaleIcon style={{ marginTop: '-4px' }} />
            <ListItemText primary='English (Lawfirm)' style={itemTextStyle} />
          </ListItem>
          <ListItem
            button
            onClick={() => onChangeLocale('es_consultancy')}
            selected={locale === 'es_consultancy'}
          >
            <LocaleIcon style={{ marginTop: '-4px' }} />
            <ListItemText primary='Español' style={itemTextStyle} />
          </ListItem>
          <ListItem
            button
            onClick={() => onChangeLocale('sv_consultancy')}
            selected={locale === 'sv_consultancy'}
          >
            <LocaleIcon style={{ marginTop: '-4px' }} />
            <ListItemText primary='Svenska' style={itemTextStyle} />
          </ListItem>
          <ListItem
            button
            onClick={() => onChangeLocale('sv_lawfirm')}
            selected={locale === 'sv_lawfirm'}
          >
            <LocaleIcon style={{ marginTop: '-4px' }} />
            <ListItemText primary='Svenska (Advokat)' style={itemTextStyle} />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions style={actionsStyle}>
        <Button
          onClick={onHideSettingsDialog}
          style={closeButtonStyle}
        >
          <Close style={closeIconStyle} />{strings.close}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const titleStyle = {
  background: HEADER_BACKGROUND,
  color: 'white',
  padding: '0px 19px',
  fontSize: '20px',
  height: HEADER_HEIGHT
}

const titleContentStyle = {
  marginTop: '8px'
}

const contentStyle = {
  background: CONTENT_BACKGROUND,
  padding: '0px'
}

const listItemStyle = {
  paddingTop: '2px',
  paddingBottom: '2px'
}

const itemTextStyle = {
  marginLeft: '10px',
  marginTop: '0px',
  marginBottom: '2px'
}

const actionsStyle = {
  borderTop: '1px solid white',
  background: FOOTER_BACKGROUND,
  height: FOOTER_HEIGHT,
  padding: 0
}

const logoutButtonStyle = {
  background: FOOTER_BACKGROUND,
  color: 'white',
  textTransform: 'none',
  marginTop: '5px',
  marginLeft: '25px'
}

const closeButtonStyle = {
  color: 'white',
  textTransform: 'none',
  marginRight: '10px'
}

const closeIconStyle = {
  marginRight: '5px'
}

const mapStateToProps = (state) => {
  const { userData } = state.login
  return {
    strings: state.locale.strings,
    locale: state.locale.locale,
    userName: userData.name,
    email: userData.email,
    tenantName: userData.tenant_name
  }
}

const mapDispatchToProps = (dispatch) => {
  const { dialog, locale, login } = redux.actions
  return {
    onHideSettingsDialog: () => {
      dispatch(dialog.hideDialog())
    },
    onChangeLocale: (l) => {
      dispatch(locale.changeLocale(l))
    },
    onLogout: () => {
      dispatch(login.doLogout())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsDialog)
