import dayjs from 'dayjs'
import { eachSeries } from 'async'
import JSZip from 'jszip'
import { stringToUint } from '../util.js'

// TODO rewrite using import and not require
const {
  XML_DEFAULT_DATA,
  XML_EMPTY_DATA,
  XML_EMPTY_SCHEMA,
  XML_EMPTY_DOCPROPS,
  xmlToJs,
  jsToXml,
  setCustomXmlProperty
} = require('./xml.js')

const PDS_CATEGORY = 'PDS'
const CUSTOM_XML_NS = 'http://schemas.microsoft.com/office/2006/metadata/properties'
const GRAPH_MESSAGES = 'https://graph.microsoft.com/v1.0/me/messages'

// TODO, add the following requirements to the manifest (?)
// - CompressedFile
// - File
// - CustomXmlParts

/**
 * Main onReady function for Office.
 */
export function onReady (fn) {
  Office.onReady(() => {
    console.info('Office is ready!')
    fn()
  })
}

/**
 * Reads document properties of a word file into memory.
 */
export async function readDocumentProperty (key) {
  if (!isWord()) {
    throw new Error('Runtime environment not supported')
  }
  if (typeof key !== 'string') {
    throw new Error('Missing argument, expected document property identifier')
  }
  return await Word.run(async (context) => {
    const property = context.document.properties.customProperties.getItem(key).load()
    await context.sync()
    try {
      return property
    } catch (e) {
      return new Error(`Failed to read document property: ${e}`)
    }
  })
}

/**
 * Delete document property
 * Requires it to be loaded into memory before executing
 */
export async function deleteDocumentProperty (key) {
  if (!isWord()) {
    throw new Error('Runtime environment not supported')
  }
  if (typeof key !== 'string') {
    throw new Error('Missing argument, expected document property identifier')
  }
  return await Word.run(async (context) => {
    const property = context.document.properties.customProperties.getItem(key).load()
    await context.sync()
    try {
      property.delete()
    } catch (e) {
      return new Error(`Failed to delete document property: ${e}`)
    }
  })
}

/**
 * Print Office diagnostics.
 */
export function printDiagnostics () {
  console.info('User agent:', navigator.userAgent)
  if (isOffice()) {
    const { diagnostics } = Office.context
    if (diagnostics) {
      console.info('Office host:', diagnostics.host)
      console.info('Office platform:', diagnostics.platform)
      console.info('Office version:', diagnostics.version)
    }
  } else if (isOutlook()) {
    const { diagnostics, item } = Office.context.mailbox
    if (diagnostics) {
      console.info('Outlook host name:', diagnostics.hostName)
      console.info('Outlook host version:', diagnostics.hostVersion)
    }
    if (item) {
      console.info('Outlook item type:', item.itemType)
    }
  }
}

/**
 * Saves a draft email.
 * Returns a Promise, which resolves to itemId.
 */
export function saveDraftEmail () {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.item.saveAsync((result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(result.value)
      } else {
        // TODO put error types in a separate file
        reject(new Error('E_SAVE_DRAFT_EMAIL_FAILED'))
      }
    })
  })
}

/**
 * Returns file data for the current file in Office.
 * Returns a Promise.
 */
export function readOfficeFile () {
  const sliceData = []

  const getSlice = (file, sliceIndex, cb) => {
    file.getSliceAsync(sliceIndex, (result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        const slice = result.value
        sliceData.push(Uint8Array.from(slice.data))
        if (sliceData.length === file.sliceCount) {
          cb()
        } else {
          getSlice(file, ++sliceIndex, cb)
        }
      } else {
        cb(result.error)
      }
    })
  }

  const concatSlides = () => {
    let totalSize = 0
    for (let i = 0; i < sliceData.length; i++) {
      totalSize += sliceData[i].length
    }

    const data = new Uint8Array(totalSize)
    let index = 0

    sliceData.forEach((d) => {
      data.set(d, index)
      index += d.length
    })

    return data
  }

  return new Promise((resolve, reject) => {
    Office.context.document.getFileAsync(Office.FileType.Compressed, (result) => {
      const onDone = (file) => {
        return (err) => {
          file.closeAsync()
          if (err) {
            reject(err)
          } else {
            resolve(concatSlides())
          }
        }
      }

      if (result.status === Office.AsyncResultStatus.Succeeded) {
        const file = result.value
        getSlice(file, 0, onDone(file))
      } else {
        reject(result.error)
      }
    })
  })
}

/**
 * Returns file data for current selected email in Outlook.
 * Returns a Promise.
 */
export function readCurrentMailFile (graphToken) {
  return readMailFile(Office.context.mailbox.item.itemId, graphToken)
}

/**
 * Returns file data for an email in Outlook using graph api
 */
export async function readMailFile (itemId, graphToken) {
  const id = restItemId(itemId)
  const url = `${GRAPH_MESSAGES}/${id}/$value`
  const headers = { Authorization: `Bearer ${graphToken}` }
  const response = await fetch(url, { headers })
  if (!response.ok) {
    throw new Error('E_READ_MAIL_FAILED')
  }
  const result = await response.text()
  return stringToUint(result)
}

/**
 * Returns email metadata in Outlook using graph api
 * https://learn.microsoft.com/en-us/graph/api/message-get?view=graph-rest-1.0&tabs=http#example-1-get-a-specific-message
 */
export async function readMailMetadata (itemId, graphToken) {
  const id = restItemId(itemId)
  const url = `${GRAPH_MESSAGES}/${id}`
  const headers = {
    Authorization: `Bearer ${graphToken}`,
    'Content-Type': 'application/json'
  }
  const response = await fetch(url, { headers })
  if (!response.ok) {
    throw new Error('E_READ_MAIL_METADATA_FAILED')
  }

  const {
    subject,
    toRecipients,
    sentTimeCreated
  } = await response.json()
  const to = toRecipients.map(({ emailAddress }) => emailAddress?.address).join(', ')
  const sender = Office.context.mailbox.userProfile

  return {
    subject,
    to,
    sender: sender?.emailAddress,
    dateTimeCreated: dayjs(sentTimeCreated).format('YYYY-MM-DD HH:mm:ss'),
    // TODO this is locale dependent
    documentClass: 'E-post',
    documentDate: dayjs().format('YYYY-MM-DD HH:mm:ss')
  }
}

/**
 * Sends a draft email.
 * Used during send and file.
 */
export async function sendMail (itemId, graphToken) {
  const id = restItemId(itemId)
  const url = `${GRAPH_MESSAGES}/${id}/send`
  const options = {
    headers: {
      Authorization: `Bearer ${graphToken}`
    },
    method: 'POST'
  }
  const response = await fetch(url, options)
  if (!response.ok) {
    throw new Error('E_SEND_MAIL_FAILED')
  }
}

/**
 * Returns a file name for send and file.
 * If for some reason, the subject can't be retrieved an
 * empty subject will be used.
 * Can only be called in Outlook compose mode, since
 * subject.getAsync is not a function in read mode.
 */
export async function emailFileNameOutlookComposeMode () {
  return new Promise((resolve) => {
    Office.context.mailbox.item.subject.getAsync((result) => {
      const ok = result.status === Office.AsyncResultStatus.Succeeded
      resolve(emailFileName(ok ? result.value : ''))
    })
  })
}

/**
 * Returns a file name for saving emails in Outlook read mode.
 */
export function emailFileNameOutlookReadMode () {
  const { subject, dateTimeCreated } = Office.context.mailbox.item
  return emailFileName(subject, dateTimeCreated)
}

/**
 * Common function for creating en email file name.
 */
function emailFileName (subject, date) {
  const time = dayjs(date).format('YYYY-MM-DD HH-mm-ss')
  return subject.length ? `${subject} ${time}.eml` : `${time}.eml`
}

/**
 * Returns email metadata, such as: subject, sender & recipients.
 */
export function getEmailMetadataReadMode () {
  let { subject, to, sender, dateTimeCreated } = Office.context.mailbox.item
  // TODO why are we replacing these characters in the subject?
  subject = subject.replace(/[<>:"/\\|?*]/g, '')
  to = to.map(({ emailAddress }) => emailAddress).join(', ')
  sender = sender.emailAddress
  dateTimeCreated = dayjs(dateTimeCreated).format('YYYY-MM-DD HH:mm:ss')
  return {
    subject,
    to,
    sender,
    dateTimeCreated,
    // TODO this is locale dependent
    documentClass: 'E-post',
    documentDate: dayjs().format('YYYY-MM-DD HH:mm:ss')
  }
}

/**
 * Returns true if current email has any non inline file attachments.
 */
export function hasAttachments () {
  if (isOutlookReadMode()) {
    const { attachments } = Office.context.mailbox.item
    return (Array.isArray(attachments) &&
            attachments.filter(fileAttachment).length > 0)
  } else {
    return false
  }
}

/**
 * Reads all non inline file attachment data.
 */
export function readFileAttachments (cb) {
  if (isOutlookReadMode()) {
    const result = []
    const { item } = Office.context.mailbox
    const attachments = item.attachments.filter(fileAttachment)
    eachSeries(attachments, (attachment, next) => {
      const { id, name } = attachment
      item.getAttachmentContentAsync(id, (res) => {
        if (res.status === Office.AsyncResultStatus.Succeeded) {
          const value = res.value
          if (value.format === 'base64') {
            // TODO this works, but why doesn't it work for emails with
            // e.g. swedish letters?
            // Can we convert the data in a better way, e.g. with base64-js?
            const fileData = stringToUint(atob(value.content))
            result.push({ fileData, fileName: name })
          }
          next()
        } else {
          next(result.error)
        }
      })
    }, (err) => cb(err, result))
  } else if (isOutlookComposeMode()) {
    const item = Office.context.mailbox.item
    const result = []
    item.getAttachmentsAsync({ asyncContext: { currentItem: item } }, async (res) => {
      if (res.value.length > 0) {
        for (let i = 0; i < res.value.length; i++) {
          const name = res.value[i].name
          res.asyncContext.currentItem.getAttachmentContentAsync(res.value[i].id, ({ value }) => {
            if (value.format === Office.MailboxEnums.AttachmentContentFormat.Base64) {
              const fileData = stringToUint(atob(value.content))
              result.push({ fileData, fileName: name })
              if (i === res.value.length - 1) {
                cb(null, result)
              }
            }
          })
        }
      } else {
        cb(null, [])
      }
    })
  } else {
    setTimeout(() => cb(null, []), 20)
  }
}

function fileAttachment (a) {
  return a.isInline === false && a.attachmentType === 'file'
}

/**
 * Closes an open window in Outlook.
 * Does not work when a reply is inline.
 */
export function closeOutlookWindow () {
  if (isOutlook()) {
    Office.context.mailbox.item.close()
  }
}

/**
 * Compute item id.
 * Item id is correctly formatted on mobile.
 */
function restItemId (itemId) {
  if (Office.context.mailbox.diagnostics.hostName === 'OutlookIOS') {
    return itemId
  } else {
    return Office.context.mailbox.convertToRestId(
      itemId,
      Office.MailboxEnums.RestVersion.v2_0
    )
  }
}

/**
 * Show reply form.
 */
export function displayReplyForm () {
  try {
    Office.context.mailbox.item.displayReplyForm('')
  } catch (err) {
    console.error('failed to display reply form', err)
  }
}

/**
 * Show reply all form.
 */
export function displayReplyAllForm () {
  try {
    Office.context.mailbox.item.displayReplyAllForm('')
  } catch (err) {
    console.error('failed to display reply all form', err)
  }
}

/**
 * Set PDS category on currently selected mail.
 */
export function setPDSCategory () {
  function addCategory () {
    Office.context.mailbox.item.categories.addAsync([PDS_CATEGORY], (result) => {
      if (result.status !== Office.AsyncResultStatus.Succeeded) {
        console.error('Failed to add category', result.error)
      }
    })
  }

  Office.context.mailbox.masterCategories.getAsync((result) => {
    if (result.status === Office.AsyncResultStatus.Succeeded) {
      const categories = result.value || []
      if (!categories.find(c => c.displayName === PDS_CATEGORY)) {
        const masterCategories = [{
          displayName: 'PDS',
          color: Office.MailboxEnums.CategoryColor.Preset22
        }]
        Office.context.mailbox.masterCategories.addAsync(masterCategories, (result) => {
          if (result.status === Office.AsyncResultStatus.Succeeded) {
            addCategory()
          } else {
            console.error('Failed to add master category', result.error)
          }
        })
      } else {
        addCategory()
      }
    } else {
      console.error('Failed to get master categories', result.error)
    }
  })
}

/**
 * Return true if we are in Office, e.g. Word, Excel or
 * PowerPoint.
 */
export function isOffice () {
  return isWord() || isExcel() || isPowerPoint()
}

/**
 * Return true if we are running inside Word
 */
export function isWord () {
  return Office.context.host === Office.HostType.Word
}

/**
 * Return true if we are running inside Excel
 */
export function isExcel () {
  return Office.context.host === Office.HostType.Excel
}

/**
 * Return true if we are running inside PowerPoint
 */
export function isPowerPoint () {
  return Office.context.host === Office.HostType.PowerPoint
}

/**
 * Return true if we are running inside Outlook
 */
export function isOutlook () {
  return Office.context.host === Office.HostType.Outlook
}

/**
 * Return true if we are running inside Outlook and in read mode
 */
export function isOutlookReadMode () {
  if (isOutlook()) {
    const { item } = Office.context.mailbox
    const { displayReplyForm, itemType } = item
    return (typeof displayReplyForm === 'function' && itemType === 'message')
  } else {
    return false
  }
}

/**
 * Return true if we are running inside Outlook and in compose mode
 */
export function isOutlookComposeMode () {
  if (isOutlook()) {
    const { item } = Office.context.mailbox
    const { displayReplyForm, itemType } = item
    return (typeof displayReplyForm !== 'function' && itemType === 'message')
  } else {
    return false
  }
}

/**
 * Return file extension depending on the office context.
 */
export function getFileExtensionFromHost () {
  if (isWord()) {
    return 'docx'
  } else if (isExcel()) {
    return 'xlsx'
  } else if (isPowerPoint()) {
    return 'pptx'
  } else if (isOutlook()) {
    return 'eml'
  }
  return ''
}

/**
 * Set custom document properties.
 */
export async function setDocumentProperty (key, value) {
  if (isWord()) {
    return Word.run((context) => {
      const customProperties = context.document.properties.customProperties
      customProperties.add(key, value)
      return context.sync()
    })
  } else if (isExcel()) {
    return Excel.run((context) => {
      const customProperties = context.workbook.properties.custom
      customProperties.add(key, value)
      return context.sync()
    })
  } else {
    console.info('not in word or excel, document property not set')
  }
}

/**
 * Set document title (document properties).
 */
export async function setDocumentTitle (title) {
  if (isWord()) {
    return Word.run((context) => {
      const properties = context.document.properties
      properties.title = title
      return context.sync()
    })
  } else if (isExcel()) {
    return Excel.run((context) => {
      const properties = context.workbook.properties
      properties.title = title
      return context.sync()
    })
  } else {
    console.info('not in word or excel, document title not set')
  }
}

/**
 * Reads custom xml in Word.
 * Returns a Promise.
 */
function readCustomXmlWord () {
  return new Promise((resolve, reject) => {
    const { customXmlParts } = Office.context.document
    customXmlParts.getByNamespaceAsync(CUSTOM_XML_NS, (result) => {
      if (result.status !== Office.AsyncResultStatus.Succeeded) {
        return reject(result.error)
      }

      const parts = result.value
      if (parts.length === 0) {
        return resolve(null)
      }

      const part = parts[0]
      part.getXmlAsync((result) => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          resolve(result.value)
        } else {
          reject(result.error)
        }
      })
    })
  })
}

/**
 * Reads custom xml in Excel.
 * Returns a Promise.
 */
function readCustomXmlExcel () {
  return Excel.run((context) => {
    const { customXmlParts } = context.workbook
    const parts = customXmlParts.getByNamespace(CUSTOM_XML_NS)
    const numberOfParts = parts.getCount()
    return context.sync().then(() => {
      if (numberOfParts.value === 1) {
        const part = parts.getOnlyItem()
        const xml = part.getXml()
        return context.sync().then(() => xml.value)
      } else {
        return null
      }
    })
  })
}

/**
 * Reads custom xml in PowerPoint.
 * Returns a Promise.
 */
async function readCustomXmlPowerPoint () {
  const fileData = await readOfficeFile()
  const zip = await JSZip.loadAsync(fileData)
  const itemXml = await findCustomXmlFile(zip)
  if (typeof itemXml === 'string') {
    return zip.file(itemXml).async('string')
  } else {
    return null
  }
}

/**
 * Reads custom xml from the document.
 */
export async function readCustomXml () {
  if (isWord()) {
    return readCustomXmlWord()
  } else if (isExcel()) {
    return readCustomXmlExcel()
  } else if (isPowerPoint()) {
    return readCustomXmlPowerPoint()
  }
}

/**
 * Find the file containing custom xml.
 */
async function findCustomXmlFile (zip) {
  const itemXml = Object.keys(zip.files).filter(file => {
    return !!file.match(/customXml\/item\d\.xml/gi)
  })

  for (const name of itemXml) {
    const xml = await zip.file(name).async('string')
    let js = null
    try {
      js = xmlToJs(xml)
    } catch (err) {
      console.warn(`Could not convert ${name} xml file to js, skipping`, err)
      continue
    }
    if (Array.isArray(js.elements) && js.elements.length > 0) {
      const root = js.elements[0]
      if (root.name !== 'p:properties') continue
      const xmlns = root.attributes['xmlns:p']
      if (xmlns !== 'http://schemas.microsoft.com/office/2006/metadata/properties') continue
      if (Array.isArray(root.elements) && root.elements.length > 0) {
        const node = root.elements[0]
        if (node.name === 'documentManagement') {
          return name
        }
      }
    }
  }

  console.info('no custom xml found in file data')
}

/**
 * Find the file containing the schema for the custom xml.
 */
async function findSchemaXmlFile (zip) {
  const itemXml = Object.keys(zip.files).filter(file => {
    return !!file.match(/customXml\/item\d\.xml/gi)
  })

  for (const name of itemXml) {
    const xml = await zip.file(name).async('string')
    let js = null
    try {
      js = xmlToJs(xml)
    } catch (err) {
      console.warn(`Could not convert ${name} xml file to js, skipping`, err)
      continue
    }
    if (Array.isArray(js.elements) && js.elements.length > 0) {
      const root = js.elements[0]
      if (root.name === 'ct:contentTypeSchema') {
        return name
      }
    }
  }

  console.info('no custom xml schema found in file data')
}

/**
 * Resets existing custom xml file with default xml data
 */
export async function resetCustomXml (fileData) {
  const zip = await JSZip.loadAsync(fileData)
  const itemXmlFile = await findCustomXmlFile(zip)
  if (typeof itemXmlFile === 'string') {
    zip.file(itemXmlFile, XML_DEFAULT_DATA)
    return zip.generateAsync({ type: 'uint8array' })
  } else {
    return fileData
  }
}

/**
 * Clear existing custom xml data file, schema file and document properties
 */
export async function clearCustomXml (fileData) {
  let dirty = false
  const zip = await JSZip.loadAsync(fileData)

  const schemaXmlFile = await findSchemaXmlFile(zip)
  if (typeof schemaXmlFile === 'string') {
    console.info('found schema for custom xml file', schemaXmlFile)
    zip.file(schemaXmlFile, XML_EMPTY_SCHEMA)
    dirty = true
  }

  const itemXmlFile = await findCustomXmlFile(zip)
  if (typeof itemXmlFile === 'string') {
    console.info('found custom xml file', itemXmlFile)
    zip.file(itemXmlFile, XML_EMPTY_DATA)
    dirty = true
  }

  try {
    await zip.file('docProps/custom.xml').async('string')
    zip.file('docProps/custom.xml', XML_EMPTY_DOCPROPS)
    dirty = true
  } catch (err) {
    console.warn('could not find docProps/custom.xml')
  }

  return dirty ? zip.generateAsync({ type: 'uint8array' }) : fileData
}

/**
 * Write custom xml.
 * `fileData` file data
 * `properties` custom xml properties to write
 */
export async function writeCustomXml (fileData, properties) {
  const zip = await JSZip.loadAsync(fileData)
  const itemXml = await findCustomXmlFile(zip)
  if (typeof itemXml === 'string') {
    const xml = await zip.file(itemXml).async('string')
    let js = xmlToJs(xml)
    const data = Object.keys(properties).map(key => {
      return { key, value: properties[key] }
    })
    data.forEach(({ key, value }) => {
      js = setCustomXmlProperty(js, key, value)
    })
    zip.file(itemXml, jsToXml(js))
    return zip.generateAsync({ type: 'uint8array' })
  } else {
    return fileData
  }
}

/**
 * Attaches a file to an email in compose mode.
 * Can only be called during compose mode in Outlook.
 */
export function addFileAttachmentFromBase64 (base64, name, cb) {
  Office.context.mailbox.item.addFileAttachmentFromBase64Async(base64, name, (result) => {
    if (result.status === Office.AsyncResultStatus.Succeeded) {
      cb(null, result.value)
    } else {
      cb(result.error)
    }
  })
}

/**
 * Fill in content controls in word.
 */
export async function fillInContentControls (person) {
  if (isWord()) {
    await Word.run(async (context) => {
      const pdsDocumentContact = context.document.contentControls.getByTag('pdsDocumentContact')
      pdsDocumentContact.load('length')

      const pdsContactPerson = context.document.contentControls.getByTag('pdsContactPerson')
      pdsContactPerson.load('length')

      const pdsAddress = context.document.contentControls.getByTag('pdsAddress')
      pdsAddress.load('length')

      await context.sync()

      for (let i = 0; i < pdsDocumentContact.items.length; i++) {
        // TODO remove person?.collection_name after views have been updated
        const name = person?.person_collection_name || person?.collection_name || ''
        pdsDocumentContact.items[i].insertText(name, 'Replace')
      }

      for (let i = 0; i < pdsContactPerson.items.length; i++) {
        pdsContactPerson.items[i].insertText(person?.person_name || '', 'Replace')
      }

      const address = `${person.person_address ?? ''}\n${person.person_postal_code ?? ''} ${person.person_city ?? ''}`
      for (let i = 0; i < pdsAddress.items.length; i++) {
        pdsAddress.items[i].insertText(address, 'Replace')
      }
    })
  }
}

/**
 * Paste in person data into word document.
 */
export async function pastePersonData (person) {
  if (isWord()) {
    await Word.run(async (context) => {
      const range = context.document.getSelection()

      range.insertParagraph(person.person_name ?? '', 'Before')

      if (person.person_email) {
        const email = person.person_email ?? ''
        range.insertHtml(`<a href="mailto:${email}">${email}</a>`, 'Before')
      }

      if (person.person_phone) {
        range.insertParagraph(person.person_phone, 'Before')
      }

      if (person.person_homepage) {
        const url = person.person_homepage
        range.insertHtml(`<a href="${url}">${url}</a>`, 'Before')
      }

      range.insertParagraph(person.role_name, 'Before')

      range.insertParagraph('', 'Before')
      range.insertParagraph('', 'Before').select('End')

      await context.sync()
    })
  }
}

export async function pasteContactData (contact) {
  if (isWord()) {
    await Word.run(async (context) => {
      const range = context.document.getSelection()

      range.insertParagraph(contact?.name ?? '', 'Before')
      const address = contact?.address?.address ?? ''
      range.insertParagraph(`${address}`, 'Before')

      const zipCode = contact?.address?.postal_code ?? ''
      const city = contact?.address?.city ?? ''
      range.insertParagraph(`${zipCode} ${city}`, 'Before')

      const email = contact?.address?.email ?? ''
      range.insertHtml(`<a href="mailto:${email}">${email}</a>`, 'Before')

      const phone = contact?.address?.phone ?? ''
      range.insertParagraph(phone, 'Before')

      const url = contact?.address?.homepage
      if (url) {
        range.insertHtml(`<a href="${url}">${url}</a>`, 'Before')
      }

      range.insertParagraph('', 'Before')
      range.insertParagraph('', 'Before').select('End')

      await context.sync()
    })
  }
}

export async function fillInContactContentControls (contact) {
  if (isWord()) {
    await Word.run(async (context) => {
      const pdsDocumentContact = context.document.contentControls.getByTag('pdsDocumentContact')
      pdsDocumentContact.load('length')

      const pdsContactPerson = context.document.contentControls.getByTag('pdsContactPerson')
      pdsContactPerson.load('length')

      const pdsAddress = context.document.contentControls.getByTag('pdsAddress')
      pdsAddress.load('length')

      await context.sync()

      for (let i = 0; i < pdsDocumentContact.items.length; i++) {
        const name = contact?.name || ''
        pdsDocumentContact.items[i].insertText(name, 'Replace')
      }

      const address = `${contact?.address.address ?? ''}\n${contact?.address?.postal_code ?? ''} ${contact?.address?.city ?? ''}`
      for (let i = 0; i < pdsAddress.items.length; i++) {
        pdsAddress.items[i].insertText(address, 'Replace')
      }
    })
  }
}

export function getEmailSubjectComposeMode () {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.item.subject.getAsync((result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(result.value)
      } else {
        reject(new Error('failed to get subject from office'))
      }
    })
  })
}

export function setEmailSubjectComposeMode (subject) {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.item.subject.setAsync(subject, {}, function (result) {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve()
      } else {
        reject(new Error('failed to set email subject'))
      }
    })
  })
}

export function getEmailAddressComposeMode () {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.item.to.getAsync((result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(result.value[0].emailAddress)
      } else {
        reject(new Error('failed to get email address from office'))
      }
    })
  })
}

export function setEmailAddressComposeMode (recipients = []) {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.item.to.addAsync(recipients, (result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(result.value)
      } else {
        reject(new Error('failed to set email address'))
      }
    })
  })
}
