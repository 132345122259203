import React from 'react'
import {
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from '@material-ui/core'
import { connect } from 'react-redux'
import redux from '../../redux/index.js'

const MultiChoice = ({
  contentType,
  properties,
  onSetProperty,
  locale,
  style
}) => {
  const blob = contentType.field_blob
  const choices = blob.choice.choices.slice().sort()
  const key = contentType.field_internal_name
  const value = properties[key] || []

  const onChange = (value) => {
    onSetProperty(key, value)
  }

  const renderTags = () => {
    return choices.map((name) => (
      <MenuItem key={name} value={name}>
        <Checkbox checked={value.indexOf(name) > -1} />
        <ListItemText primary={name} />
      </MenuItem>
    ))
  }

  const required = contentType.ui_required
  const title = locale[key] || contentType.field_title
  const label = `${title}${required ? ' *' : ''}`

  return (
    <div style={style}>
      <InputLabel style={labelStyle}>{label}</InputLabel>
      <Select
        multiple
        value={value}
        variant='outlined'
        style={selectStyle}
        onChange={(event) => onChange(event.target.value)}
        renderValue={(selected) => selected.join(', ')}
      >
        {renderTags()}
      </Select>
    </div>
  )
}

const labelStyle = {
  marginLeft: '15px',
  marginTop: '-7px',
  position: 'absolute',
  zIndex: 7,
  background: 'white',
  fontSize: '12px'
}

const selectStyle = {
  width: '100%',
  background: 'white'
}

const mapStateToProps = (state) => {
  return {
    properties: state.save.documentProperties,
    locale: state.locale.strings
  }
}

const mapDispatchToProps = (dispatch) => {
  const { save } = redux.actions
  return {
    onSetProperty: (key, value) => {
      dispatch(save.setProperty(key, value))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiChoice)
