import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core'
import { Close, Save } from '@material-ui/icons'
import TextField from '../components/TextField.js'
import redux from '../redux/index.js'
import {
  CONTENT_BACKGROUND,
  FOOTER_BACKGROUND,
  FOOTER_HEIGHT,
  HEADER_BACKGROUND,
  HEADER_HEIGHT
} from '../styles.js'
import { cleanFileName } from '../util.js'

const FolderDialog = ({ strings, selected, item, onCreateFolder, onHideCreateDialog }) => {
  const [name, setName] = useState('')
  const disableSave = name.length === 0

  return (
    <Dialog fullScreen open>
      <DialogTitle style={titleStyle}>
        <div style={titleContentStyle}>
          {strings.create_folder}
        </div>
      </DialogTitle>
      <DialogContent style={contentStyle}>
        <TextField
          required
          label={strings.foldername}
          value={name}
          style={{ background: 'white', marginBottom: '10px' }}
          onChange={(e) => setName(cleanFileName(e.target.value))}
          autoFocus
        />
      </DialogContent>
      <DialogActions style={actionsStyle}>
        <Button
          disabled={disableSave}
          onClick={() => onCreateFolder(selected, item, name)}
          style={actionButtonStyle(disableSave)}
        >
          <Save style={actionIconStyle} />{strings.create_folder}
        </Button>
        <Button
          onClick={onHideCreateDialog}
          style={actionButtonStyle()}
        >
          <Close style={actionIconStyle} />{strings.cancel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const titleStyle = {
  background: HEADER_BACKGROUND,
  color: 'white',
  padding: '0px 19px',
  fontSize: '20px',
  height: HEADER_HEIGHT
}

const titleContentStyle = {
  marginTop: '8px'
}

const contentStyle = {
  background: CONTENT_BACKGROUND
}

const actionsStyle = {
  borderTop: '1px solid white',
  background: FOOTER_BACKGROUND,
  height: FOOTER_HEIGHT,
  padding: 0
}

export const actionButtonStyle = (disabled = false) => {
  return {
    color: disabled ? '#aaafb2' : 'white',
    textTransform: 'none',
    marginRight: '10px'
  }
}

export const actionIconStyle = {
  marginRight: '5px'
}

const mapStateToProps = (state) => {
  const selected = state.selected.current
  const item = state.dialog.meta.item
  return {
    selected,
    item,
    strings: state.locale.strings
  }
}

const mapDispatchToProps = (dispatch) => {
  const { dialog, files } = redux.actions
  return {
    onHideCreateDialog: () => {
      dispatch(dialog.hideDialog())
    },
    onCreateFolder: (siteItem, item, name) => {
      dispatch(files.createFolder(siteItem, item, name))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FolderDialog)
