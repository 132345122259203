import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuList,
  MenuItem
} from '@material-ui/core'
import { Menu as MenuIcon } from '@material-ui/icons'
import {
  ContactIcon,
  ProjectIcon,
  DepartmentIcon,
  SettingsIcon,
  TemplateIcon
} from '../icons/index.js'
import redux from '../redux/index.js'

const Toolbar = ({
  strings,
  sharePointSaveEnabled,
  sharePointTemplatesEnabled,
  currentPage,
  onSetCurrentPage,
  onResetSearch,
  onShowSettingsDialog,
  children
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const handleOpenMenu = (event) => setAnchorEl(event.currentTarget)
  const handleCloseMenu = () => setAnchorEl(null)

  const onMenuSelect = (page) => {
    handleCloseMenu()
    onResetSearch()
    if (page !== currentPage) {
      onSetCurrentPage(page)
    }
  }

  const onSettingsSelect = () => {
    handleCloseMenu()
    onShowSettingsDialog()
  }

  const renderMenuButton = () => {
    return (
      <Button onClick={handleOpenMenu} style={menuButtonStyle}>
        <MenuIcon />
      </Button>
    )
  }

  const renderIntranetMenuitem = () => {
    if (sharePointSaveEnabled) {
      return (
        <MenuItem
          selected={currentPage === 'intranet'}
          onClick={() => onMenuSelect('intranet')}
        >
          <ListItemIcon><DepartmentIcon /></ListItemIcon>
          <ListItemText primary={strings.intranet_page_title} />
        </MenuItem>
      )
    } else {
      return null
    }
  }

  const renderTemplatesMenuitem = () => {
    if (sharePointTemplatesEnabled) {
      return (
        <MenuItem
          selected={currentPage === 'templates'}
          onClick={() => onMenuSelect('templates')}
        >
          <ListItemIcon><TemplateIcon /></ListItemIcon>
          <ListItemText primary={strings.templates_page_title} />
        </MenuItem>
      )
    } else {
      return null
    }
  }

  const renderMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuList style={menuListStyle}>
          <MenuItem
            selected={currentPage === 'contact'}
            onClick={() => onMenuSelect('contact')}
          >
            <ListItemIcon><ContactIcon /></ListItemIcon>
            <ListItemText primary={strings.contact_page_title} />
          </MenuItem>
          <MenuItem
            selected={currentPage === 'project'}
            onClick={() => onMenuSelect('project')}
          >
            <ListItemIcon><ProjectIcon /></ListItemIcon>
            <ListItemText primary={strings.project_page_title} />
          </MenuItem>
          {renderIntranetMenuitem()}
          {renderTemplatesMenuitem()}
        </MenuList>
        <MenuList style={{ padding: '0px' }}>
          <MenuItem
            onClick={onSettingsSelect}
          >
            <ListItemIcon><SettingsIcon /></ListItemIcon>
            <ListItemText primary={strings.settings_dialog_title} />
          </MenuItem>
        </MenuList>
      </Menu>
    )
  }

  return (
    <div style={toolbarStyle}>
      <div>
        {renderMenuButton()}
        {renderMenu()}
      </div>
      {children}
    </div>
  )
}

const menuListStyle = {
  borderBottom: '0.25px solid #c7c7c7',
  padding: '0px'
}

const menuButtonStyle = {
  minWidth: 24,
  marginLeft: 5,
  paddingLeft: 1,
  paddingRight: 1
}

const toolbarStyle = {
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: '#f4f4f4',
  height: 40
}

const mapStateToProps = (state) => {
  return {
    currentPage: state.page,
    strings: state.locale.strings,
    sharePointSaveEnabled: state.login.userData.sharePointSaveEnabled,
    sharePointTemplatesEnabled: state.login.userData.sharePointTemplatesEnabled
  }
}

const mapDispatchToProps = (dispatch) => {
  const { dialog, files, page, selected } = redux.actions
  return {
    onSetCurrentPage: (p) => {
      dispatch(files.resetSelectedFile())
      if (p !== 'templates') {
        dispatch(selected.resetSelectedItem())
      }
      dispatch(page.setCurrentPage(p))
    },
    onShowSettingsDialog: () => {
      dispatch(dialog.showSettingsDialog())
    },
    onResetSearch: () => {
      dispatch(files.resetSearch())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar)
