import { INCREASE_BUSY_ACTION, DECREASE_BUSY_ACTION } from './actions'

/**
 * Reducer for busy state
 */
export function busy (state = 0, action) { // eslint-disable-line
  switch (action.type) {
    case INCREASE_BUSY_ACTION:
      return state + 1
    case DECREASE_BUSY_ACTION:
      return Math.max(state - 1, 0)
    default:
      return state
  }
}
